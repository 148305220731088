import React from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import RycomSectionHeader from "../../common/RycomSectionHeader";
import RycomPageHeader from "../../common/RycomPageHeader";
import RycomInfoPair from "../../common/RycomInfoPair";
import RycomSelectField from "../../common/RycomSelectField";
import RycomTextField from "../../common/RycomTextField";
import { RycomGridItem, RycomGrid } from "../../common/RycomGrid";
import RycomSubmitModel from "../../common/RycomSubmitModel";
import JsonMapperForm from "./JsonMapperForm";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import * as OrganisationService from "../../../services/OrganisationService";
import * as VenueService from "../../../services/VenueService";
import * as DeviceService from "../../../services/DeviceService";
import * as DataMapperService from "../../../services/DataMapperService";
import * as DocumentClassService from "../../../services/DocumentClassService";

import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import environment from "../../../environment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  /*modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },*/
  modal: {
    display: "flex",
    justifyContent: "center",
    overflow: "scroll",
    position: "absolute",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 1300,
    overflow: "scroll",
  },
  invalidMessage: {
    display: "inline-block",
    fontSize: "12px",
    color: "red",
    paddingLeft: "12px",
  },
  gridBox: {
    padding: "24px",
    border: "1px solid #ddd",
    boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    margin: "10px",
    width: "calc(100% - 20px)"
  }
}));

export default function DataMapperEditTemplateForm(props) {
  let { id } = useParams();
  const history = useHistory();
  const [metadataproperties, setMetadataproperties] = useState(
    props.defaultValues.metadata
  );

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedMapper, setSelectedMapper] = React.useState({});

  const handleOpen = (mapper) => {
    console.log('edit click', mapper);
    if (fileType) {
      setOpen(true);
      setSelectedMapper(mapper);

      if(mapper.markedAsPrimaryKey == true) {
        setPrimaryKey(true)
      } else {
        setPrimaryKey(false)
      }
      
      if(mapper.maintainHistoricalRecord == true) {
        setMaintainHistoryValue(true)
      } else {
        setMaintainHistoryValue(false)
      }

      if(fileType == 'CSV') {
        
        if(Object.keys(mapper).length !== 0 ) {
          setMetadatapopupproperties(mapper.metadata);
          setTransformationType(mapper.transformationType);
          setTransformationConfiguration(mapper.transformationConfiguration)
        } else {
          setMetadatapopupproperties([]);
          setTransformationType('');
          setTransformationConfiguration({})
        }
      }

    } else {
      alert("Please select the File Type");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const defaultValues = {
    ...props.defaultValues,
    metadata: metadataproperties,
    filetype: props.defaultValues.datainfo.filetype,
    headerpresent:props.defaultValues.datainfo.headerpresent ? props.defaultValues.datainfo.headerpresent : null
  };

  console.log("defaultValues", defaultValues);

  const [companyId, setCompanyId] = useState();
  const [fileType, setFileType] = useState(defaultValues.datainfo.filetype);
  const [headerPresent, setHeaderPresent] = useState(defaultValues.datainfo.headerpresent.toString().toUpperCase());

  const { register, errors, handleSubmit, reset, watch, control } = useForm({
    defaultValues,
  });
  const {
    register: register2,
    errors: errors2,
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm({});

  const fileTypeHandler = (fileTypeValue) => {
    console.log(fileTypeValue);
    setFileType(fileTypeValue);
    //  if(fileTypeValue==='JSON')
  };
  
  const headerPresentHandler = (header) => {
    console.log(header);
    setHeaderPresent(header);
    //  if(fileTypeValue==='JSON')
  };

  const [mapperError, setMapperError] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [vertexList, setVertexList] = useState([]);
  const [vertexName, setVertexName] = useState(props.defaultValues.vertexName);
  const [vertexId, setVertexId] = useState(props.defaultValues.vertexId);
  const [dataSourceAttribute, setDataSourceAttribute] = useState([]);

  const [deviceList, setDeviceList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [mapperList, setMapperList] = useState(props.defaultValues.mapper);
  const [venueList, setVenueList] = useState([]);
  const [duplicateVertex, setDuplicateVertex] = useState();

  const [primaryKeyEnabled, setPrimaryKeyEnabled] = React.useState(
    props.defaultValues.checkDuplicate
  );
  //const [historicalEnabled, setHistoricalEnabled] = React.useState(false);
  const [historicalEnabled, setHistoricalEnabled] = React.useState(props.defaultValues.checkForHistoricalChanges);
  const [transformationType, setTransformationType]=React.useState(selectedMapper && selectedMapper.transformationType ? selectedMapper.transformationType : '');
  const [transformationConfiguration, setTransformationConfiguration]=React.useState(selectedMapper && selectedMapper.transformationConfiguration ? selectedMapper.transformationConfiguration : {});
  const [primaryKey, setPrimaryKey] = React.useState(selectedMapper && selectedMapper.markedAsPrimaryKey ? true : false );

  const [maintainHistoryValue, setMaintainHistoryValue] = React.useState(false);

  const [metadatapopupproperties, setMetadatapopupproperties] = useState([]);

  const canAddNewMetadataPopupProperties = () => {
      const length = metadatapopupproperties.length - 1
      return metadatapopupproperties[length].v_id
  };

  const dataTimeZoneTypes = [
    "Etc/GMT",
    "America/New_York",
    "America/Denver",
    "America/Los_Angeles",
    "America/Halifax"
  ]

  const newMetadataPopupProfile = () => {
    let id = 1;
    if (metadatapopupproperties.length > 0) {
        let lastKey = canAddNewMetadataPopupProperties();
        id = ++lastKey;
    }

    console.log(id)
    const newMetadataProfile = [...metadatapopupproperties, {
        'v_id': id,
        'key': null,
        'value': null,
    }]
    setMetadatapopupproperties(newMetadataProfile);
  }

  const deleteMetadataPopupProfile = (index) => {
    const deleteProfiles = [...metadatapopupproperties]
    if (deleteProfiles.length > 1) {
        deleteProfiles.splice(index, 1);
        setMetadataproperties(deleteProfiles);
    } else {
        setMetadatapopupproperties([{
            'v_id': 1,
            'key': null,
            'value': null,
        }])
    }
  };

  if (!metadatapopupproperties.length) {
      deleteMetadataPopupProfile()
  }

  const changeKeyOnPopup = (edgeProperty,event) => { 

    let dataList = metadatapopupproperties.map((item) => {
        if(item.id == edgeProperty.id) {
            item.key = event.target.value
            return item;
        } else {
            return item;
        }
    });

    setMetadatapopupproperties(dataList);

  }

  const changeValueOnPopup = (edgeProperty,event) => { 

      let dataList = metadatapopupproperties.map((item) => {
          if(item.id == edgeProperty.id) {
              item.value = event.target.value
              return item;
          } else {
              return item;
          }
      });

      setMetadatapopupproperties(dataList);

  }

  console.log('props.defaultValues.mapper', props.defaultValues.mapper[0].maintainHistoricalRecord);
  const handlePrimaryKeyEnabledChange = () => {
    setPrimaryKeyEnabled(!primaryKeyEnabled);
  };

  const handleHistoricalEnabledChange = () => {
    setHistoricalEnabled(!historicalEnabled);
  };
  const [completeDateSet, setcompleteDateSet] = React.useState(false);
  const [mappedCsvKey, setMappedCsvKey] = React.useState("");
  const [mappedJsonKey, setMappedJsonKey] = React.useState("");

  const mappedCsvKeyChange = (e) => {
    const value = e.target.value;

    setMappedCsvKey(value.split(" ").join(""));
  };

  const handlePrimaryKeyChange = () => {
      setPrimaryKey(!primaryKey)
      setMaintainHistoryValue(true)
  }

  const handleMaintainHistoryValue = () => {
    setMaintainHistoryValue(!maintainHistoryValue)
  }

  const mappedJsonKeyChange = e => {
    const value = e.target.value;

    setMappedJsonKey(value.split(" ").join(""));
  };
  const [checked, setChecked] = useState(true);
  const [checked2, setChecked2] = useState(false);
  const JsonMapperFormContent = (
    <JsonMapperForm
      register2={register2}
      errors2={errors2}
      control2={control2}
      handleSubmit2={handleSubmit2}
      reset2={reset2}
      mapperList={mapperList}
      setMapperList={setMapperList}
      setMapperError={setMapperError}
      handleClose={handleClose}
      primaryKeyEnabled={primaryKeyEnabled}
      historicalEnabled={historicalEnabled}
      completeDateSet={completeDateSet}
      fileType={fileType}
      selectedMapper={selectedMapper}
    ></JsonMapperForm>
  );

  const getParentOrgId = () => {
    const userInfoJson = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(userInfoJson);
    const parentcompanyid = userInfo["parentcompanyid"];
    return parentcompanyid;
  };

  const userInfoJson = localStorage.getItem("userInfo");
  const userInfo = JSON.parse(userInfoJson);

  const parentcompanyid = getParentOrgId();
  const companyid = userInfo["companyid"];
  const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;

  const canAddNewMetadataProperties = () => {
    const length = metadataproperties.length - 1;
    return metadataproperties[length].v_id;
  };

  const newMetadataProfile = () => {
    let id = 1;
    if (metadataproperties.length > 0) {
      let lastKey = canAddNewMetadataProperties();
      id = ++lastKey;
    }

    console.log(id);
    const newMetadataProfile = [
      ...metadataproperties,
      {
        v_id: id,
        key: null,
        value: null,
      },
    ];
    setMetadataproperties(newMetadataProfile);
  };

  const deleteMetadataProfile = (index) => {
    const deleteProfiles = [...metadataproperties];
    if (deleteProfiles.length > 1) {
      deleteProfiles.splice(index, 1);
      setMetadataproperties(deleteProfiles);
    } else {
      setMetadataproperties([
        {
          v_id: 1,
          key: null,
          value: null,
        },
      ]);
    }
  };

  if (!metadataproperties.length) {
    deleteMetadataProfile();
  }

  const changeKeyHandler = (edgeProperty) => (event) =>
    (edgeProperty.key = event.target.value);
  const changeValueHandler = (edgeProperty) => (event) =>
    (edgeProperty.value = event.target.value);

  const handleVertexChange = (vertexId) => {
    // console.log(vertexId)
    // console.log('vertexList', vertexList)
    const selectedVertexList = vertexList.filter(
      (vertex) => vertex.classId == vertexId
    );
    //console.log(selectedVertexList)
    if (selectedVertexList.length > 0) {
      const selectedVertex = selectedVertexList[0];
      setVertexName(selectedVertex.className);
      setVertexId(vertexId);
      if (selectedVertex.checkDuplicate) setPrimaryKeyEnabled(true);
      else setPrimaryKeyEnabled(false);

      if (selectedVertex.checkForHistoricalChanges) {
        setHistoricalEnabled(true);
        setDuplicateVertex(selectedVertex.duplicateVertex);
      } else {
        setHistoricalEnabled(false);
        setDuplicateVertex(null);
      }
    } else {
      setVertexName(undefined);
      setVertexId(undefined);
    }
  };

  const metadataList = metadataproperties.map((metadata, index) => {
    metadata.id = index;
    return (
      <RycomGrid>
        <RycomGridItem>
          <div>
            {" "}
            <TextField
              id="outlined-multiline-flexible"
              label="key"
              variant="outlined"
              inputRef={register({
                required: {
                  value: true,
                  message: "Metadata key is required",
                },
              })}
              value={metadata.key}
              onChange={changeKeyHandler(metadata)}
            />
            <span>
              <span>
                {errors["edgepropertykey" + metadata.id] && (
                  <span>{errors["edgepropertykey" + metadata.id].message}</span>
                )}
              </span>
            </span>
          </div>
        </RycomGridItem>
        <RycomGridItem>
          <span>
            {" "}
            <TextField
              id="outlined-multiline-flexible"
              label="Value"
              variant="outlined"
              inputRef={register({
                required: {
                  value: true,
                  message: "Edge key is required",
                },
              })}
              value={metadata.value}
              onChange={changeValueHandler(metadata)}
            />
            <span>
              {errors["PropertyValue" + metadata.id] && (
                <span>{errors["PropertyValue" + metadata.id].message}</span>
              )}
            </span>
          </span>
        </RycomGridItem>
        <RycomGridItem>
          <Button
            color="secondary"
            variant="contained"
            style={{marginTop:"20px"}}
            size="small"
            disabled={metadataproperties.length == 1}
            value="delete Edge"
            onClick={(e) => {
              const deleteProfiles = [...metadataproperties];

              if (metadataproperties.length > 1) {
                const deleteProfiles1 = deleteProfiles.filter((edge) => {
                  return edge.v_id != metadata.v_id;
                });
                setMetadataproperties(deleteProfiles1);
              } else {
                setMetadataproperties([
                  {
                    v_id: 1,
                    key: null,
                    value: null,
                  },
                ]);
              }
            }}
          >
            Delete Metadata
          </Button>
        </RycomGridItem>
      </RycomGrid>
    );
  });

  const mapperDisplayList = mapperList.map((mapper) => {

    const InnerCsvContent = mapper.metadata?.map((metadata) => (
      <Grid container spacing={12} style={{ padding: 10, marginLeft: 25 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="metadata Key"
            value={metadata ? metadata.key : ""}
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
          <RycomInfoPair
            label="metadata value"
            value={metadata ? metadata.value : ""}
          ></RycomInfoPair>
        </Grid>
      </Grid>
    ));

    return (
      <>
        <Grid container spacing={12} className={classes.gridBox}>
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="Position"
              value={mapper ? mapper.postion : ""}
            ></RycomInfoPair>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={2}>
            <RycomInfoPair
              label="CSV Column Header"
              value={mapper ? mapper.key : ""}
            ></RycomInfoPair>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={2}>
            <RycomInfoPair
              label="Target Column Name"
              value={mapper ? mapper.mappedJsonKey : ""}
            ></RycomInfoPair>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={2}>
            <RycomInfoPair
              label="Transformation Type"
              value={mapper ? mapper.transformationType : ""}
            ></RycomInfoPair>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={2}>
            <RycomInfoPair
              label="Column Datatype"
              value={mapper ? mapper.valuetype : ""}
            ></RycomInfoPair>
          </Grid>

          <Grid>
            <span style={{marginRight: "5px"}}>
                <Button color="default" variant="contained" size="small" value="edit Mapper" onClick={()=>{handleOpen(mapper)}}>Edit Mapper</Button>
            </span>
            <span>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                value="delete Mapper"
                onClick={(e) => {
                  const deleteProfiles = [...mapperList];
                  if (mapperList.length > 1) {
                    const deleteProfiles1 = deleteProfiles.filter(
                      (mapperListValue) => {
                        return mapperListValue.postion != mapper.postion;
                      }
                    );
                    setMapperList(deleteProfiles1);
                  } else {
                    setMapperList([]);
                  }
                }}
              >
                Delete Mapper
              </Button>
            </span>
          </Grid>

          {InnerCsvContent}
        </Grid>
      </>
    )
  });

  const paddingStyle = {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
  };

  const jsonMapperDisplayList = mapperList.map((mapper) => {

    // If child element has then it will show
    const InnerJsonContent = mapper.nestedJsonTypeConfig?.map(
      (nestermapper) => (
        // <div>
          <Grid container spacing={12} style={{ paddingStyle, marginLeft: 60 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
              <RycomInfoPair
                label="Key"
                value={nestermapper ? nestermapper.key : ""}
              ></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={2}>
              <RycomInfoPair
                label="mappedJsonKey"
                value={nestermapper ? nestermapper.mappedJsonKey : ""}
              ></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={2}>
              <RycomInfoPair
                label="TransFormationType"
                value={nestermapper ? nestermapper.transformationType : ""}
              ></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={2}>
              <RycomInfoPair
                label="Marked As PrimaryKey"
                value={nestermapper.markedAsPrimaryKey == true ? 'TRUE' : "FALSE"}
              ></RycomInfoPair>
            </Grid>
          </Grid>
        // </div>
      )
    );

    return (
      <>
        <Grid container spacing={12} className={classes.gridBox}>
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="Original Key"
              value={mapper ? mapper.key : ""}
            ></RycomInfoPair>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="Mapped Key"
              value={mapper ? mapper.mappedJsonKey : ""}
            ></RycomInfoPair>
          </Grid>

          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="Transformation Type"
              value={mapper ? mapper.transformationType : ""}
            ></RycomInfoPair>
          </Grid>

          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="MARKED AS PRIMARY KEY"
              value={mapper ? mapper.markedAsPrimaryKey : ""}
            ></RycomInfoPair>
          </Grid>

          {/* <Grid item xs={12} sm={6} lg={3} xl={2}>
                <RycomInfoPair label="Attribute Datatype" value={mapper ? mapper.valuetype : ''}></RycomInfoPair>
            </Grid> */}
          {/* {fileType === 'JSON-CSV DATA GRID'?
            <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair label="Transformation Type" value={mapper ? mapper.transformationType : ''}></RycomInfoPair>
            </Grid>
            :""} */}

            <Grid item xs={12} sm={6} lg={2} xl={2} style={{ marginTop: "30px" }}>
              <span style={{ marginRight: "5px" }}>
                <Button
                  color="default"
                  variant="contained"
                  size="small"
                  value="edit Mapper"
                  onClick={() => {
                    handleOpen(mapper);
                  }}
                >
                  Edit Mapper
                </Button>
              </span>
              <span>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  value="delete Mapper"
                  onClick={(e) => {
                    const deleteProfiles = [...mapperList];
                    if (mapperList.length > 1) {
                      const deleteProfiles1 = deleteProfiles.filter(
                        (mapperListValue) => {
                          return mapperListValue.key != mapper.key;
                        }
                      );
                      setMapperList(deleteProfiles1);
                    } else {
                      setMapperList([]);
                    }
                  }}
                >
                  Delete Mapper
                </Button>
              </span>
            </Grid>
          
         {InnerJsonContent}
        </Grid>
      </>
    )
  });

  // inner json content start here
  let jsonInnerMapperDisplayList = "";
  jsonInnerMapperDisplayList = mapperList.map((mapper) => {
    let content = "";
    const InnerJsonContent = mapper.nestedJsonTypeConfig?.map(
      (nestermapper) => (
        <Grid container spacing={12} style={{ paddingStyle, marginLeft: 60 }}>
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="Original Key"
              value={nestermapper ? nestermapper.key : ""}
            ></RycomInfoPair>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="Mapped Key"
              value={nestermapper ? nestermapper.mappedJsonKey : ""}
            ></RycomInfoPair>
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair label="Attribute Datatype" value={nestermapper ? nestermapper.valuetype : ''}></RycomInfoPair>
        </Grid> */}
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="TransFormationType"
              value={nestermapper ? nestermapper.transformationType : ""}
            ></RycomInfoPair>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair
              label="Marked As Primary Key"
              value={nestermapper ? nestermapper.markedAsPrimaryKey : ""}
            ></RycomInfoPair>
          </Grid>
          {/* {fileType === 'JSON-CSV DATA GRID'?
        <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair label="Transformation Type" value={nestermapper ? nestermapper.transformationType : ''}></RycomInfoPair>
        </Grid>
        :""} */}
        </Grid>
      )
    );
    content = <span>{InnerJsonContent}</span>;
    return content;
  }); // inner json content end here

  // inner csv details diplayed from here
  let innerCsvDetails = "";
  innerCsvDetails = mapperList.map((mapper) => {
    let content = "";
    const InnerCsvContent = mapper.metadata?.map((metadata) => (
      <Grid container spacing={12} style={{ padding: 10, marginLeft: 25 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="metadata Key"
            value={metadata ? metadata.key : ""}
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
          <RycomInfoPair
            label="metadata value"
            value={metadata ? metadata.value : ""}
          ></RycomInfoPair>
        </Grid>
      </Grid>
    ));
    content = <div>{InnerCsvContent}</div>;
    return content;
  });
  // inner csv details end here

  useEffect(() => {
    if (companyList.length == 0) {
      //OrganisationService.getCompanyByParentId(parentcompanyid)
      OrganisationService.getCompanyParentById(companyid)
        .then((res) => {
          if (res && res.data && res.data) {
            setCompanyList(res.data);
            getVenueList(defaultValues.companyid);
            getVertexList(parentcompanyid);
          }
        })
        .catch((error) => {
          console.error("getConnector =>", error);
        });
    }
  }, []);

  const getVenueList = (value) => {
    setCompanyId(value);
    VenueService.getVenueByCompanyId(value)
      .then((res) => {
        if (res && res.data && res.data) {
          setVenueList(res.data);
        }
      })
      .catch((error) => {
        console.error("getConnector =>", error);
      });
    return value;
  };

  const getDeviceList = (event) => {
    const venueId = event.target.value;
    DeviceService.getDeviceByVenueId(venueId)
      .then((res) => {
        if (res && res.data && res.data) {
          setDeviceList(res.data);
        }
      })
      .catch((error) => {
        console.error("getConnector =>", error);
      });
    return event.target.value;
  };

  let mapperContent = "";

  const companyMenuList = companyList.map((company, index) => (
    <MenuItem key={++index} value={company.companyid}>
      {" "}
      {company.companyname}
    </MenuItem>
  ));
  const venueMenuList = venueList.map((venue, index) => (
    <MenuItem key={++index} value={venue.venueid}>
      {" "}
      {venue.venuename}
    </MenuItem>
  ));
  const deviceMenuList = deviceList.map((device, index) => (
    <MenuItem key={++index} value={device.deviceid}>
      {" "}
      {device.devicename}
    </MenuItem>
  ));

  if (fileType === "CSV") {
    mapperContent = mapperDisplayList;
  } else if (fileType === "JSON" || fileType === "JSON-CSV DATA GRID") {
    mapperContent = jsonMapperDisplayList;
  }

  const handleMapperSubmit = (data) => {

    const metaDataPayload = metadatapopupproperties.filter((metadatapropertiesValue) => {
        return (metadatapropertiesValue.key !== '' && metadatapropertiesValue.key !== null)
    });

    const mapperPayload = {
      key: data.key,
      postion: data.position,
      mappedJsonKey: data.mappedJsonKey,
      transformationType: data.transformationType,
      valuetype: data.valueType,
      metadata: metaDataPayload,
      isActive: true,
      maintainHistoricalRecord: maintainHistoryValue
    };

    if(primaryKey) {
        mapperPayload['markedAsPrimaryKey'] = primaryKey;
    }

    if(data.transformationType && data.transformationType=='DATE'){
        mapperPayload['transformationConfiguration']=transformationConfiguration
    }

    let mapperListValues = [];

      let findExistingMapperWithSamePosition = mapperList.find(mapper => mapper.postion.toString() === mapperPayload.postion.toString());
      
      if(Object.keys(selectedMapper).length === 0) { // is datamapper edit or add? Adding here

          if (findExistingMapperWithSamePosition) {
            alert('Position already exist: Please enter different Position or Delete the existing mapper with same position number')
          } else {
            mapperListValues = [...mapperList, mapperPayload]
          }
          
        } else { // editing
          
          if(findExistingMapperWithSamePosition && findExistingMapperWithSamePosition.postion.toString() !== selectedMapper.postion.toString()) {
            alert('Position already exist: Please enter different Position or Delete the existing mapper with same position number')
          } else {
              
              mapperListValues = mapperList.map(data => {
                  let mapperListValue;
                  if (data.postion === selectedMapper.postion) {
                      mapperListValue = { ...mapperPayload }
                  } else {
                      mapperListValue = { ...data }
                  }

                  return mapperListValue;
              })

          }

      }

      if(mapperListValues.length > 0) {
          
          mapperListValues.sort((a, b) => {
              if (parseInt(a.postion) > parseInt(b.postion)) return 1;
              else if (parseInt(a.postion) < parseInt(b.postion)) return -1;
              else return 0;
          });
  
          setMapperList(mapperListValues);
          setMapperError(false);
          setMetadatapopupproperties([]);
          setPrimaryKey(false);
          handleClose();

      }

    };

  const onSubmit = (data) => {
    console.log("data")
    console.log(data)
    if (mapperList.length === 0) {
      setMapperError(true);
      return false;
    }

    //console.log(data);

    const metadatapropertiesContent = metadataproperties.filter(
      (metadatapropertiesValue) => {
        return (
          metadatapropertiesValue.key !== "" &&
          metadatapropertiesValue.key !== null
        );
      }
    );

    let payload = {
      datamapperid: id,
      datamappername: data.datamappername,
      datamapperdescription: data.datamapperdescription,
      isActive: true,
      datacreated: 1582338051,
      //"parentcompanyid": parentcompanyid,
      parentcompanyid: paramID,
      companyid: data.companyid,
      venueid: data.venueid,
      deviceid: data.deviceid,
      createdbyuserid: "0",
      metadata: metadatapropertiesContent,
      datainfo: {
        filetype: data.filetype,
        headerpresent: false,
        dataKey: "",
        cells: "",
      },
      checkDuplicate: primaryKeyEnabled,
      checkForHistoricalChanges: historicalEnabled,
      duplicateVertex: duplicateVertex,
      mapper: mapperList,
      dataflow:data.dataflow
    };

    if (fileType == "JSON") {
      payload["vertexName"] = vertexName;
      payload["vertexId"] = vertexId;
      payload.datainfo.dataKey = data.dataKey;
    } else if (fileType == "JSON-CSV DATA GRID") {
      payload["vertexName"] = vertexName;
      payload["vertexId"] = vertexId;
      payload.datainfo.dataKey = data.dataKey;
      payload.datainfo.cells = data.cells;
    }

    console.log(data.headerpresent);
    if (data.headerpresent == "TRUE") payload.datainfo.headerpresent = true;

    console.log("updateDataMapper", payload);
    DataMapperService.updateDataMapper(payload)
      .then((res) => {
        console.log("updateDataMapperResult", res);
        if (res.status === 200) alert("DataMapper updated successfully");
        history.push("/topics/datamapper");
      })
      .catch((error) => {
        alert("DataMapper error");
        console.error("DataMapper =>", error);
      });
  };

  const getVertexList = () => {
    console.log("inside the getVertex List");
    let newParentcompanyid =
      parentcompanyid == "0"
        ? userInfo["companyid"]
        : userInfo["parentcompanyid"];

    DocumentClassService.getAllVertex(newParentcompanyid)
      .then((res) => {
        if (res && res.data && res.data) {
          setVertexList(res.data);
          console.log("vertexId");
          console.log(vertexId);
        }
      })
      .catch((error) => {
        console.error("getVertexList =>", error);
      });
  };

  const vertexMenuList = vertexList.map((vertex, index) => (
    <MenuItem key={++index} value={vertex.classId}>
      {" "}
      {vertex.className}
    </MenuItem>
  ));

  const metadataPopupList = metadatapopupproperties.map((metadata, index) => {
    console.log("metadata")
    console.log(metadata)
    metadata.id = index;
    return <RycomGrid>
        <RycomGridItem>
            <div>  <TextField
                id="outlined-multiline-flexible"
                label="key"
                variant="outlined"
                inputRef={register({
                    required: {
                        value: true,
                        message: 'Metadata key is required'
                    }
                })}
                value={metadata.key}
                // onChange={changeKeyHandler(metadata)}
                onChange={(e) => changeKeyOnPopup(metadata,e)}
            />
                <span>
                    <span>
                        {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
                    </span>
                </span>
            </div>
        </RycomGridItem>
        <RycomGridItem>
            <span>  <TextField
                id="outlined-multiline-flexible"
                label="Value"
                variant="outlined"
                inputRef={register({
                    required: {
                        value: true,
                        message: 'Edge key is required'
                    }
                })}
                value={metadata.value}
                // onChange={changeValueOnPopup(metadata)}
                onChange={(e) => changeValueOnPopup(metadata,e)}
            />
                <span>
                    {errors['PropertyValue' + metadata.id] && <span>{errors['PropertyValue' + metadata.id].message}</span>}
                </span>
            </span>
        </RycomGridItem>
        <RycomGridItem>
            <Button color="secondary" style={{marginTop:"20px"}} variant="contained" size="small" disabled={metadatapopupproperties.length == 1} value="delete Edge"
                onClick={(e) => {
                    const deleteProfiles = [...metadatapopupproperties]

                    if (metadatapopupproperties.length > 1) {
                        const deleteProfiles1 = deleteProfiles.filter(edge => {
                            return edge.id != metadata.id
                        });
                        setMetadatapopupproperties(deleteProfiles1);
                    } else {
                        setMetadatapopupproperties([{
                            'v_id': 1,
                            'key': null,
                            'value': null
                        }])
                    }
                }}>Delete Metadata</Button>
        </RycomGridItem>
    </RycomGrid>;
  });

  return (
    <div>
      <form className={classes.root}>
        <RycomPageHeader
          title="Enter the DataMapper"
          links={[{ title: "Back", url: "/topics/datamapper/detail/" + id }]}
        ></RycomPageHeader>

        <RycomSectionHeader title="DataMapper Form"></RycomSectionHeader>
        <RycomGrid>
          <RycomGridItem>
            <RycomSelectField
              id="companyid"
              label="companyid"
              name="companyid"
              onChangeHandler={(event) => {
                return getVenueList(event[0].target.value);
              }}
              register={register}
              control={control}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: "Company is required",
                },
              }}
            >
              {companyMenuList}
            </RycomSelectField>
          </RycomGridItem>
          <RycomGridItem>
            <RycomSelectField
              id="Venue"
              label="Venue"
              name="venueid"
              onChangeHandler={(event) => {
                return getDeviceList(event[0]);
              }}
              register={register}
              control={control}
              disabled={!companyId}
            >
              {venueMenuList}
            </RycomSelectField>
          </RycomGridItem>
          <RycomGridItem>
            <RycomSelectField
              id="deviceId"
              label="Device"
              name="deviceid"
              disabled={!companyId}
              onChangeHandler={(event) => {
                return event[0].target.value;
              }}
              register={register}
              control={control}
            >
              {deviceMenuList}
            </RycomSelectField>
          </RycomGridItem>
        </RycomGrid>
        <RycomGrid>
          <RycomGridItem>
            <RycomTextField
              id="datamappername"
              label="Data Mapper Name"
              name="datamappername"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: "Data Mapper Name is required",
                },
              }}
            />
          </RycomGridItem>
          <RycomGridItem>
            <RycomTextField
              id="datamapperdescription"
              label="Data Mapper Description"
              name="datamapperdescription"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: "Data Mapper Description is required",
                },
              }}
            />
          </RycomGridItem>
        </RycomGrid>
        <RycomSectionHeader title="Data Info"></RycomSectionHeader>
        <RycomGrid>
          <RycomGridItem>
            <RycomSelectField
              id="File Type"
              label="File Type"
              name="filetype"
              onChangeHandler={(event) => {
                fileTypeHandler(event[0].target.value);
                return event[0].target.value;
              }}
              value={fileType}
              register={register}
              control={control}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: "File Type is required",
                },
              }}
              disabled
            >
              <MenuItem key="1" value="CSV">
                CSV
              </MenuItem>
              <MenuItem key="2" value="JSON">
                JSON{" "}
              </MenuItem>
              <MenuItem key="3" value="JSON-CSV DATA GRID">
                JSON-CSV DATA GRID{" "}
              </MenuItem>
            </RycomSelectField>
          </RycomGridItem>

          {fileType == "CSV" ? (
            <RycomGridItem>
              <RycomSelectField
                id="headerpresent"
                label="Header Present"
                name="headerpresent"
                onChangeHandler={(event) => {
                  headerPresentHandler(event[0].target.value)
                  return event[0].target.value;
                }}
                value={headerPresent}
                register={register}
                control={control}
                errors={errors}
                errorDescription={{
                  required: {
                    value: true,
                    message: "Header Present is required",
                  },
                }}
              >
                <MenuItem key="1" value="TRUE">
                  {" "}
                  TRUE
                </MenuItem>
                <MenuItem key="2" value="FALSE">
                  FALSE{" "}
                </MenuItem>
              </RycomSelectField>
            </RycomGridItem>
          ) : (
            // <span>
              <RycomGridItem>
                <RycomTextField
                  id="dataKey"
                  label="Data Source Attribute"
                  name="dataKey"
                  value={props.defaultValues.datainfo.dataKey}
                  register={register}
                  errors={errors}
                  errorDescription={{}}
                  disabled
                />
            </RycomGridItem>
          )}
          <RycomGridItem>
              <RycomSelectField
                  id="dataflow"
                  label="Connector Flow Types"
                  name="dataflow"
                  // onChangeHandler={(event) => {
                  //     setDataFlowType(event[0].target.value);
                  //     return event[0].target.value;
                  // }}
                  register={register}
                  control={control}
                  errors={errors}
                  errorDescription={{
                      required: {
                      value: true,
                      message: "Connector Flow Type is required",
                      },
                  }}
                  >
                  <MenuItem value="inbound" key="1">Data Storage</MenuItem>
                  <MenuItem value="outbound" key="2">Data Piping</MenuItem>
              </RycomSelectField>
          </RycomGridItem>
        </RycomGrid>


        {fileType == "JSON-CSV DATA GRID" ? (
          <RycomGrid>
            {" "}
            <RycomTextField
              id="cells"
              label="Cell Source Attribute"
              name="cells"
              value={props.defaultValues.datainfo.cells}
              register={register}
              errors={errors}
              errorDescription={{}}
              disabled
            />
          </RycomGrid>
        ) : (
          ""
        )}

        <RycomSectionHeader title="Meta Data"></RycomSectionHeader>
        {metadataList}
        <Grid container spacing={12} className={classes.rycomButtonGroup}>
          <Grid item xs={12} sm={4} lg={4} xl={4}></Grid>
          <Grid item xs={12} sm={4} lg={4} xl={4}></Grid>
          <Grid item xs={12} sm={4} lg={4} xl={4}>
            <span>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                value="Add Edge"
                onClick={newMetadataProfile}
              >
                Add Metadata
              </Button>
            </span>
          </Grid>
        </Grid>
        {fileType == "JSON" || fileType == "JSON-CSV DATA GRID" ? (
          <span>
            <RycomSectionHeader title="Graph Info"></RycomSectionHeader>
            <RycomGrid>
              <RycomGridItem>
                <FormControlLabel
                  style={{ width: 300 }}
                  control={
                    <Checkbox
                      checked={primaryKeyEnabled}
                      onChange={handlePrimaryKeyEnabledChange}
                      name="flattening"
                    />
                  }
                  label="Check for Duplicate Record"
                  disabled
                />
              </RycomGridItem>
              <RycomGridItem>
                <FormControlLabel
                  style={{ width: 300 }}
                  control={
                    <Checkbox
                      checked={historicalEnabled}
                      onChange={handleHistoricalEnabledChange}
                      name="flattening"
                    />
                  }
                  label="Maintain Historical Record - Changes"
                  disabled
                />
                {historicalEnabled ? <span>
                  <FormControl component="fieldset">
                    <Controller
                      control={control}
                      name="flattening"
                      as={
                        <RadioGroup row>
                          <FormControlLabel
                            value="fieldLevel"
                            control={<Radio checked={checked} />}
                            label="Field Level"
                            onChange={() => {
                              setChecked2(false);
                              setChecked(true);
                            }}
                          />
                          <FormControlLabel
                            value="completeDateSet"
                            control={<Radio />}
                            label="Complete DataSet"
                            onChange={() => {
                              setChecked(false);
                              setcompleteDateSet(true);
                            }}
                          />

                        </RadioGroup>
                      }
                    />
                  </FormControl></span> : ""}
              </RycomGridItem>
            </RycomGrid>
          </span>
        ) : (
          ""
        )}
        <RycomSectionHeader
          title={
            mapperError ? (
              <span>
                Mapper Configuration
                <span className={classes.invalidMessage}>
                  Mapper Details is required
                </span>
              </span>
            ) : (
              <span>Mapper Configuration</span>
            )
          }
        ></RycomSectionHeader>
        <div>
          {mapperContent}
          {/* {jsonInnerMapperDisplayList} */}
          {/* {innerCsvDetails} */}
          <Grid container spacing={12} className={classes.rycomButtonGroup}>
            <Grid item xs={12} sm={4} lg={4} xl={4}></Grid>
            <Grid item xs={12} sm={4} lg={4} xl={4}></Grid>
            <Grid item xs={12} sm={4} lg={4} xl={4}>
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  value="Add Mapper"
                  onClick={() => {
                    handleOpen({});
                  }}
                >
                  Add Mapper
                </Button>
              </span>
            </Grid>
          </Grid>
          ;
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <div>
                  <form key={2} className={classes.root}>
                    {fileType === "CSV" ? (
                      <span>
                        {" "}
                        <RycomSectionHeader title="Header - Excel Column/Json Attribute"></RycomSectionHeader>
                        <Grid
                          container
                          spacing={12}
                          style={{ padding: 4 }}
                          className={classes.rycomGridItem}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={3}
                            xl={3}
                            className={classes.rycomGridItem}
                          >
                            <TextField
                              id="outlined-multiline-flexible"
                              label="Position"
                              name="position"
                              type="number"
                              // value={
                              //   selectedMapper.postion
                              //     ? selectedMapper.postion
                              //     : ""
                              // }
                              defaultValue={
                                selectedMapper.postion && parseInt(selectedMapper.postion)
                              }
                              variant="outlined"
                              style={{ width: 300 }}
                              inputRef={register2({
                                required: {
                                  value: true,
                                  message: "Column Position is required",
                                },
                              })}
                            />
                            <span>
                              <span className={classes.invalidMessage}>
                                {errors2["position"] && (
                                  <span>{errors2["position"].message}</span>
                                )}
                              </span>
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={3}
                            xl={3}
                            className={classes.rycomGridItem}
                          >
                            <TextField
                              id="outlined-multiline-flexible"
                              label="CSV Column - Header"
                              name="key"
                              defaultValue={
                                selectedMapper && selectedMapper.key ? selectedMapper.key : ""
                              }
                              variant="outlined"
                              style={{ width: 300 }}
                              inputRef={register2({
                                required: {
                                  value: true,
                                  message: "CSV Column Header is required",
                                },
                              })}
                              // value={mappedCsvKey}
                              onChange={mappedCsvKeyChange}
                            />
                            <span>
                              <span className={classes.invalidMessage}>
                                {errors2["key"] && (
                                  <span>{errors2["key"].message}</span>
                                )}
                              </span>
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                              <TextField
                                  id="targetColumnName"
                                  label="Target Column Name"
                                  name="mappedJsonKey"
                                  variant="outlined"
                                  style={{ width: 300 }}
                                  inputRef={register2({
                                      // required: {
                                      //     value: true,
                                      //     message: 'Column Header is required'
                                      // }
                                  })}
                                  defaultValue={selectedMapper && selectedMapper.mappedJsonKey ? selectedMapper.mappedJsonKey : ""}
                                  // value={
                                  //   mappedJsonKey && mappedJsonKey ? mappedJsonKey : ''
                                  // }

                                  onChange={mappedJsonKeyChange}
                              />
                              <span>
                                  <span className={classes.invalidMessage}>
                                      {errors2['targetColumnName'] && <span>{errors2['targetColumnName'].message}</span>}
                                  </span>
                              </span>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                              <span>
                                  <Controller
                                      as={
                                          <TextField
                                              id="transformationType"
                                              select
                                              label="Transformation Type"
                                              variant="outlined"
                                              name="transformationType"
                                          >
                                              <MenuItem key="0" value="">Select Transformation Type</MenuItem>
                                              <MenuItem key="1" value="CURRENCY">CURRENCY</MenuItem>
                                              <MenuItem key="2" value="DATE">DATE</MenuItem>
                                              <MenuItem key="3" value="BOOPROBABILITY_PERCENTAGELEAN">BOOLPROBABILITY PERCENTAGEEAN</MenuItem>
                                          </TextField>
                                      }
                                      control={control2}
                                      name="transformationType"
                                      defaultValue={selectedMapper && selectedMapper.transformationType ? selectedMapper.transformationType : ''}
                                      style={{ width: 300 }}
                                      onChange={(event) => {
                                          console.log(event[0].target.value);
                                          setTransformationType(event[0].target.value)
                                          if(event[0].target.value!='DATE'){
                                              setTransformationConfiguration({})
                                          }
                                          return event[0].target.value
                                      }}
                                  />
                                  <span className={classes.invalidMessage}>
                                      {errors2["transformationType"] && <span>{errors2["transformationType"].message}</span>}
                                  </span>
                              </span>
                          </Grid>
                          { transformationType =='DATE'?
                            <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                              <span>
                                  <Controller
                                      as={
                                          <TextField
                                              id="timeZone"
                                              select
                                              label="Time - Zone*"
                                              variant="outlined"
                                              name="timeZone"
                                          >
                                          <MenuItem value="">Select Time Zone</MenuItem>
                                            {dataTimeZoneTypes.map((dataTimeZoneTypes1, index) => (
                                              <MenuItem key={++index} value={dataTimeZoneTypes1}>
                                                  {dataTimeZoneTypes1}
                                              </MenuItem>   
                                            ))}
                                          </TextField>
                                      }
                                      control={control2}
                                      name="timeZone"
                                      defaultValue={transformationConfiguration && transformationConfiguration.timeZone ? transformationConfiguration.timeZone : ''}
                                      onChange={(event) => {
                                          console.log(event[0].target.value);
                                          setTransformationConfiguration({timeZone:event[0].target.value})
                                          return event[0].target.value
                                      }}
                                      style={{ width: 300 }}
                                  />
                                  <span className={classes.invalidMessage}>
                                      {errors2["timeZone"] && <span>{errors2["timeZone"].message}</span>}
                                  </span>
                                </span>
                              </Grid>
                            :""}
                          {primaryKeyEnabled?
                            <>
                                <Grid item xs={12} sm={3} lg={3} xl={3} className={classes.rycomGridItem} style={{width:"300px",padding:"10px"}}>
                                    <FormControlLabel
                                        style={{ width: 300 }}
                                        control={<Checkbox
                                            checked={primaryKey} 
                                            onChange={handlePrimaryKeyChange} 
                                            name="flattening" 
                                            />}
                                        label="Primary Key"
                                    />
                                </Grid>
                                {  primaryKey && 
                                    <Grid item xs={12} sm={3} lg={3} xl={3} className={classes.rycomGridItem}>
                                        {/* <span> */}
                                            <Controller
                                                as={
                                                    <TextField
                                                        id="valueType"
                                                        select
                                                        label="Value Type"
                                                        variant="outlined"
                                                        name="valueType"
                                                        defaultValue={""}
                                                    >
                                                        <MenuItem key="1" value="STRING">STRING</MenuItem>
                                                        <MenuItem key="2" value="NUMBER">NUMBER</MenuItem>
                                                        <MenuItem key="3" value="BOOLEAN">BOOLEAN</MenuItem>
                                                    </TextField>
                                                }
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Value Type is required'
                                                    }
                                                }}
                                                control={control2}
                                                name="valueType"
                                                defaultValue={selectedMapper && selectedMapper.valuetype ? selectedMapper.valuetype : ''}
                                                style={{ width: 200 }}
                                            />
                                            <span className={classes.invalidMessage}>
                                                {errors2["valueType"] && <span>{errors2["valueType"].message}</span>}
                                            </span>
                                        {/* </span> */}
                                    </Grid>
                                }
                            
                            </>
                            :""}
                            {primaryKey || !historicalEnabled ? "" :
                            <Grid item xs={12} sm={6} lg={3} xl={3} style={{ width: 300, padding: 16 }} className={classes.rycomGridItem} >
                                <FormControlLabel
                                    style={{ width: 300 }}
                                    control={<Checkbox
                                        checked={completeDateSet == true ? completeDateSet : maintainHistoryValue} onChange={handleMaintainHistoryValue} name="flattening" />}
                                    label="Maintain Historical Record"
                                    disabled={completeDateSet == true ? completeDateSet : false}
                                />
                            </Grid>}
                          {/* <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={3}
                            xl={3}
                            className={classes.rycomGridItem}
                          >
                            <span>
                              <Controller
                                as={
                                  <TextField
                                    id="valueType"
                                    select
                                    label="Value Type"
                                    variant="outlined"
                                    name="valueType"
                                  >
                                    <MenuItem key="1" value="STRING">
                                      STRING
                                    </MenuItem>
                                    <MenuItem key="2" value="NUMBER">
                                      NUMBER
                                    </MenuItem>
                                    <MenuItem key="3" value="BOOLEAN">
                                      BOOLEAN
                                    </MenuItem>
                                  </TextField>
                                }
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Value Type is required",
                                  },
                                }}
                                control={control2}
                                name="valueType"
                                defaultValue={selectedMapper && selectedMapper.valuetype ? selectedMapper.valuetype : ''}
                                // value={
                                //   selectedMapper && selectedMapper.valuetype
                                //     ? selectedMapper.valuetype
                                //     : ""
                                // }
                                style={{ width: 200 }}
                              />
                              <span className={classes.invalidMessage}>
                                {errors2["valueType"] && (
                                  <span>{errors2["valueType"].message}</span>
                                )}
                              </span>
                            </span>
                          </Grid> */}
                        </Grid>
                        <RycomSectionHeader title="Metadata"></RycomSectionHeader>
                        {metadataPopupList}
                        <Grid container spacing={12} className={classes.rycomButtonGroup} >
                            <Grid item xs={12} sm={4} lg={4} xl={4} >
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4} xl={4} >
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4} xl={4} >
                                <span>
                                    <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newMetadataPopupProfile}>Add Metadata</Button>
                                </span>
                            </Grid>
                        </Grid>
                        {/* <Grid
                          container
                          spacing={12}
                          style={{ padding: 4 }}
                          className={classes.rycomGridItem}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            xl={6}
                            className={classes.rycomGridItem}
                          >
                            <div>
                              {" "}
                              <TextField
                                id="outlined-multiline-flexible"
                                label="key"
                                name="metadataKey"
                                register={register2}
                                variant="outlined"
                                style={{ width: 300 }}
                                inputRef={register2({})}
                                // defaultValue={selectedMapper && selectedMapper.metadata ? selectedMapper.metadata[0].key : ''}
                              />
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            xl={6}
                            className={classes.rycomGridItem}
                          >
                            <span>
                              {" "}
                              <TextField
                                id="outlined-multiline-flexible"
                                label="Value"
                                name="metadataValue"
                                style={{ width: 300 }}
                                variant="outlined"
                                inputRef={register2({})}
                                // defaultValue={selectedMapper && selectedMapper.metadata ? selectedMapper.metadata[0].value : ''}
                              />
                            </span>
                          </Grid>
                        </Grid> */}
                        <RycomSubmitModel>
                          <Button
                            type="reset"
                            variant=""
                            size="large"
                            onClick={() => {
                              reset2();
                            }}
                          >
                            Clear
                          </Button>
                          <Button
                            type
                            color="primary"
                            variant="contained"
                            size="large"
                            value="submit"
                            onClick={handleSubmit2(handleMapperSubmit)}
                          >
                            Submit
                          </Button>
                        </RycomSubmitModel>
                      </span>
                    ) : (
                      JsonMapperFormContent
                    )}
                  </form>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
        <RycomSubmitModel>
          <Button
            type="reset"
            variant=""
            size="large"
            onClick={() => {
              reset();
            }}
          >
            Clear
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            value="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </RycomSubmitModel>
      </form>
    </div>
  );
}
