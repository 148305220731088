import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'


export const saveTaskEdge = (vertexPayload) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/edgeCreationTask`,
          vertexPayload, addBearerToken());
}
export const updateTaskEdge = (vertexPayload) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/edgeCreationTask`,
          vertexPayload, addBearerToken());
}
export const getAllTaskEdge = (companyId) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/edgeCreationTask/parentcompany/${companyId}`, addBearerToken());
}

export const getTaskEdgeById = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/edgeCreationTask/${id}`, addBearerToken());
}

export const deleteTaskEdge = (id) => {
    return axios.delete(`${environment.dataBaseUrl}/api/dms/edgeCreationTask/delete/${id}`,  addBearerToken());
}

export const executeTask = (task) => {
    console.log(JSON.stringify(task));
    return axios.post(`${environment.dataBaseUrl}/api/dms/execEdgeCreationTask`,
            task, addBearerToken()
    );
}

export const getTaskEdgeStatus = () => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/edgeCreationTask/status`, addBearerToken());
}