import React, { Component } from 'react'
import RycomTable from '../common/RycomTable'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomTableHeader from '../common/RycomTableHeader'
import { Link } from 'react-router-dom'
import * as SchedulerService from '../../services/SchedulerService';

class SchedulerList extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      schedulers: [],
    }
  }

  componentDidMount() {
    console.log('componentDidMount');
    SchedulerService.getAllScheduler()
      .then((res) => {
        if (res && res.data && res.data) {
          const schedulers = res.data;
          console.log(res);
          this.setState({ schedulers: schedulers });
        }
      })
      .catch((error) => {
        console.error('Task =>', error)
      })
  }
  
   render() {
    const columns = [
      { id: 'schedulerName', label: 'Scheduler Name', minWidth: 170 },
      { id: 'schedulerDescription', label: 'Scheduler Description', minWidth: 170 },
      { id: 'cronExpression', label: 'Cron Expression', minWidth: 100 }
    ];

     
      return (<div>
        <RycomPageHeader title="Scheduler List" links={[{title:'Add scheduler',url:'/topics/scheduler/form', role:"create:scheduler"}]}></RycomPageHeader>
        <RycomTableHeader title="Scheduler" buttonTitle="Add Scheduler"></RycomTableHeader>
        <RycomTable rows={this.state.schedulers} columns={columns}></RycomTable>
      </div>);
    } 
}
export default SchedulerList;
