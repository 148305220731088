import React from 'react';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomTextField from '../common/RycomTextField'
import RycomSelectField from '../common/RycomSelectField'
import MenuItem from '@material-ui/core/MenuItem';
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {  useState } from 'react'


const useStyles = makeStyles(theme => ({
  invalidMessage: {
      display: 'inline-block',
      fontSize: '12px',
      color: 'red',
      paddingLeft: '12px',
    }
  
}));




export default function ConnectorEditSFTPConfiguration(props) {

  const classes = useStyles();
  const { connectorType, register, errors, control } = props
  console.log("Edit props")
  console.log(props)
  console.log(props.control.defaultValuesRef.current.connectionInfo.configuration.publicKeyAuthenticaiton)

  const [publicKeyAuthenticaiton, setPublicKeyAuthenticaiton] = useState(props.control.defaultValuesRef.current.connectionInfo.configuration.publicKeyAuthenticaiton+"");
  console.log(publicKeyAuthenticaiton+"")
  return (
      <RycomGrid>
        <RycomGridItem>
        <TextField
                id="outlined-multiline-flexible"
                label="PassPhrase"
                variant="outlined"
                inputRef={register({
                 })}
                name="passPhrase"
                type="password"/>
               <span className={classes.invalidMessage}>
                {errors['passPhrase'] && <span>{errors['passPhrase'].message}</span>}
            </span>
        </RycomGridItem>
        <RycomGridItem>
          <RycomSelectField id="publicKeyAuthenticaiton"
            label="Public Key Authentication"
            name="publicKeyAuthenticaiton"
             onChangeHandler={(event) => {
               console.log(event[0].target.value)
               setPublicKeyAuthenticaiton(event[0].target.value+"")
              return event[0].target.value+""
            }}
            register={register}
            control={control}
            errors={errors}
            value={publicKeyAuthenticaiton}
            errorDescription={{
             
            }} >
            <MenuItem key="1" value="true">TRUE</MenuItem>
            <MenuItem key="2" value="false">FALSE</MenuItem>
          </RycomSelectField>
        </RycomGridItem>
      </RycomGrid>
  );
}
