import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator';


export const getAllConnector = () => {
    return axios.get(`${environment.connectorBaseUrl}/api/connect/getallconnectors`, addBearerToken()
    );
}

export const getConnectorById = (id) => {
    return axios.get(`${environment.connectorBaseUrl}/api/connect/getconnectorbyid/${id}`, addBearerToken()
    );
}

export const saveConnector = (connectorPayload) => {
    return axios.post(`${environment.connectorBaseUrl}/api/connect/create`,
         connectorPayload, addBearerToken()
         );
}

export const updateConnector = (connectorPayload) => {
    console.log(connectorPayload);
    return axios.put(`${environment.connectorBaseUrl}/api/connect/updateconnector`,
         connectorPayload, addBearerToken()
         );
}

export const deleteConnector = (id) => {
    return axios.patch(`${environment.connectorBaseUrl}/api/connect/deleteconnector/`,
    {
        "id": id
    }, addBearerToken()
    );
}

export const getConnectorFile = (type,configurationPayload) => {
    console.log("configurationPayload")
    
    console.log(JSON.stringify(configurationPayload))
     return axios.patch(`${environment.connectorBaseUrl}/api/connect/getFile/validate/${type}`,
        {
            ...configurationPayload,
        }, addBearerToken()
        );
}
