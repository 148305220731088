import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomSelectField from '../common/RycomSelectField'
import RycomTextField from '../common/RycomTextField'
import { useState } from 'react'
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import * as ConnectorService from '../../services/ConnectorService';

import ConnectorEditAPIConfiguration from './ConnectorEditAPIConfiguration';

import ConnectorEditSFTPConfiguration from './ConnectorEditSFTPConfiguration';
import ConnectorEditJDBCConfiguration from './ConnectorEditJDBCConfiguration';

import ConnectorFTPConfiguration from './ConnectorFTPConfiguration';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },

  rycomButtonGroup: {
    paddingLeft: '1100px'

  }
}));


export default function ConnectorEditTemplateForm(props) {

  const history = useHistory();
  let defaultValues = props.defaultValues;
  console.log("ConnectorEditTemplateForm props",props);

  let methodParameterValue = [];
  let metadatapropertiesContentValue = [];
  let historyValueContent = null;
  let includePathIdValueContent = null;
if (defaultValues && defaultValues.connectorType == 'api' && defaultValues.connectionInfo && defaultValues.connectionInfo.configuration) {

    let uriParametersObject = defaultValues.connectionInfo.configuration.uriParameters;
    //let pathVariableObject = defaultValues.connectionInfo.configuration.pathVariable;
    let pathVariableObject = defaultValues.pathVariable;
    defaultValues.history = defaultValues.connectionInfo.configuration.history;
    defaultValues.includePathId = defaultValues.connectionInfo.configuration.includePathId;
    //console.log('++++', defaultValues);

    metadatapropertiesContentValue = Object.keys(pathVariableObject || {}).map((key, index) => {
      return {
        'v_id': ++index,
        'key': key,
        'value': pathVariableObject[key]
      };
    })

    methodParameterValue = Object.keys(uriParametersObject || {}).map((key, index) => {
      return {
        'v_id': ++index,
        'key': key,
        'value': uriParametersObject[key]
      };
    })
 }

  const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
  defaultValues.password = "";
  const [connectorFlowType, setConnectorFlowType]=useState(defaultValues.connectorFlowType); 
  const [connectorType, setConnectorType] = useState(defaultValues.connectorType);
  console.log("connectorType:",connectorType)
  const [directionValue, setDirectionValue] = useState(defaultValues.historyOffsetDirectionType);
  const connectorTypes = ['sftp', 'sftprecursive', 'ftp', 'ftprecrusive', 'ftps', 'ftpsrecrusive', 'api', 'JDBC', "ORIENT"];
  const connectorFlowTypes=['inbound','outbound'];
  const [methodParametersContent, setMethodParametersContent] = useState(methodParameterValue);
  const [metadatapropertiesContent, setmetadatapropertiesContent] = useState(metadatapropertiesContentValue);
  const [historyValue, setHistoryValue] = useState(defaultValues.history);
  const [includePathId, setIncludePathId] = useState(defaultValues.includePathId);
  const [headerContent, setHeaderContent] = useState(defaultValues.headerContent);
  const [bodyContent, setBodyContent] = useState(defaultValues.bodyContent);
  const [dateFormat, setDateFormat] = useState(defaultValues.dateFormat);
  const [dateTimeZone, setDateTimeZone] = useState(defaultValues.dateTimeZone);
  const [includePathIdFlagValue, setIncludePathIdFlagValue] = useState(defaultValues.includePathIdFlagValue);
  const classes = useStyles();
  const [startDate, setStartDate] = useState(defaultValues.fetchStartDateString);
  const [endDate, setEndDate] = useState(defaultValues.fetchEndDateString);
  const [onChangeStartDate, setOnChangeStartDate] = useState(false);
  const [onChangeEndDate, setOnChangeEndDate] = useState(false);
  //  const startDateSubmit  = new Date(startDate).toISOString().replace('T', ' ').substring(0, 19);
  // const endDateSubmit = new Date(endDate).toISOString().replace('T', ' ').substring(0, 19);
  const startDateSubmit = moment(startDate).format('YYYY-MM-DD hh:mm:ss A');
  const endDateSubmit = moment(endDate).format('YYYY-MM-DD hh:mm:ss A');
  const [unitValue, setUnitValue] = useState(null);
  const [ jsonResponsePayload, setJsonResponsePayload ] = useState(null);
  const startDateGmt = moment(startDate).format("yyyy-MM-DD hh:mm:ss A")
  const endDateGmt = moment(endDate).format("yyyy-MM-DD hh:mm:ss A")

  const validate = (data) => {

    console.log(data)
    if(connectorLoginCredential && connectorLoginCredential.usernameKey){
      const usernameAttribute=connectorLoginCredential.usernameKey;
      const passwordAttribute=connectorLoginCredential.passwordKey;
      const credentialInfoObj ={};
      credentialInfoObj[usernameAttribute]=connectorLoginCredential.username
      credentialInfoObj[passwordAttribute]=connectorLoginCredential.password
      connectorLoginCredential['credentialInfoMap']=credentialInfoObj
      connectorLoginCredential['extractTokenAttributeKey']=connectorLoginCredential.tokenAttribute
    }
    const configuration = {
      baseURI: data.baseURI,
      pathURI: data.pathURI,
      history: data.fetchOffsetType == 'history' ? true : false,
      includePathId: includePathIdFlagValue,
      frequency: data.frequency,
      requestMethod: data.requestMethod,
      maxmiumFetchOffset: data.maxmiumFetchOffset,
      maxmiumUnitType: data.maxmiumUnitType,
      fetchType: data.fetchType,
      startParamter: data.startParamter,
      endParamter: data.endParamter,
      timeZone: data.timeZone,
      historyDurationType: data.historyDurationType,
      offsetDuration: data.offsetDuration,
      offsetDurationUnitType: data.offsetDurationUnitType,
      dateFormat: data.dateFormat,
      fetchOffsetType:
        data.fetchOffsetType === ""
          ? "lastOneDay"
          : data.fetchOffsetType,
      fetchStartDateString: startDateGmt,
      fetchEndDateString: endDateGmt,
      body: '',
      connectorLoginCredential: connectorLoginCredential,

                 password: data.password,
                  userName: data.userName,
                  port: data.port,
                  host: data.host,
                  history: true,
                  databaseName: data.databaseName,
                  dataStoreType: data.dataStoreType,
                  dataStoreName: data.dataStoreName,
                  databaseType:data.databaseType,
                  sslEnabled: data.sslEnabled=='enabled'?true:false,
                  query: data.query,
                  createdDateColumn: data.createdDateColumn,
                  modifiedDateColumn: data.modifiedDateColumn
    }
  
    configuration.pathVariable = formatObject(
      metadatapropertiesContent
    );
    configuration.uriParameters = formatObject(
      methodParametersContent
    );

    configuration.header = formatObject(headerContent);
   
    if (bodyContent && bodyContent != '') {
     // console.log(bodyContent);
      const parsedObject = JSON.parse(bodyContent);
      const jsonContent = JSON.stringify(parsedObject);
      configuration.body = jsonContent
    }

    console.log('inside the validate method',configuration)

    ConnectorService.getConnectorFile(connectorType,configuration)
      .then((res) => {
        if (res.status == 200) {
          alert("Valid Connector Configuration")
          setJsonResponsePayload(res.data);
        } else {
          alert("InValid Connector Configuration")
        }
      })
      .catch((error) => {
        alert("Connector error");
        setJsonResponsePayload(null);

        console.error("getConnector =>", error);
      });
    return false;
  }


  var startDateNew = new Date(startDate);
  console.log(
    startDateNew.getFullYear() +
    "-" +
    startDateNew.getMonth() +
    1 +
    "-" +
    startDateNew.getDate() +
    "," +
    startDateNew.getHours() +
    ":" +
    startDateNew.getMinutes() +
    ":" +
    startDateNew.getSeconds() +
    ""
  );

 
  const formatObject = (content) => {
    if (Array.isArray(content)) {
    let pathContent = {};
    let pathInfoContent = content.filter((pathInfoContentValue) => {
      return !(
        pathInfoContentValue.key == null ||
        pathInfoContentValue.value == null ||
        pathInfoContentValue.value == "" ||
        pathInfoContentValue.key == "" ||
        pathInfoContentValue.key == undefined ||
        pathInfoContentValue.value == undefined
      );
    });
    pathInfoContent = pathInfoContent.map((pathObj) => {
      delete pathObj.v_id;
      delete pathObj.id;
      return pathObj;
    });
    //console.log(pathInfoContent);
    if (pathInfoContent.length > 0) {
      for (var i = 0; i < pathInfoContent.length; i++) {
        pathContent[pathInfoContent[i].key] = pathInfoContent[i].value;
      }
    }
    return pathContent;
  }else
  return content;
  };

  const onSubmit = (data) => {
   // console.log("data")
   // console.log(data)
    
    let payload = {};
    if (connectorType == "API" || connectorType == "api") {
      if(connectorLoginCredential && connectorLoginCredential.usernameKey){
        const usernameAttribute=connectorLoginCredential.usernameKey;
        const passwordAttribute=connectorLoginCredential.passwordKey;
        const credentialInfoObj ={};
        credentialInfoObj[usernameAttribute]=connectorLoginCredential.username
        credentialInfoObj[passwordAttribute]=connectorLoginCredential.password
        connectorLoginCredential['credentialInfoMap']=credentialInfoObj
        connectorLoginCredential['extractTokenAttributeKey']=connectorLoginCredential.tokenAttribute
      }

      payload = {
        connectionInfo: {
          configuration: {
            baseURI: data.baseURI,
            pathURI: data.pathURI,
            contentType:data.contentType,
            history: data.fetchOffsetType == 'history' ? true : false,
            includePathId: includePathIdFlagValue,
            frequency: data.frequency,
            requestMethod: data.requestMethod,
            maxmiumFetchOffset: data.maxmiumFetchOffset,
            maxmiumUnitType: data.maxmiumUnitType,
            fetchType: data.fetchType,
            startParamter: data.startParamter,
            endParamter: data.endParamter,
            timeZone: data.timeZone,
            historyDurationType: data.historyDurationType,
            offsetDuration: data.offsetDuration,
            offsetDurationUnitType: data.offsetDurationUnitType,
            dateFormat: data.dateFormat,
            query: data.query,
            fetchOffsetType:
              data.fetchOffsetType === ""
                ? "lastOneDay"
                : data.fetchOffsetType,
            fetchStartDateString: startDateGmt,
            fetchEndDateString: endDateGmt,
            body: '',
            connectorLoginCredential: connectorLoginCredential,
          },
          type: "api",
          dataflow:data.dataflow,
        },
        userName: data.userName,
        connectorName: data.connectorName,
        dataClassificationID: data.dataClassificationID,
      };

      if (data.tokenValue) {
        headerContent["api-key"] = data.tokenValue;
      }

      payload.connectionInfo.configuration.pathVariable = formatObject(
        metadatapropertiesContent
      );
      payload.connectionInfo.configuration.uriParameters = formatObject(
        methodParametersContent
      );

      payload.connectionInfo.configuration.header = formatObject(
        headerContent
      );
     // console.log(bodyContent);

      if (bodyContent && bodyContent != '') {
        //console.log(bodyContent);
        const parsedObject = JSON.parse(bodyContent);
        const jsonContent = JSON.stringify(parsedObject);
        payload.connectionInfo.configuration.body = jsonContent
      }
    } else if (connectorType == "JDBC" || connectorType == "ORIENT") {
      if (data.dataStoreType == "table") {
        if (data.fetchType == "range") {
          if (data.fetchOffsetType == "customDate") {
            //console.log("STARTDATE:----", data);
            payload = {
              connectorName: data.connectorName,
              companyId: "0",
              connectionInfo: {
                Type: data.connectorType,
                dataflow:data.dataflow,
                configuration: {
                  password: data.password,
                  userName: data.userName,
                  port: data.port,
                  host: data.host,
                  history: false,
                  maxmiumFetchOffset: data.maxmiumFetchOffset,
                  maxmiumUnitType: data.maxmiumUnitType,
                  databaseName: data.databaseName,
                  dataStoreType: data.dataStoreType,
                  dataStoreName: data.dataStoreName,
                  databaseType:data.databaseType,
                  sslEnabled: data.sslEnabled=='enabled'?true:false,
                  fetchType: data.fetchType,
                  timeZone: data.timeZone,
                  fetchOffsetType:
                    data.fetchOffsetType === ""
                      ? "lastOneDay"
                      : data.fetchOffsetType,
                  fetchStartDateString: startDateGmt,
                  fetchEndDateString: endDateGmt,
                  createdDateColumn: data.createdDateColumn,
                  modifiedDateColumn: data.modifiedDateColumn,
                },
              },
            };
          } else if (data.fetchOffsetType == "history") {
            payload = {
              connectorName: data.connectorName,
              companyId: "0",
              connectionInfo: {
                Type: data.connectorType,
                dataflow:data.dataflow,
                configuration: {
                  password: data.password,
                  userName: data.userName,
                  port: data.port,
                  host: data.host,
                  history: true,
                  maxmiumFetchOffset: data.maxmiumFetchOffset,
                  maxmiumUnitType: data.maxmiumUnitType,
                  databaseName: data.databaseName,
                  dataStoreType: data.dataStoreType,
                  dataStoreName: data.dataStoreName,
                  databaseType:data.databaseType,
                  sslEnabled: data.sslEnabled=='enabled'?true:false,
                  fetchType: data.fetchType,
                  dateFormat: data.dateFormat,
                  timeZone: data.timeZone,
                  fetchOffsetType:
                    data.fetchOffsetType === ""
                      ? "lastOneDay"
                      : data.fetchOffsetType,
                  frequency: data.frequency,
                  historyDurationType: data.historyDurationType,
                  offsetDuration: data.offsetDuration,
                  offsetDurationUnitType: data.offsetDurationUnitType,
                  createdDateColumn: data.createdDateColumn,
                  modifiedDateColumn: data.modifiedDateColumn,
                },
              },
            };
          } else {
            payload = {
              connectorName: data.connectorName,
              companyId: "0",
              ConnectionInfo: {
                Type: data.connectorType,
                dataflow:data.dataflow,
                Configuration: {
                  password: data.password,
                  userName: data.userName,
                  port: data.port,
                  host: data.host,
                  history: false,
                  maxmiumFetchOffset: data.maxmiumFetchOffset,
                  maxmiumUnitType: data.maxmiumUnitType,
                  databaseName: data.databaseName,
                  dataStoreType: data.dataStoreType,
                  dataStoreName: data.dataStoreName,
                  databaseType:data.databaseType,
                  sslEnabled: data.sslEnabled=='enabled'?true:false,
                  fetchType: data.fetchType,
                  startParamter: data.startParamter,
                  endParamter: data.endParamter,
                  dateFormat: data.dateFormat,
                  timeZone: data.timeZone,
                  fetchOffsetType:
                    data.fetchOffsetType === ""
                      ? "lastOneDay"
                      : data.fetchOffsetType,
                  dataStoreType: data.dataStoreType,
                  createdDateColumn: data.createdDateColumn,
                  modifiedDateColumn: data.modifiedDateColumn,
                },
              },
            };
          }
        } // fetchType == complete then execute this case
        else {
          payload = {
            connectorName: data.connectorName,
            companyId: "0",
            ConnectionInfo: {
              Type: data.connectorType,
              dataflow:data.dataflow,
              Configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                databaseName: data.databaseName,
                dataStoreType: data.dataStoreType,
                dataStoreName: data.dataStoreName,
                databaseType:data.databaseType,
                sslEnabled: data.sslEnabled=='enabled'?true:false,
                fetchType: data.fetchType,
                dataStoreType: data.dataStoreType,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
              },
            },
          };
        }
      } else {
        // dataStoreType query
        if (data.fetchOffsetType == "customDate") {
          payload = {
            connectionInfo: {
              configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                databaseName: data.databaseName,
                dataStoreType: data.dataStoreType,
                dataStoreName: data.dataStoreName,
                databaseType:data.databaseType,
                sslEnabled: data.sslEnabled=='enabled'?true:false,
                query: data.query,
                fetchType: data.fetchType,
                startParamter: data.startParamter,
                endParamter: data.endParamter,
                dateFormat: data.dateFormat,
                maxmiumFetchOffset: data.maxmiumFetchOffset,
                maxmiumUnitType: data.maxmiumUnitType,
                history: false,
                timeZone: data.timeZone,
                fetchOffsetType:
                  data.fetchOffsetType === ""
                    ? "lastOneDay"
                    : data.fetchOffsetType,
                fetchStartDateString: startDateGmt,
                fetchEndDateString: endDateGmt,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
              },
              type: data.connectorType,
              dataflow:data.dataflow,
            },
            connectorName: data.connectorName,
            companyId: "0",
          };
        } else if (data.fetchOffsetType == "history") {
          //console.log("dataStoreName_____&&&", data.dataStoreName);
          //console.log("dataStoreType&&&******---", data.dataStoreType);
          payload = {
            connectorName: data.connectorName,
            companyId: "0",
            connectionInfo: {
              Type: data.connectorType,
              dataflow:data.dataflow,
              configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                maxmiumFetchOffset: data.maxmiumFetchOffset,
                maxmiumUnitType: data.maxmiumUnitType,
                databaseName: data.databaseName,
                dataStoreType: data.dataStoreType,
                dataStoreName: data.dataStoreName,
                databaseType:data.databaseType,
                sslEnabled: data.sslEnabled=='enabled'?true:false,
                query: data.query,
                fetchType: data.fetchType,
                startParamter: data.startParamter,
                endParamter: data.endParamter,
                dateFormat: data.dateFormat,
                timeZone: data.timeZone,
                history: true,
                fetchOffsetType:
                  data.fetchOffsetType === ""
                    ? "lastOneDay"
                    : data.fetchOffsetType,
                frequency: data.frequency,
                historyDurationType: data.historyDurationType,
                offsetDuration: data.offsetDuration,
                offsetDurationUnitType: data.offsetDurationUnitType,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
              },
            },
          };
        } else {
          /// insert record when record offset type is lastOneDay
         // console.log("inside the jdbc else",data)
          payload = {
            connectionInfo: {
              configuration: {
                password: data.password,
                userName: data.userName,
                port: data.port,
                host: data.host,
                maxmiumFetchOffset: data.maxmiumFetchOffset,
                maxmiumUnitType: data.maxmiumUnitType,
                history: false,
                databaseName: data.databaseName,
                fetchType: data.fetchType,
                fetchOffsetType:
                  data.fetchOffsetType === ""
                    ? "lastOneDay"
                    : data.fetchOffsetType,
                startParamter: data.startParamter,
                endParamter: data.endParamter,
                dateFormat: data.dateFormat,
                timeZone: data.timeZone,
                dataStoreType: data.dataStoreType,
                query: data.query,
                createdDateColumn: data.createdDateColumn,
                modifiedDateColumn: data.modifiedDateColumn,
                databaseName: data.databaseName,
                dataStoreType: data.dataStoreType,
                dataStoreName: data.dataStoreName,
                databaseType:data.databaseType,
                sslEnabled: data.sslEnabled=='enabled'?true:false,
              },
              dataflow:connectorFlowType,
              type: data.connectorType,
            },
            connectorName: data.connectorName,
            companyId: "0",
          };
          //console.log("payload 1")
          //console.log(payload)
        }
      }

      //console.log("Payload Data for the JDBC", JSON.stringify(payload));
    } else {
      payload = {
        connectionInfo: {
          configuration: {
            filePath: data.filePath,
            host: data.host,
            passPhrase: data.passPhrase,
            userName: data.userName,
            password: data.password,
            port: data.port,
            privateKeyFileLocation: data.privateKeyFileLocation,
            publicKeyAuthenticaiton: data.publicKeyAuthenticaiton,
          },
          dataflow: data.dataflow,
          type: data.connectorType,
        },
        userName: data.userName,
        connectorName: data.connectorName,
        dataClassificationID: data.dataClassificationID,
      };
    }
    payload["id"] = defaultValues.id
    //console.log("payload 2", payload);
    //console.log("methodParametersContent");
    //console.log(methodParametersContent);
    console.log(JSON.stringify(payload))
    ConnectorService
      .updateConnector(payload)
      .then((res) => {
        //console.log(res)
        if (res.status === 200)
          alert('updated successfully');
        history.push("/topics/connector/detail/" + payload.id)

      })
      .catch((error) => {
        alert("Connector error")
        console.error('getConnector =>', error)
      })
  }

  //console.log("connectorType:" + connectorType);

  let configurationTypeValue = null;
  let submitDetails = null;
  let credentialInfoUserNameKey = '';
  let credentialInfoPasswordKey = '';
  let credentialInfoUserNameValue = '';
  let credentialInfoPasswordValue = '';

  if (defaultValues.connectorLoginCredential && defaultValues.connectorLoginCredential.credentialInfoMap) {
    const credentialInfo = defaultValues.connectorLoginCredential.credentialInfoMap;
    const keys = Object.keys(credentialInfo);
    if (keys && keys.length > 0) {
      credentialInfoUserNameKey = keys[0];
      credentialInfoPasswordKey = keys[1];
      credentialInfoUserNameValue = credentialInfo[credentialInfoUserNameKey];
      credentialInfoPasswordValue = credentialInfo[credentialInfoPasswordKey];
    }
  }

  let connectorLoginCredentialDefaultValues = {
    url:  '',
    tokenAttribute: '',
    usernameKey: '',
    passwordKey: '',
    username: '',
    password: '',
    tokenType: '',
    methodType: '',
    keyName: '',
    responseChannel: ''
  }

if(defaultValues.connectorLoginCredential){
   connectorLoginCredentialDefaultValues = {
    url: defaultValues.connectorLoginCredential.url ? defaultValues.connectorLoginCredential.url : '',
    tokenAttribute: defaultValues.connectorLoginCredential.extractTokenAttributeKey ? defaultValues.connectorLoginCredential.extractTokenAttributeKey : '',
    usernameKey: credentialInfoUserNameKey,
    passwordKey: credentialInfoPasswordKey,
    username: credentialInfoUserNameValue,
    password: credentialInfoPasswordValue,
    tokenType: defaultValues.connectorLoginCredential.tokenType ? defaultValues.connectorLoginCredential.tokenType : '',
    methodType: defaultValues.connectorLoginCredential.methodType ? defaultValues.connectorLoginCredential.methodType : '',
    keyName: defaultValues.connectorLoginCredential.keyName ? defaultValues.connectorLoginCredential.keyName : '',
    responseChannel: defaultValues.connectorLoginCredential.responseChannel ? defaultValues.connectorLoginCredential.responseChannel : ''
  }
}

  //console.log('connectorLoginCredentialDefaultValues+', connectorLoginCredentialDefaultValues);
  const [connectorLoginCredential, setConnectorLoginCredential] = useState(connectorLoginCredentialDefaultValues);
  

  if (connectorType !== undefined &&
    (connectorType === 'sftp' || connectorType === 'sftpsrecursive')) {
    configurationTypeValue = <ConnectorEditSFTPConfiguration register={register} connectorType={connectorType} errors={errors} control={control} />
  } else if (connectorType !== undefined && (connectorType === 'jdbc' || connectorType === 'JDBC' || connectorType === 'ORIENT' || connectorType === 'ORIENT')) {
    configurationTypeValue = <ConnectorEditJDBCConfiguration 
    defaultValues={props.defaultValues} 
    changeStartDate={setOnChangeStartDate} 
    changeEndDate={setOnChangeEndDate} 
    directionValue={directionValue}
      
      register={register} 
      connectorType={connectorType} 
      errors={errors} 
      control={control} 
      setMetadatapropertiesContent={setmetadatapropertiesContent} 
      setMethodParametersContent={setMethodParametersContent}
      setHistoryValue={setHistoryValue}
      methodParameterValue={methodParameterValue}
      metadatapropertiesContentValue={metadatapropertiesContentValue}
      setHeaderContent={setHeaderContent}
      bodyContent={bodyContent}
      setBodyContent={setBodyContent}
      dateStartValue={setStartDate}
      dateEndValue={setEndDate}
      headerContent={headerContent}
      selectedDateFormat={setDateFormat}
      slectedTimeZone={setDateTimeZone}
      connectorFlowType={connectorFlowType}
      requestMethod={defaultValues.requestMethod}
      dateFormat={dateFormat}
      dateTimeZone={dateTimeZone}
      fetchType={defaultValues.fetchType}
      historyDurationType={defaultValues.historyDurationType}
      frequency={defaultValues.frequency}
      offsetDuration={defaultValues.offsetDuration}
      offsetDurationUnitType={defaultValues.offsetDurationUnitType}
      fetchOffsetType={defaultValues.fetchOffsetType}
      maxmiumFetchOffset={defaultValues.maxmiumFetchOffset}
      maxmiumUnitType={defaultValues.maxmiumUnitType}
      fetchStartDateString={defaultValues.fetchStartDateString}
      fetchEndDateString={defaultValues.fetchEndDateString}
      setIncludePathIdFlagValue={setIncludePathIdFlagValue}
      setConnectorLoginCredential={setConnectorLoginCredential}
      connectorLoginCredential={connectorLoginCredential}
      startParamter={defaultValues.startParamter}
      endParamter={defaultValues.endParamter}
      />
  } else if (connectorType !== undefined && (connectorType === 'api' || connectorType === 'API')) {
    configurationTypeValue = <ConnectorEditAPIConfiguration register={register} connectorType={connectorType} errors={errors} control={control} setMetadatapropertiesContent={setmetadatapropertiesContent} setMethodParametersContent={setMethodParametersContent}
      setHistoryValue={setHistoryValue}
      methodParameterValue={methodParameterValue}
      metadatapropertiesContentValue={metadatapropertiesContentValue}
      setHeaderContent={setHeaderContent}
      bodyContent={bodyContent}
      setBodyContent={setBodyContent}
      dateStartValue={setStartDate}
      dateEndValue={setEndDate}
      headerContent={headerContent}
      selectedDateFormat={setDateFormat}
      slectedTimeZone={setDateTimeZone}
      connectorFlowType={connectorFlowType}
      requestMethod={defaultValues.requestMethod}
      dateFormat={dateFormat}
      dateTimeZone={dateTimeZone}
      fetchType={defaultValues.fetchType}
      historyDurationType={defaultValues.historyDurationType}
      frequency={defaultValues.frequency}
      offsetDuration={defaultValues.offsetDuration}
      offsetDurationUnitType={defaultValues.offsetDurationUnitType}
      fetchOffsetType={defaultValues.fetchOffsetType}
      maxmiumFetchOffset={defaultValues.maxmiumFetchOffset}
      maxmiumUnitType={defaultValues.maxmiumUnitType}
      fetchStartDateString={defaultValues.fetchStartDateString}
      fetchEndDateString={defaultValues.fetchEndDateString}
      setIncludePathIdFlagValue={setIncludePathIdFlagValue}
      setIncludePathId={setIncludePathId}
      includePathId={includePathId}
      setConnectorLoginCredential={setConnectorLoginCredential}
      connectorLoginCredential={connectorLoginCredential}
      startParamter={defaultValues.startParamter}
      endParamter={defaultValues.endParamter}

    />
  }


  if (configurationTypeValue != null)




    submitDetails = <div className={classes.rycomButtonGroup}>
      <Button type="reset" size="large"
        onClick={() => reset({
          "type": "",
          "companyID": "",
          "dataTypeMapperID": ""
        })}>Clear</Button>
      <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Update</Button>
    </div>;

  if (connectorType != null) {
    if (connectorType !== undefined &&   (connectorType === "API" || connectorType === "api" || connectorType === "JDBC" || connectorType === "jdbc" || connectorType === "ORIENT" || connectorType === "orient" ) ) {
      submitDetails = (
        <div>

          {jsonResponsePayload ? <span>
            <br></br>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1-header"
              >
                <Typography>Extracted Input Payload</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <pre>{JSON.stringify(jsonResponsePayload, null, 2)}</pre>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel></span> : ""}


          <br></br>

          <div className={classes.rycomButtonGroup}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              value="validate"
              onClick={handleSubmit(validate)}
            >
              validate
            </Button>
            <Button
              type="reset"
              variant=""
              size="large"
              onClick={() =>
                reset({
                  type: "",
                  companyID: "",
                  dataTypeMapperID: "",
                })
              }
            >
              Clear
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              value="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>

          </div>
        </div>
      );
    } else {
      submitDetails = <div className={classes.rycomButtonGroup}>
        <Button type="reset" variant="" size="large"
          onClick={() => reset({
            "type": "",
            "companyID": "",
            "dataTypeMapperID": ""
          })}>Clear</Button>
        <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
      </div>;
    }
  }


  //console.log(connectorType);

  const suffix = 'recursive'
  const pathLabel = (connectorType !== undefined &&
    connectorType.substr(-suffix.length) === suffix) ? 'Absoloute Directory Path' : 'Absolute File Path';


  const getMenuItems = () => {
    return connectorTypes.map((connectorType1, index) => {
      return connectorType1 == connectorType ?
        <MenuItem key={++index} value={connectorType1} >{connectorType1}
        </MenuItem> :
        <MenuItem key={++index} value={connectorType1} >{connectorType1}
        </MenuItem>
    })
  }

  const getFlowItems = () => connectorFlowTypes.map((connectorflowType, index) => <MenuItem key={++index} value={connectorflowType} >{connectorflowType}
        </MenuItem>);


  const getDirection = () => {
    return connectorTypes.map((connectorType1, index) => {
      console.log(connectorType1 == connectorType)
      return connectorType1 == connectorType ?
        <MenuItem key={++index} value={connectorType1} >{connectorType1}
        </MenuItem> :
        <MenuItem key={++index} value={connectorType1} >{connectorType1}
        </MenuItem>
    })
  }


  return (
    <form className={classes.root} >
      <RycomPageHeader title="Update the Connector Details"
        links={[{ title: 'Back', url: '/topics/connector' }]}></RycomPageHeader>
      <RycomSectionHeader title="Connector Overview Details"></RycomSectionHeader>
      <Grid container spacing={12} style={{ padding: "4px,4px,4px,0" }}>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <RycomSelectField id="Connector Type"
            label="Connector Types"
            name="connectorType"
            list={connectorTypes}
            onChangeHandler={(event) => {
              setConnectorType(event[0].target.value)
              return event[0].target.value
            }}
            register={register}
            control={control}
            value={connectorType}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Connector Type is required'
              }
            }} >
            {getMenuItems()}
          </RycomSelectField>
        </Grid>
       
        <Grid item xs={12} sm={12} lg={4} xl={4}>
      <RycomSelectField
          id="dataflow"
          label="Connector Flow Types"
          name="dataflow"
          list={connectorFlowTypes}
          onChangeHandler={(event) => {
            setConnectorFlowType(event[0].target.value)
            return event[0].target.value;
          }}
          register={register}
          control={control}
          errors={errors}
          value={connectorFlowType}
          errorDescription={{
            required: {
              value: true,
              message: "Connector Flow Type is required",
            },
          }}
        >
         {getFlowItems()}
        </RycomSelectField>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <RycomTextField id="Connector Name"
            label="Connector Name"
            name="connectorName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Connector Name is required'
              }
            }} />
        </Grid>
      </Grid>

      {( (connectorType === 'api' || connectorType === 'API') || (connectorType == 'jdbc' || connectorType == 'JDBC') || (connectorType == 'orient' || connectorType == 'ORIENT') )? "" : <div>
        <RycomSectionHeader title="Server and Connection Information" />
        <RycomGrid>
          <RycomGridItem>
            <RycomTextField id="User Name"
              label="User Name"
              name="userName"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: 'User Name is required'
                }
              }} />
          </RycomGridItem>
          <RycomGridItem>
            <TextField
              id="outlined-multiline-flexible"
              label="Password*"
              variant="outlined"
              inputRef={register({
                required: {
                  value: true,
                  message: 'password is required'
                }
              })}
              name="password"
              type="password" />
            <span className={classes.invalidMessage}>
              {errors['password'] && <span>{errors['password'].message}</span>}
            </span>
          </RycomGridItem>
          <RycomGridItem>
            <RycomTextField id="Host"
              label="host"
              name="host"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: 'Host is required'
                }
              }} />
          </RycomGridItem>
        </RycomGrid>
        <RycomGrid>
          <RycomGridItem>
            <RycomTextField id="Port"
              label="Port"
              name="port"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: 'Port is required'
                }
              }} />
          </RycomGridItem>
          <RycomGridItem>
            <RycomTextField id={pathLabel}
              label={pathLabel}
              name="filePath"
              register={register}
              errors={errors}
              errorDescription={{
                required: {
                  value: true,
                  message: pathLabel + ' is required'
                }
              }} />
          </RycomGridItem>
        </RycomGrid></div>}
      {configurationTypeValue}
      {submitDetails}
    </form>
  );
}
