import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RycomInfoPair from "../../common/RycomInfoPair";
import Grid from "@material-ui/core/Grid";
import RycomSectionHeader from "../../common/RycomSectionHeader";
import RycomPageHeader from "../../common/RycomPageHeader";
import * as DataMapperService from "../../../services/DataMapperService";
import * as VenueService from '../../../services/VenueService';

import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    flexDirection: "column",
  },

  aocard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "color-white",
    border: "1px solid ui-border-color-base",
    borderRadius: "border-radius-base",
    marginBottom: "spacer",
    padding: 0,
  },

  title: {
    margin: 0,
    fontSize: "xx-large",
    display: "inline-block",
    paddingRight: "10px",
  },

  header: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    borderBottom: "5px solid $ui-border-color-base",
  },

  toolbar: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "row nowrap",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "flex-end",
  },

  buttonStyle: {
    paddingRight: "5px",
  },
  gridBox: {
    padding: "24px",
    border: "1px solid #ddd",
    boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    margin: "10px",
    width: "calc(100% - 20px)"
  }
}));

export default function DataMapperDetail() {
  let { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [keycloak] = useKeycloak();

  const [dataMapperValues, setDataMapperValues] = useState();
  const [venueName, setVenueName] = useState([]);

  console.log("dataMapperValues")
  console.log(dataMapperValues)

  useEffect(() => {
    DataMapperService.getDataMapperById(id)
      .then((res) => {
        if (res && res.data && res.data) {
          console.log(res.data);
          setDataMapperValues(res.data);
        }
      })
      .catch((error) => {
        console.error("getCompanyById =>", error);
      });
  }, []);

  useEffect(() => {
    if(dataMapperValues) {
      
        VenueService.getVenueByCompanyId(dataMapperValues.companyid)
        .then((res) => {
            if (res && res.data && res.data) {
                const venueData = res.data.filter((item) => item.venueid === dataMapperValues.venueid );
                setVenueName(venueData[0].venuename);
              } else {
              setVenueName("");
            }
        })
        .catch((error) => {
            console.error('getConnector =>', error)
        })
    }


  },[dataMapperValues]);
  

  const createDeleteButton = () => {
    const DeleteButton = (
      <Button
        className={classes.buttonStyle}
        color="secondary"
        variant="contained"
        size="medium"
        value="Delete DataMapper"
        onClick={(e) => {
          {
            if (window.confirm("Are you sure you wish to delete this item?")) {
              DataMapperService.deleteDatamapper(id)
                .then((res) => {
                  if (res && res.data && res.data) {
                    console.log(res.data);
                    alert(res.data);
                    history.push("/topics/datamapper");
                  }
                })
                .catch((error) => {
                  alert("some error in deleting the DataMapper");
                  console.error("delete DataMapper =>", error);
                });
            } else {
              console.log("Not Delted");
            }
          }
        }}
      >
        Delete
      </Button>
    );
    return DeleteButton;
  };

  let metadataValues = (
    <Grid item xs={12} sm={6} lg={6} xl={2} style={{ padding: 15 }}>
      <RycomInfoPair label="No Metadata value" value=""></RycomInfoPair>
    </Grid>
  );
  if (dataMapperValues && dataMapperValues.metadata) {
    metadataValues = dataMapperValues.metadata.map((metadata) => (
      <Grid container spacing={12} style={{ padding: 15 }}>
        <Grid item xs={12} sm={6} lg={6} xl={2}>
          <RycomInfoPair
            label="Metadata Key"
            value={metadata ? metadata.key : ""}
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Metadata Value"
            value={metadata ? metadata.value : ""}
          ></RycomInfoPair>
        </Grid>
      </Grid>
    ));
  }

  let mapperDetails = "";
  let innerJsonDetails = "";
  let innerCsvDetails ="";
  if (dataMapperValues && dataMapperValues.mapper) {
    const listvalues = dataMapperValues.mapper.sort((a, b) => {
      if (parseInt(a.postion) > parseInt(b.postion)) return 1;
      else if (parseInt(a.postion) < parseInt(b.postion)) return -1;
      else return 0;
    });

    console.log("***********", dataMapperValues);
    if (
      dataMapperValues.datainfo.filetype !== "JSON" ||
      dataMapperValues.datainfo.filetype !== "JSON-CSV DATA GRID"
    ) {
      if (dataMapperValues.datainfo.filetype == "CSV") {
        mapperDetails = listvalues.map((mapper) => {

          const InnerCsvContent = mapper.metadata?.map(
            (metadata) => (
              <Grid
                container
                spacing={12}
                style={{ padding: 10, marginLeft: 25 }}
              >
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="metadata Key"
                    value={metadata ? metadata.key : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="metadata value"
                    value={metadata ? metadata.value : ""}
                  ></RycomInfoPair>
                </Grid>
               
              </Grid>
            )
          );

          return (
            <>
              <Grid container spacing={12} className={classes.gridBox}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="Position"
                    value={mapper ? mapper.postion : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="CSV Column Header"
                    // label="mappedCsvKey"
                    value={mapper ? mapper.key : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="TARGET COLUMN NAME"
                    value={mapper ? mapper.mappedJsonKey : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="TRANSFORMATION TYPE"
                    value={mapper ? mapper.transformationType : ""}
                  ></RycomInfoPair>
                </Grid>
                {InnerCsvContent}
              </Grid>
            </>
          ) 
        });

        // inner csv details diplayed from here
        innerCsvDetails = listvalues.map((mapper) => {
          let content = "";
          const InnerCsvContent = mapper.metadata?.map(
            (metadata) => (
              <Grid
                container
                spacing={12}
                style={{ padding: 10, marginLeft: 25 }}
              >
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="metadata Key"
                    value={metadata ? metadata.key : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="metadata value"
                    value={metadata ? metadata.value : ""}
                  ></RycomInfoPair>
                </Grid>
               
              </Grid>
            )
          );
          content = <div>{InnerCsvContent}</div>;
          return content;
        });
        // inner csv details end here
      } else {
        mapperDetails = listvalues.map((mapper) => {
          // If child element has then it will show
          const InnerJsonContent = mapper.nestedJsonTypeConfig?.map(
            (nestermapper) => (
              // <div>
                <Grid
                  container
                  spacing={12}
                  style={{ padding: 10, marginLeft: 25 }}
                >
                  <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair
                      label="Key"
                      value={nestermapper ? nestermapper.key : ""}
                    ></RycomInfoPair>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2} xl={2}>
                    <RycomInfoPair
                      label="mappedJsonKey"
                      value={nestermapper ? nestermapper.mappedJsonKey : ""}
                    ></RycomInfoPair>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2} xl={2}>
                    <RycomInfoPair
                      label="TransFormationType"
                      value={nestermapper ? nestermapper.transformationType : ""}
                    ></RycomInfoPair>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2} xl={2}>
                    <RycomInfoPair
                      label="Marked As PrimaryKey"
                      value={nestermapper.markedAsPrimaryKey==true?'TRUE':"FALSE"}
                    ></RycomInfoPair>
                  </Grid> 
                </Grid>
              // </div>
            )
          );

          return (
            <>
              <Grid container spacing={12} className={classes.gridBox}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="Key"
                    value={mapper ? mapper.mappedJsonKey : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="mappedJsonKey"
                    value={mapper ? mapper.key : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="TransFormationType"
                    value={mapper ? mapper.transformationType : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Marked As Primary Key"
                    value={mapper.markedAsPrimaryKey==true?'TRUE':"FALSE"}
                  ></RycomInfoPair>
                </Grid>
                 {InnerJsonContent}
              </Grid>
            </>
          )
        });
      }
      if (dataMapperValues.datainfo.filetype == "JSON" || dataMapperValues.datainfo.filetype == "JSON-CSV DATA GRID") {
        // inner json details diplayed from here
        innerJsonDetails = listvalues.map((mapper) => {
          let content = "";
          const InnerJsonContent = mapper.nestedJsonTypeConfig?.map(
            (nestermapper) => (
              <Grid
                container
                spacing={12}
                style={{ padding: 10, marginLeft: 25 }}
              >
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <RycomInfoPair
                    label="Key"
                    value={nestermapper ? nestermapper.key : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="mappedJsonKey"
                    value={nestermapper ? nestermapper.mappedJsonKey : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="TransFormationType"
                    value={nestermapper ? nestermapper.transformationType : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Marked As PrimaryKey"
                    value={nestermapper.markedAsPrimaryKey==true?'TRUE':"FALSE"}
                  ></RycomInfoPair>
                </Grid> 
              </Grid>
            )
          );
          content = <div>{InnerJsonContent}</div>;
          return content;
        });
        // inner json details end here
      }
    } else {
      mapperDetails = listvalues.map((mapper) => {
        let content = "";
        if (!mapper.flattening) {
          content = (
            <Grid container spacing={12} style={{ padding: 10 }}>
              <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair
                  label="Key"
                  value={mapper ? mapper.key : ""}
                ></RycomInfoPair>
              </Grid>
              <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair
                  label="mappedJsonKey"
                  value={mapper ? mapper.mappedJsonKey : ""}
                ></RycomInfoPair>
              </Grid>
              <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair
                  label="Data Type"
                  value={mapper ? mapper.valuetype : ""}
                ></RycomInfoPair>
              </Grid>
              <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair
                  label="Marked As PrimaryKey"
                  value={
                    mapper ? (mapper.markedAsPrimaryKey ? "TRUE" : "FALSE") : ""
                  }
                ></RycomInfoPair>
              </Grid>
              <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair
                  label="Maintain Historical Record"
                  value={
                    mapper
                      ? mapper.maintainHistoricalRecord
                        ? "TRUE"
                        : "FALSE"
                      : ""
                  }
                ></RycomInfoPair>
              </Grid>
            </Grid>
          );
        } else {
          // console.log("mapper.nestedJsonTypeConfig")
          //console.log(mapper.nestedJsonTypeConfig)
          const InnerContent = mapper.nestedJsonTypeConfig.map(
            (nestermapper) => (
              <Grid container spacing={12} style={{ padding: 10 }}>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Key"
                    value={nestermapper ? nestermapper.key : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="mappedJsonKey"
                    value={nestermapper ? nestermapper.mappedJsonKey : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Data Type"
                    value={nestermapper ? nestermapper.valuetype : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Marked As PrimaryKey"
                    value={nestermapper ? nestermapper.valuetype : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Maintain Historical Record"
                    value={nestermapper ? nestermapper.valuetype : ""}
                  ></RycomInfoPair>
                </Grid>
              </Grid>
            )
          );

          content = (
            <span>
              <Grid container spacing={12} style={{ padding: 10 }}>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Key"
                    value={mapper ? mapper.key : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="mappedJsonKey"
                    value={mapper ? mapper.mappedJsonKey : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Data Type"
                    value={mapper ? mapper.valuetype : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Marked As PrimaryKey"
                    value={mapper ? mapper.valuetype : ""}
                  ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                  <RycomInfoPair
                    label="Maintain Historical Record"
                    value={mapper ? mapper.valuetype : ""}
                  ></RycomInfoPair>
                </Grid>
              </Grid>
              {InnerContent}
            </span>
          );
        }
        // console.log(content)
        return content;
      });
    }
  }

  const buttonLinks = (
    <span>
      <Link className={classes.buttonStyle} to="/topics/datamapper">
        <Button color="default" variant="contained" size="medium">
          Back
        </Button>
      </Link>
      {keycloak.hasRealmRole("update:graph") ? (
        <Link
          className={classes.buttonStyle}
          to={"/topics/datamapper/edit/" + id}
        >
          <Button color="primary" variant="contained" size="medium">
            Edit
          </Button>
        </Link>
      ) : (
        ""
      )}

      {keycloak.hasRealmRole("delete:graph") ? (
        <span className={classes.buttonStyle} to="/topics/datamapper">
          {createDeleteButton()}
        </span>
      ) : (
        ""
      )}
      <Link className={classes.buttonStyle} to="/topics">
        <Button color="primary" variant="contained" size="medium">
          Home
        </Button>
      </Link>
    </span>
  );

  return (
    <div>
      <Card className={classes.aocard}>
        <div className={classes.header}>
          <span className={classes.title}>
            {dataMapperValues
              ? `Data Mapper Details: ${dataMapperValues.datamappername}`
              : "Data Mapper Details"}{" "}
          </span>
          <span className={classes.toolbar}>{buttonLinks}</span>
        </div>
      </Card>
      {/* <RycomPageHeader title={dataMapperValues ? `Data Mapper Details: ${dataMapperValues.datamappername}` : 'Task Details'} links={[{ title: 'Back', url: '/topics/datamapper' }]}></RycomPageHeader> */}
      <RycomSectionHeader title="Data Mapper "></RycomSectionHeader>
      <Grid container spacing={12} style={{ padding: 15 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="DataMapper"
            value={dataMapperValues ? dataMapperValues.datamappername : ""}
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="DataMapper Description"
            value={
              dataMapperValues ? dataMapperValues.datamapperdescription : ""
            }
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Parent Organisation"
            value={dataMapperValues ? dataMapperValues.parentcompanyname : ""}
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label=" Organisation"
            value={dataMapperValues ? dataMapperValues.companyName : ""}
          ></RycomInfoPair>
        </Grid>

        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Venue Name"
            value={venueName}
          ></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
          <RycomInfoPair
            label="Device Name"
            value={dataMapperValues ? dataMapperValues.deviceName : ""}
          ></RycomInfoPair>
        </Grid>
      </Grid>
      <RycomSectionHeader title="Metadata "></RycomSectionHeader>
      {metadataValues}
      <RycomSectionHeader title="Mapper Details "></RycomSectionHeader>
      {mapperDetails}
      {/* {innerJsonDetails} */}
      {/* {innerCsvDetails} */}
    </div>
  );
}
