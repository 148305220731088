import React from 'react';
import RycomSectionHeader from '../common/RycomSectionHeader'
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import RycomTextField from '../common/RycomTextField'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  invalidMessage: {
      display: 'inline-block',
      fontSize: '12px',
      color: 'red',
      paddingLeft: '12px',
    }
  
}));

export default function ConnectorFTPConfiguration(props) {

  const classes = useStyles();

  const { connectorType, register, errors } = props
  const suffix = 'recrusive'
  const pathLabel = (connectorType !== undefined &&
    connectorType.substr(-suffix.length) === suffix) ? 'Absolute Directory Path' : 'Absolute File Path';
  return (
    <div>
      <RycomSectionHeader title="Server and Connection Information"></RycomSectionHeader>
      <RycomGrid>
        <RycomGridItem>
        <RycomTextField id="User Name"
            label="User Name"
            name="userName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'User Name is required'
              }
            }} />
        </RycomGridItem>
        <RycomGridItem>

        <TextField
                id="outlined-multiline-flexible"
                label="Password*"
                variant="outlined"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'password is required'
                  }})}
                name="password"
                type="password"/>
               <span className={classes.invalidMessage}>
                {errors['password'] && <span>{errors['password'].message}</span>}
            </span>
         </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id="Host"
            label="host"
            name="host"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Host is required'
              }
            }} />
        </RycomGridItem>
      </RycomGrid>
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id="Port"
            label="Port"
            name="port"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Port is required'
              }
            }} />

        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id={pathLabel}
            label={pathLabel}
            name="filePath"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: pathLabel + ' is required'
              }
            }} />
        </RycomGridItem>
      </RycomGrid>
    </div>
  );
}
