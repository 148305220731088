import React from 'react'
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader';

export default function ConnectorSFTPDetail({connectorInfo}) {
    return (
        <div>
            <RycomSectionHeader title="Connector Device & Server Details"></RycomSectionHeader>
            <Grid container spacing={12} style={{ padding: 24 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Host" value={connectorInfo ? connectorInfo.connectionInfo.configuration.host : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Port" value={connectorInfo ? connectorInfo.connectionInfo.configuration.port : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="File Path" value={connectorInfo ? connectorInfo.connectionInfo.configuration.filePath : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="User Name" value={connectorInfo ? connectorInfo.connectionInfo.configuration.userName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Password" value={connectorInfo ? connectorInfo.connectionInfo.configuration.password : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Public Key Authentication" value={(connectorInfo && connectorInfo.connectionInfo.configuration.publicKeyAuthenticaiton)
                         ? 'TRUE' : 'FALSE'}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="PassPhrase" value={connectorInfo ? connectorInfo.connectionInfo.configuration.passPhrase : ''}></RycomInfoPair>
                </Grid>
            </Grid>
        </div>);
 }
