import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomSelectField from '../common/RycomSelectField'
import RycomTextField from '../common/RycomTextField'
import {  useState } from 'react'
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import Grid from '@material-ui/core/Grid';
import RycomSubmitModel from '../common/RycomSubmitModel'

import * as OrganisationService from '../../services/OrganisationService';
import * as VenueService from '../../services/VenueService';
import * as DeviceService from '../../services/DeviceService';
import * as RelationService from '../../services/RelationService';
import * as ConnectorService from '../../services/ConnectorService';
import * as DataMapperService from '../../services/DataMapperService';
import * as SchedulerService from '../../services/SchedulerService';
import * as TaskService from '../../services/TaskService';

import * as DocumentRelationService from '../../services/DocumentRelationService';
import * as DocumentClassService from '../../services/DocumentClassService';


import { useEffect } from 'react'
import environment from '../../environment'

const connectorTypes = [
    "sftp",
    "sftprecursive",
    "ftp",
    "ftprecrusive",
    "ftps",
    "ftpsrecrusive",
    "API",
    "JDBC",
    "ORIENT"
];

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },

  rycomButtonGroup: {
    paddingLeft: '1100px'

  }
}));

export default function TaskEditTemplateForm(props) {
    const history = useHistory();
    const defaultValues = {
        ...props.defaultValues,
        documentVertexType:props.defaultValues.vertexTypeId
    }
        const classes = useStyles();
        const [companyId, setCompanyId] = useState(undefined);
        const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
        const [companyList, setCompanyList] = useState([]);
        const [venueList, setVenueList] = useState([]);
        const [deviceList, setDeviceList] = useState([]);
        const [schedulerList, setSchedulerList] = useState([]);
        const [connectorList, setConnectorList] = useState([]);
        const [connectorFilteredInboundList, setConnectorFilteredInboundList] = useState([]);
        const [connectorInboundType, setConnectorInboundType] = useState("");
        const [connectorFilteredOutboundList, setConnectorFilteredOutboundList] = useState([]);
        const [dataMapperList, setDataMapperList] = useState([]);
        const [relationList, setRelationList] = useState([]);
        const userInfoJson=localStorage.getItem("userInfo"); 
        const userInfo=JSON.parse(userInfoJson);
        const parentcompanyid=userInfo['parentcompanyid'];
        const companyid=userInfo['companyid'];
        const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;
        const [taskType, setTaskType] = useState(defaultValues.taskType);
        const [documentRelationList, setDocumentRelationList] = useState([]);
        const [documentClassList, setDocumentClassList] = useState([]);
        const [taskStorageType, setTaskStorageType] = useState(defaultValues.taskStorageType);	
        console.log("connectorInboundType")
        console.log(connectorInboundType)

        useEffect(() => {//
            if(companyList.length==0){
            //OrganisationService.getCompanyByParentId(parentcompanyid)
            OrganisationService.getCompanyParentById(companyid)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setCompanyList(res.data);
                    }
                })
                .catch((error) => {
                    alert(error)
                    console.error('getConnector =>', error)
                })
            }
            getVenueList(defaultValues.companyid)
            //getDatamapperList(parentcompanyid);
            // getRelationList(parentcompanyid);
            getConnectorList(parentcompanyid);
            getSchedulerList(parentcompanyid)
            getDocumentRelationList(parentcompanyid);
            //getDocumentClassList(parentcompanyid);
            
            getDatamapperList(paramID);
            getRelationList(paramID);
            // getConnectorList(paramID);
            // getSchedulerList(paramID)
            // getDocumentRelationList(paramID);
            getDocumentClassList(paramID);
        }, []);
    
        const getVenueList = (companyid) => {
            VenueService.getVenueByCompanyId(companyid)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setVenueList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        };
    
    
        const getDeviceList = (event) => {
            const venueId=event.target.value;
            DeviceService.getDeviceByVenueId(venueId)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setDeviceList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
                return event.target.value
        };

        const getRelationList = (companyId) => {
            //RelationService.getAllRelation(parentcompanyid)
            RelationService.getAllRelation(paramID)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setRelationList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        };

        // const getRelationList = (companyId) => {
        //     RelationService.getAllRelation(parentcompanyid)
        //         .then((res) => {
        //             if (res && res.data && res.data) {
        //                 setRelationList(res.data);
        //             }
        //         })
        //         .catch((error) => {
        //             console.error('getConnector =>', error)
        //         })
        // };

        const getConnectorList = () => {
            ConnectorService.getAllConnector()
                .then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data[0]);
                        setConnectorList(res.data[0].body);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        };

        const getDatamapperList = () => {
            //DataMapperService.getDataMapperByCompanyId(parentcompanyid,{})
            DataMapperService.getDataMapperByCompanyIdForTask(paramID)
                .then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res);
                        setDataMapperList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        };

        const getSchedulerList = () => {
            SchedulerService.getAllScheduler()
                .then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res);
                        setSchedulerList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        };
 

        const getDocumentRelationList = (companyId) => {
            DocumentRelationService.getAllDocumentRelation(companyId)
                .then((res) => {
                    if (res && res.data && res.data) {
                        console.log("Relation documwnt")
                        console.log(res.data)
                        setDocumentRelationList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        };



        const getDocumentClassList = (companyId) => {
            DocumentClassService.getAllVertex(companyId)
                .then((res) => {
                    if (res && res.data && res.data) {
                        console.log("Class documwnt")
                        console.log(res.data)
                        setDocumentClassList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        };

    
       // Filter the inbound connectors using connector types
        const filterInboundConnectors = (connectorType) => {

            // Inbound connector list
            const connectorInboundList=connectorList.filter(connector=>{
                console.log("inside the connectorInboundList "+connector.connectionInfo.dataflow+" : "+(connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound'));
                return (connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound') && connector.connectionInfo.type.toLowerCase() == connectorType.toLowerCase();
            });

            setConnectorFilteredInboundList(connectorInboundList);

        }

         // Filter the outbout connectors using connector types
        const filterOutboundConnectors = (connectorType) => {

            // outbound connector list
            const connectorOutboundList=connectorList.filter(connector=>{
                console.log("inside the connectorOutboundList "+connector.connectionInfo.dataflow+" : "+(connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound'));
                return (connector.connectionInfo.dataflow!=null && connector.connectionInfo.dataflow=='outbound') && connector.connectionInfo.type.toLowerCase() == connectorType.toLowerCase();
            });

            setConnectorFilteredOutboundList(connectorOutboundList);

        }

       
    
    
        const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);
        const venueMenuList = venueList.map((venue, index) => <MenuItem key={++index} value={venue.venueid}> {venue.venuename}</MenuItem>);
        const deviceMenuList = deviceList.map((device, index) => <MenuItem key={++index} value={device.deviceid}> {device.devicename}</MenuItem>);
        const relationMenuList = relationList.map((relation, index) => <MenuItem key={++index} value={relation.relationshipid}> {relation.rname}</MenuItem>);
        const dataMapperMenuList = dataMapperList.map((dataMapper, index) => <MenuItem key={++index} value={dataMapper.datamapperid}> {dataMapper.datamappername}</MenuItem>);
        const connectorMenuList = connectorList.map((connector, index) => <MenuItem key={++index} value={connector.id}> {connector.connectorName}</MenuItem>);
        const schedulerMenuList = schedulerList.map((scheduler, index) => <MenuItem key={++index} value={scheduler.id}> {scheduler.schedulerName}</MenuItem>);
        const documentRelationMenuList = documentRelationList.map((relation, index) => <MenuItem key={++index} value={relation.documentRelationId}> {relation.documentRelationName}</MenuItem>);
        const documentClassMenuList = documentClassList.map((relation, index) => <MenuItem key={++index} value={relation.classId}> {relation.className}</MenuItem>);
       
       

        useEffect(() => {
                    
            if(defaultValues.inboundConnectorType != null) {

                const connectorInboundLists = connectorList.filter(connectorData => {
            
                    return (connectorData.connectionInfo.dataflow==null || connectorData.connectionInfo.dataflow=='inbound') && connectorData.connectionInfo.type.toLowerCase() == defaultValues.inboundConnectorType.toLowerCase();
    
                });

                setConnectorFilteredInboundList(connectorInboundLists);
            }
            

            if(defaultValues.taskStorageType == "OutputConnector" && defaultValues.outboundConnectorType != null) {

                const connectorOutboundLists = connectorList.filter(connectorData => {
            
                    return (connectorData.connectionInfo.dataflow==null || connectorData.connectionInfo.dataflow=='outbound') && connectorData.connectionInfo.type.toLowerCase() == defaultValues.outboundConnectorType.toLowerCase();
                });
        
                setConnectorFilteredOutboundList(connectorOutboundLists);

            }

        },[connectorList]);
 
        const connectorInboundMenuList = connectorFilteredInboundList.map((connector, index) => <MenuItem key={++index} value={connector.id}> {connector.connectorName}</MenuItem>);
        const connectorOutboundMenuList = connectorFilteredOutboundList.map((connector, index) => <MenuItem key={++index} value={connector.id}> {connector.connectorName}</MenuItem>);
    

    const onSubmit = data => {
        console.log('------------', data);
        //data['parentcompanyid']=parentcompanyid;
        data['parentcompanyid']=paramID;
        data['taskid']=defaultValues.taskid;
        data['vertexTypeId'] = data.documentVertexType;
        TaskService.updateVertex(data).then((res) => {
            console.log(res)
            if(res.status === 200)
            alert('Task updated successfully');
            history.push("/topics/task/detail/"+defaultValues.taskid)
          })
        .catch((error) => {
          alert("Task error")                                      
           console.error('Task =>', error)
         }) 
    }
  

    return (
        <div>  
        <form className={classes.root}  >
            <RycomPageHeader title="Enter the Task Details"
                links={[{ title: 'Back', url: '/topics/task' },
                ]}></RycomPageHeader>

            <RycomSectionHeader title="Task Form"></RycomSectionHeader>
            <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="companyid"
                            label="companyid"
                            name="companyid"
                            onChangeHandler={(event) => {
                                getVenueList(event[0].target.value)
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Company is required'
                                }
                            }}
                        >
                            {companyMenuList}
                        </RycomSelectField> 
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="venueid"    
                            label="Venue"
                            name="venueid"
                            onChangeHandler={(event) => {
                                return getDeviceList(event[0])
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Venue is required'
                                }
                            }}
                        >
                            {venueMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>	
                        <RycomSelectField id="taskStorageType"	
                            label="Task Storage Type"	
                            name="taskStorageType"	
                            onChangeHandler={(event) => {	
                                setTaskStorageType(event[0].target.value);	
                                return event[0].target.value	
                            }}	
                            register={register}	
                            control={control}	
                            errors={errors}	
                            errorDescription={{	
                                required: {	
                                    value: true,	
                                    message: 'File Type is required'	
                                }	
                            }}	
                        >	
                            <MenuItem key="1" value="GraphConnector">Data Storage(Graph)</MenuItem>	
                            <MenuItem key="2" value="OutputConnector">Data Piping</MenuItem>	
                        </RycomSelectField>	
                    </RycomGridItem>
                </RycomGrid>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="taskname"
                            label="Task Name"
                            name="taskname"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Task Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="taskdescription"
                            label="Task Description"
                            name="taskdescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Task Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="taskType"
                            label="Data Type"
                            name="taskType"
                            onChangeHandler={(event) => {
                                setTaskType(event[0].target.value);
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            value={taskType}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'File Type is required'
                                }
                            }}
                        >
                            <MenuItem key="1" value="csv">CSV</MenuItem>
                            <MenuItem key="2" value="JSON">JSON </MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>
                </RycomGrid>
                <RycomSectionHeader title="Inbound Connector Configuration"></RycomSectionHeader>	
                <RycomGrid>	
                    <RycomGridItem>
                        <RycomSelectField
                            id="inboundConnectorType"
                            label="Connector Types"
                            name="inboundConnectorType"
                            list={connectorTypes}
                            onChangeHandler={(event) => {
                             filterInboundConnectors(event[0].target.value);   
                             // setConnectorType(event[0].target.value);
                             return event[0].target.value;
                            }}
                            register={register}
                            value={connectorInboundType}
                            control={control}
                            errors={errors}
                            errorDescription={{
                            required: {
                                value: true,
                                message: "Connector Type is required",
                            },
                            }}
                        >
                            {connectorTypes.map((connectorType1, index) => (
                            <MenuItem key={++index} value={connectorType1}>
                                {connectorType1}
                            </MenuItem>
                            ))}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>	
                        <RycomSelectField id="connectionid"	
                            label="Source - Input Bound Connector"	
                            name="connectionid"	
                            onChangeHandler={(event) => {	
                                return event[0].target.value;	
                            }}	
                            register={register}	
                            control={control}	
                            errors={errors}	
                            errorDescription={{	
                                required: {	
                                    value: true,	
                                    message: 'Connector is required'	
                                }	
                            }}	
                        >	
                            {connectorInboundMenuList}	
                        </RycomSelectField>	
                    </RycomGridItem>	
                    {/* {taskStorageType == 'OutputConnector' ? <RycomGridItem>	
                        <RycomSelectField id="outputConnectionId"	
                            label="Target - Output Bound Connector"	
                            name="outputConnectionId"	
                            onChangeHandler={(event) => {	
                                return event[0].target.value;	
                            }}	
                            register={register}	
                            control={control}	
                            errors={errors}	
                            errorDescription={{	
                                required: {	
                                    value: true,	
                                    message: 'Output Connector is required'	
                                }	
                            }}>	
                            {connectorOutboundMenuList}	
                        </RycomSelectField></RycomGridItem> : <span>{taskType !== 'JSON' ?	
                            (<RycomGridItem><RycomSelectField id="baserelationshipid"	
                                label="Base relation"	
                                name="baserelationshipid"	
                                onChangeHandler={(event) => {	
                                    return event[0].target.value;	
                                }}	
                                register={register}	
                                control={control}	
                                errors={errors}	
                                errorDescription={{	
                                    required: {	
                                        value: true,	
                                        message: 'Base Relation is required'	
                                    }	
                                }}	
                            >	
                                {relationMenuList}	
                            </RycomSelectField></RycomGridItem>) : <RycomGridItem><RycomSelectField id="pathClassType"	
                                label="Document Vertex Type"	
                                name="documentVertexType"	
                                onChangeHandler={(event) => {	
                                    return event[0].target.value	
                                }}	
                                register={register}	
                                control={control}	
                                errors={errors}	
                                errorDescription={{	
                                }} >	
                                {documentClassMenuList}	
                            </RycomSelectField></RycomGridItem>}</span>}	 */}
                </RycomGrid>	
                <RycomSectionHeader title="DataMapper - Schema and Scheduler Configuration"></RycomSectionHeader>	
                <RycomGrid>	
                    <RycomGridItem>	
                        <RycomSelectField id="datamapperid"	
                            label="DataMapper"	
                            name="datamapperid"	
                            onChangeHandler={(event) => {	
                                return event[0].target.value;	
                            }}	
                            register={register}	
                            control={control}	
                            errors={errors}	
                            errorDescription={{	
                                required: {	
                                    value: true,	
                                    message: 'DataMapper is required'	
                                }	
                            }}	
                        >	
                            {dataMapperMenuList}	
                        </RycomSelectField>	
                    </RycomGridItem>	
                    <RycomGridItem>	
                        <RycomSelectField id="schedulerid"	
                            label="Scheduler"	
                            name="schedulerid"	
                            onChangeHandler={(event) => {	
                                return event[0].target.value;	
                            }}	
                            register={register}	
                            control={control}	
                            errors={errors}	
                            errorDescription={{	
                                required: {	
                                    value: true,	
                                    message: 'Scheduler is required'	
                                }	
                            }}	
                        >	
                            {schedulerMenuList}	
                        </RycomSelectField>	
                    </RycomGridItem>	
                </RycomGrid>    
                {taskStorageType == 'OutputConnector' &&
                <>
                    <RycomSectionHeader title="Outbound Connector Configuration"></RycomSectionHeader>
                    <RycomGrid>
                        <RycomGridItem>
                            <RycomSelectField
                                id="outboundConnectorType"
                                label="Connector Types"
                                name="outboundConnectorType"
                                list={connectorTypes}
                                onChangeHandler={(event) => {
                                    filterOutboundConnectors(event[0].target.value);   
                                // setConnectorType(event[0].target.value);
                                return event[0].target.value;
                                }}
                                register={register}
                                control={control}
                                errors={errors}
                                errorDescription={{
                                required: {
                                    value: true,
                                    message: "Connector Type is required",
                                },
                                }}
                            >
                                {connectorTypes.map((connectorType1, index) => (
                                <MenuItem key={++index} value={connectorType1}>
                                    {connectorType1}
                                </MenuItem>
                                ))}
                            </RycomSelectField>
                        </RycomGridItem>
                        <RycomGridItem>	
                            <RycomSelectField id="outputConnectionId"	
                                label="Target - Output Bound Connector"	
                                name="outputConnectionId"	
                                onChangeHandler={(event) => {	
                                    return event[0].target.value;	
                                }}	
                                register={register}	
                                control={control}	
                                errors={errors}	
                                errorDescription={{	
                                    required: {	
                                        value: true,	
                                        message: 'Output Connector is required'	
                                    }	
                                }}>	
                                {connectorOutboundMenuList}	
                            </RycomSelectField>
                        </RycomGridItem>
                    </RycomGrid>
                </>}
                {taskStorageType == 'GraphConnector' &&
                <>
                    <RycomSectionHeader title="Vertex Storage Configuration"></RycomSectionHeader>
                    <RycomGrid>
                        <RycomGridItem>
                            <RycomSelectField id="pathClassType"	
                                label="Document Vertex Type"	
                                name="documentVertexType"	
                                onChangeHandler={(event) => {	
                                    return event[0].target.value	
                                }}	
                                register={register}	
                                control={control}	
                                errors={errors}	
                                errorDescription={{	
                                }} >	
                                {documentClassMenuList}	
                            </RycomSelectField>
                        </RycomGridItem>
                    </RycomGrid>
                </>}
                 <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                            onClick={() => {
                                reset();
                                }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Update</Button>
                </RycomSubmitModel>
     </form >
     </div>
  );
}
