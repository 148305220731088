import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react'
import * as ConnectorService from '../../services/ConnectorService';
import ConnectorEditTemplateForm from './ConnectorEditTemplateForm';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom'
import { red } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },

  rycomButtonGroup: {
    paddingLeft: '1100px'

  }
}));

export default function ConnectorEditForm() {
    
    let { id } = useParams();
    const [defaultValues, setDefaultValues]=useState({
        "connectorType":"",
        "filePath": "",
        "host": "",
        "passPhrase": "",
        "password": "",
        "port": "",
        "privateKeyFileLocation": "",
        "publicKeyAuthenticaiton": "",
        "userName": "abc",
    });


    useEffect(() => {
        ConnectorService
            .getConnectorById(id)
            .then((res) => {
                if (res && res.data) {
                    console.log('getConnectorById', res.data)
                    setDefaultValues({
                        "id":id,
                        "connectorType":res.data.connectionInfo.type,
                        "connectorFlowType":res.data.connectionInfo.dataflow,
                        "dataflow":res.data.connectionInfo.dataflow,
                        "filePath": res.data.connectionInfo.configuration.filePath,
                        "pathVariable": res.data.connectionInfo.configuration.pathVariable,
                        "host": res.data.connectionInfo.configuration.host,
                        "passPhrase": res.data.connectionInfo.configuration.passPhrase,
                        "password": res.data.connectionInfo.configuration.password,
                        "port": res.data.connectionInfo.configuration.port,
                        "privateKeyFileLocation": res.data.connectionInfo.configuration.privateKeyFileLocation,
                        "publicKeyAuthenticaiton": res.data.connectionInfo.configuration.publicKeyAuthenticaiton,
                        "userName": res.data.connectionInfo.configuration.userName,
                        "connectorName":res.data.connectorName,
                        "dataStoreName":res.data.connectionInfo.configuration.dataStoreName,
                        "query":res.data.connectionInfo.configuration.query,
                        "dataStoreType":res.data.connectionInfo.configuration.dataStoreType,
                        "frequency": res.data.connectionInfo.configuration.frequency,
                        "historyDurationType":res.data.connectionInfo.configuration.historyDurationType,
                        "offsetDuration":res.data.connectionInfo.configuration.offsetDuration,
                        "offsetDurationUnitType":res.data.connectionInfo.configuration.offsetDurationUnitType,
                        maxmiumFetchOffset: res.data.connectionInfo.configuration.maxmiumFetchOffset,
                        maxmiumUnitType: res.data.connectionInfo.configuration.maxmiumUnitType,
                        timeZone: res.data.connectionInfo.configuration.timeZone,
                        dateFormat: res.data.connectionInfo.configuration.dateFormat,
                        fetchType: res.data.connectionInfo.configuration.fetchType,
                        "createdDateColumn":res.data.connectionInfo.configuration.createdDateColumn,
                        "modifiedDateColumn":res.data.connectionInfo.configuration.modifiedDateColumn,
                        "fetchOffsetType":res.data.connectionInfo.configuration.fetchOffsetType,
                        "fetchStartDateString":res.data.connectionInfo.configuration.fetchStartDateString,
                        "fetchEndDateString":res.data.connectionInfo.configuration.fetchEndDateString,
                        "databaseName":res.data.connectionInfo.configuration.databaseName,
                        "baseURI": res.data.connectionInfo.configuration.baseURI,
                        "pathURI": res.data.connectionInfo.configuration.pathURI,
                        "history": res.data.connectionInfo.configuration.history,
                        "includePathId": res.data.connectionInfo.configuration.includePathId,
                        "requestMethod": res.data.connectionInfo.configuration.requestMethod,
                        "contentType": res.data.connectionInfo.configuration.contentType,
                        "connectionInfo":res.data.connectionInfo,
                        "headerContent":res.data.connectionInfo.configuration.header,
                        "bodyContent":res.data.connectionInfo.configuration.body,
                        "methodType":res.data.connectionInfo.configuration.methodType,
                        "requestMethod":res.data.connectionInfo.configuration.requestMethod,
                        "connectorLoginCredential": res.data.connectionInfo.configuration.connectorLoginCredential,
                        "startParamter":res.data.connectionInfo.configuration.startParamter,
                        "endParamter":res.data.connectionInfo.configuration.endParamter,
                        "databaseType":res.data.connectionInfo.configuration.databaseType,
                        "sslEnabled":res.data.connectionInfo.configuration.sslEnabled?'enabled':'disabled'
                      });
                     

                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    }, [])
 
  return (defaultValues && defaultValues.connectorType)?
        <ConnectorEditTemplateForm defaultValues={defaultValues}></ConnectorEditTemplateForm>:"";
  
}
