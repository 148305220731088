import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../../../common/RycomSectionHeader'
import RycomPageHeader from '../../../common/RycomPageHeader'
import RycomInfoPair from '../../../common/RycomInfoPair'
import * as DocumentClassService from '../../../../services/DocumentClassService';
import { useParams } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useHistory } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    details: {
      flexDirection: 'column',
    },
  
    aocard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'color-white',
      border: '1px solid ui-border-color-base',
      borderRadius: 'border-radius-base',
      marginBottom: 'spacer',
      padding: 0
     },
     
     title: {
        margin: 0,
       fontSize: 'xx-large',
       display: 'inline-block',
       paddingRight: '10px',
      },
  
     header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '5px solid $ui-border-color-base'
      },
  
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end'
      },  
  
      buttonStyle: {
        paddingRight: '5px',
      }
  
  }));
  

export default function VertexDetail() {

    let { id } = useParams();
    const classes = useStyles();
    const [vertexValues, setVertexValues] =useState();
    const history = useHistory();
    const [keycloak] = useKeycloak();


    const createDeleteButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                DocumentClassService.deleteDocumentVertex(id).then((res) => {
                    if (res && res.data && res.data) {
                        console.log(res.data);
                        alert(res.data.message)
                        history.push("/topics/documentvertex");
                    }
                })
                    .catch((error) => {
                        alert('some error in deleting the Job')
                        console.error('startTask =>', error)
                    })
            }}>Delete</Button>
        return DeleteButton;
    }

    useEffect(() => {
        console.log(id)
        DocumentClassService.getVertexById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data)
                    setVertexValues(res.data);
                }
            })
            .catch((error) => {
                console.error('getVertexById =>', error)
            })
    }, [])

    let vertexproperties = ""
    if(vertexValues && vertexValues.vertexproperties){
     vertexproperties = vertexValues.vertexproperties.map((vertexProperty => <Grid container spacing={12} style={{ padding: 24 }}>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Key" value={vertexProperty ? vertexProperty.key : ''}></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={2}>
            <RycomInfoPair label="Value" value={vertexProperty ? vertexProperty.value : ''}></RycomInfoPair>
        </Grid>
     </Grid>))
    }
    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/documentvertex"><Button
    color="primary" 
variant="contained" size="medium">
    Back</Button></Link>
    {keycloak.hasRealmRole("update:graph")?<Link className={classes.buttonStyle} to={'/topics/documentvertex/edit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">
    Edit</Button>
    </Link>:""} 
    {keycloak.hasRealmRole("delete:graph")?<span className={classes.buttonStyle} to="/topics/documentvertex">
        {createDeleteButton()}
    </span>:""} 
     <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;
    return (<div>
        
        <Card className={classes.aocard}>
<div className={classes.header}>
<span className={classes.title} >
{vertexValues ? `Vertex Details about ${vertexValues.className}` : 
        'Vertes Details'} </span>
<span className={classes.toolbar}>
{buttonLinks}
</span>
</div>
</Card>

        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Name" value={vertexValues ? vertexValues.className : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Vertex Description" value={vertexValues ? vertexValues.classDescription : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Check for Duplicate Record" value={vertexValues ? (vertexValues.checkDuplicate?"TRUE":"FALSE") : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Maintain Historical Record - Changes" value={vertexValues ? (vertexValues.checkForHistoricalChanges?"TRUE":"FALSE")  : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Historical Vertex Name" value={vertexValues ? vertexValues.duplicateVertex : ''}></RycomInfoPair>
            </Grid>
                 </Grid>
    </div>
    );
}
