import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../../common/RycomSectionHeader'
import RycomPageHeader from '../../common/RycomPageHeader'
import RycomInfoPair from '../../common/RycomInfoPair'
import RycomSelectField from '../../common/RycomSelectField'
import RycomTextField from '../../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../../common/RycomGrid'
import RycomSubmitModel from '../../common/RycomSubmitModel'
import JsonMapperForm from './JsonMapperForm';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import * as OrganisationService from '../../../services/OrganisationService';
import * as VenueService from '../../../services/VenueService';
import * as DeviceService from '../../../services/DeviceService';
import * as DataMapperService from '../../../services/DataMapperService';
import * as DocumentClassService from '../../../services/DocumentClassService';
import * as ConnectorService from '../../../services/ConnectorService';

import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import environment from '../../../environment'

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ProgressBar from '../../common/ProgressBar'
import { green, lightBlue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    },
    /*modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },*/
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
        position: 'absolute',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 1300,
        overflow: 'scroll',
    },
    invalidMessage: {
        display: 'inline-block',
        fontSize: '12px',
        color: 'red',
        paddingLeft: '12px',
    },
    gridBox: {
        padding: "24px",
        border: "1px solid #ddd",
        boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
        margin: "10px",
        width: "calc(100% - 20px)"
    }
}));


export default function DataMapperForm() {

    const history = useHistory();
    const [metadataproperties, setMetadataproperties] = useState([]);
    const [metadatapopupproperties, setMetadatapopupproperties] = useState([]);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openConnector, setOpenConnector] = React.useState(false);
    const [openSample, setOpenSample] = React.useState(false);
    const [selectedMapper, setSelectedMapper] = React.useState({});
    const [dataKey, setDataKey] = React.useState("");
    const [cellKey, setCellKey] = React.useState("");
    const [samplePayload, setSamplePayload] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(50);
    const [checked, setChecked] = useState(true);
    const [checked2, setChecked2] = useState(false);
    const [primaryKey, setPrimaryKey] = React.useState(false);
    const [headerPresent, setHeaderPresent] = React.useState();

    const [dataFlowType, setDataFlowType] = useState();
    
    const handleOpen = (mapper) => {
        console.log("mapper")
        console.log(mapper)
        if (fileType) {
            setOpen(true);
            setSelectedMapper(mapper);
            if(fileType == 'CSV') {
                if(Object.keys(mapper).length !== 0 ) {
                    setMetadatapopupproperties(mapper.metadata);
                    setTransformationType(mapper.transformationType);
                    setTransformationConfiguration(mapper.transformationConfiguration)
                } else {
                    setMetadatapopupproperties([]);
                    setTransformationType('');
                    setTransformationConfiguration({})
                }

            }
        } else {
            alert("Please select the File Type")
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenConnector = (mapper) => {
        setOpenConnector(true);
    };

    const handleCloseConnector = () => {
        setOpenConnector(false);
    };

    const handleOpenSample = (mapper) => {
        setOpenSample(true);
    };

    const handleCloseSample = () => {
        setOpenSample(false);
    };

    const defaultValues = {
        "metadata": metadataproperties
    };

    const [companyId, setCompanyId] = useState(undefined);
    const [fileType, setFileType] = useState(undefined);

    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const {
        register: register2,
        errors: errors2,
        control: control2,
        handleSubmit: handleSubmit2,
        reset: reset2
    } = useForm({});

    const fileTypeHandler = (fileTypeValue) => {
        console.log(fileTypeValue)
        setFileType(fileTypeValue);
        setMapperList([]);
        setReviewOutput(undefined);
        setResponsePayload(undefined);
        //  if(fileTypeValue==='JSON')
    }


    const [mapperError, setMapperError] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [vertexList, setVertexList] = useState([]);
    const [connectorList, setConnectorList] = useState([]);
    const [vertexName, setVertexName] = useState("");
    const [vertexId, setVertexId] = useState("");   
    const [dataSourceAttribute, setDataSourceAttribute] = useState([]);

    const [deviceList, setDeviceList] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const [mapperList, setMapperList] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [duplicateVertex, setDuplicateVertex] = useState();


    
    const [responsePayload, setResponsePayload] = useState();
    const [reviewOutput, setReviewOutput] = useState();

    const [primaryKeyEnabled, setPrimaryKeyEnabled] = React.useState(false);
    const [historicalEnabled, setHistoricalEnabled] = React.useState(false);
    const [completeDateSet, setcompleteDateSet] = React.useState(false);
    const [maintainHistoryValue, setMaintainHistoryValue] = React.useState(false);

    const dataTimeZoneTypes = [
        "Etc/GMT",
        "America/New_York",
        "America/Denver",
        "America/Los_Angeles",
        "America/Halifax"
    ]

    const handlePrimaryKeyEnabledChange = () => {
        setPrimaryKeyEnabled(!primaryKeyEnabled)
    }

    const handleHistoricalEnabledChange = () => {
        setHistoricalEnabled(!historicalEnabled)
    }

    const handlePrimaryKeyChange = () => {
        setPrimaryKey(!primaryKey)
        setMaintainHistoryValue(true)
    }

    const handleMaintainHistoryValue = () => {
        setMaintainHistoryValue(!maintainHistoryValue)
    }

    const [mappedCsvKey, setMappedCsvKey] = React.useState('');

    const mappedCsvKeyChange = e => {
        const value = e.target.value;
        setMappedCsvKey(value.split(" ").join(""));
    };


    const JsonMapperFormContent = <JsonMapperForm register2={register2}
        errors2={errors2}
        control2={control2}
        handleSubmit2={handleSubmit2}
        reset2={reset2}
        mapperList={mapperList}
        setMapperList={setMapperList}
        setMapperError={setMapperError}
        handleClose={handleClose}
        primaryKeyEnabled={primaryKeyEnabled}
        historicalEnabled={historicalEnabled}
        completeDateSet={completeDateSet}
        fileType={fileType}
        selectedMapper={selectedMapper}
    ></JsonMapperForm>;

    const getParentOrgId = () => {
        const userInfoJson = localStorage.getItem("userInfo");
        const userInfo = JSON.parse(userInfoJson);
        const parentcompanyid = userInfo['parentcompanyid'];
        return parentcompanyid;
    }
 
    
    const userInfoJson = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(userInfoJson);

    const parentcompanyid = getParentOrgId();
    const companyid=userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;
    
    const canAddNewMetadataProperties = () => {
        const length = metadataproperties.length - 1
        return metadataproperties[length].v_id
    };
    
    const canAddNewMetadataPopupProperties = () => {
        const length = metadatapopupproperties.length - 1
        return metadatapopupproperties[length].v_id
    };

    const newMetadataProfile = () => {
        let id = 1;
        if (metadataproperties.length > 0) {
            let lastKey = canAddNewMetadataProperties();
            id = ++lastKey;
        }

        console.log(id)
        const newMetadataProfile = [...metadataproperties, {
            'v_id': id,
            'key': null,
            'value': null,
        }]
        setMetadataproperties(newMetadataProfile);
    }

    const newMetadataPopupProfile = () => {
        let id = 1;
        if (metadatapopupproperties.length > 0) {
            let lastKey = canAddNewMetadataPopupProperties();
            id = ++lastKey;
        }

        console.log(id)
        const newMetadataProfile = [...metadatapopupproperties, {
            'v_id': id,
            'key': null,
            'value': null,
        }]
        setMetadatapopupproperties(newMetadataProfile);
    }

    const deleteMetadataProfile = (index) => {
        const deleteProfiles = [...metadataproperties]
        if (deleteProfiles.length > 1) {
            deleteProfiles.splice(index, 1);
            setMetadataproperties(deleteProfiles);
        } else {
            setMetadataproperties([{
                'v_id': 1,
                'key': null,
                'value': null,
            }])
        }
    };

    if (!metadataproperties.length) {
        deleteMetadataProfile()
    }

    const deleteMetadataPopupProfile = (index) => {
        const deleteProfiles = [...metadatapopupproperties]
        if (deleteProfiles.length > 1) {
            deleteProfiles.splice(index, 1);
            setMetadataproperties(deleteProfiles);
        } else {
            setMetadatapopupproperties([{
                'v_id': 1,
                'key': null,
                'value': null,
            }])
        }
    };

    if (!metadatapopupproperties.length) {
        deleteMetadataPopupProfile()
    }

    const changeKeyHandler = (edgeProperty) => (event) => edgeProperty.key = event.target.value;
    const changeValueHandler = (edgeProperty) => (event) => edgeProperty.value = event.target.value;

    
    const changeKeyOnPopup = (edgeProperty,event) => { 

        let dataList = metadatapopupproperties.map((item) => {
            if(item.id == edgeProperty.id) {
                item.key = event.target.value
                return item;
            } else {
                return item;
            }
        });
    
        setMetadatapopupproperties(dataList);
 
    }

    const changeValueOnPopup = (edgeProperty,event) => { 

        let dataList = metadatapopupproperties.map((item) => {
            if(item.id == edgeProperty.id) {
                item.value = event.target.value
                return item;
            } else {
                return item;
            }
        });
    
        setMetadatapopupproperties(dataList);
 
    }

    const handleVertexChange = (vertexId) => {
        // console.log(vertexId)
        // console.log(vertexList)
        const selectedVertexList = vertexList.filter(vertex => vertex.classId == vertexId);
        console.log(selectedVertexList)
        if (selectedVertexList.length > 0) {
            const selectedVertex = selectedVertexList[0];
            setVertexName(selectedVertex.className)
            setVertexId(vertexId)
            if (selectedVertex.checkDuplicate)
                setPrimaryKeyEnabled(true);
            else
                setPrimaryKeyEnabled(false);

            if (selectedVertex.checkForHistoricalChanges) {
                setHistoricalEnabled(true);
                setDuplicateVertex(selectedVertex.duplicateVertex);
            } else {
                setHistoricalEnabled(false);
                setDuplicateVertex(null);
            }
        } else {
            setVertexName(undefined);
        }
    }


    const metadataList = metadataproperties.map((metadata, index) => {
        metadata.id = index;
        return <RycomGrid>
            <RycomGridItem>
                <div>  <TextField
                    id="outlined-multiline-flexible"
                    label="key"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Metadata key is required'
                        }
                    })}
                    value={metadata.key}
                    onChange={changeKeyHandler(metadata)}
                />
                    <span>
                        <span>
                            {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
                        </span>
                    </span>
                </div>
            </RycomGridItem>
            <RycomGridItem>
                <span>  <TextField
                    id="outlined-multiline-flexible"
                    label="Value"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Edge key is required'
                        }
                    })}
                    value={metadata.value}
                    onChange={changeValueHandler(metadata)}
                />
                    <span>
                        {errors['PropertyValue' + metadata.id] && <span>{errors['PropertyValue' + metadata.id].message}</span>}
                    </span>
                </span>
            </RycomGridItem>
            <RycomGridItem>
                <Button color="secondary" style={{marginTop:"20px"}} variant="contained" size="small" disabled={metadataproperties.length == 1} value="delete Edge"
                    onClick={(e) => {
                        const deleteProfiles = [...metadataproperties]

                        if (metadataproperties.length > 1) {
                            const deleteProfiles1 = deleteProfiles.filter(edge => {
                                return edge.v_id != metadata.v_id
                            });
                            setMetadataproperties(deleteProfiles1);
                        } else {
                            setMetadataproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null
                            }])
                        }
                    }}>Delete Metadata</Button>
            </RycomGridItem>
        </RycomGrid>;
    });

    const metadataPopupList = metadatapopupproperties.map((metadata, index) => {
        metadata.id = index;
        return <RycomGrid>
            <RycomGridItem>
                <div>  <TextField
                    id="outlined-multiline-flexible"
                    label="key"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Metadata key is required'
                        }
                    })}
                    value={metadata.key}
                    // onChange={changeKeyHandler(metadata)}
                    onChange={(e) => changeKeyOnPopup(metadata,e)}
                />
                    <span>
                        <span>
                            {errors['edgepropertykey' + metadata.id] && <span>{errors['edgepropertykey' + metadata.id].message}</span>}
                        </span>
                    </span>
                </div>
            </RycomGridItem>
            <RycomGridItem>
                <span>  <TextField
                    id="outlined-multiline-flexible"
                    label="Value"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Edge key is required'
                        }
                    })}
                    value={metadata.value}
                    // onChange={changeValueOnPopup(metadata)}
                    onChange={(e) => changeValueOnPopup(metadata,e)}
                />
                    <span>
                        {errors['PropertyValue' + metadata.id] && <span>{errors['PropertyValue' + metadata.id].message}</span>}
                    </span>
                </span>
            </RycomGridItem>
            <RycomGridItem>
                <Button color="secondary" style={{marginTop:"20px"}} variant="contained" size="small" disabled={metadatapopupproperties.length == 1} value="delete Edge"
                    onClick={(e) => {
                        const deleteProfiles = [...metadatapopupproperties]

                        if (metadatapopupproperties.length > 1) {
                            const deleteProfiles1 = deleteProfiles.filter(edge => {
                                return edge.v_id != metadata.v_id
                            });
                            setMetadatapopupproperties(deleteProfiles1);
                        } else {
                            setMetadatapopupproperties([{
                                'v_id': 1,
                                'key': null,
                                'value': null
                            }])
                        }
                    }}>Delete Metadata</Button>
            </RycomGridItem>
        </RycomGrid>;
    });


    const mapperDisplayList = mapperList.map(mapper => {
        console.log("mapper inside")
        console.log(mapper)
        const InnerCsvContent = mapper.metadata?.map((metadata) => (
            <Grid container spacing={12} style={{ padding: 10, marginLeft: 25 }}>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair
                    label="metadata Key"
                    value={metadata ? metadata.key : ""}
                ></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair
                    label="metadata value"
                    value={metadata ? metadata.value : ""}
                ></RycomInfoPair>
                </Grid>
            </Grid>
        ));

        return (
            <>
                <Grid container spacing={12} className={classes.gridBox}>

                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <RycomInfoPair label="Position" value={mapper ? mapper.postion : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <RycomInfoPair label="CSV Column Header" value={mapper ? mapper.key : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <RycomInfoPair label="Target Column Name" value={mapper ? mapper.mappedJsonKey : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <RycomInfoPair label="Transformation Type" value={mapper ? mapper.transformationType : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} xl={2}>
                        <RycomInfoPair label="Column Datatype" value={mapper ? mapper.valuetype : ''}></RycomInfoPair>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} xl={2} >
                        <span style={{marginRight: "5px"}}>
                            <Button color="default" variant="contained" size="small" value="edit Mapper" onClick={()=>{handleOpen(mapper)}}>Edit Mapper</Button>
                        </span>
                        <span>
                            <Button color="secondary" variant="contained" size="small" value="delete Mapper "
                                onClick={(e) => {
                                { if (window.confirm('Are you sure you wish to delete this item?')) 
                                    {
                                        const deleteProfiles = [...mapperList]
                                        if (mapperList.length > 1) {
                                            const deleteProfiles1 = deleteProfiles.filter(mapperListValue => {
                                                return mapperListValue.postion != mapper.postion
                                            });
                                            setMapperList(deleteProfiles1);
                                        } else {
                                            setMapperList([])
                                        }
                                    }
                                }
                                }}>Delete Mapper</Button>
                        </span>
                            
                    </Grid>

                    {InnerCsvContent}
                </Grid>
            </>
        );
    } 
    );

const paddingStyle = {
    paddingTop:10,
    paddingRight:10,
    paddingBottom:0,
    paddingLeft:10
};

    const jsonMapperDisplayList = mapperList.map(mapper => {

        // If child element has then it will show
        const InnerJsonContent = mapper.nestedJsonTypeConfig?.map(
            (nestermapper) => (
                // <span>
                    <Grid container spacing={12} style={{paddingStyle,marginLeft:60}}>
                        <Grid item xs={12} sm={6} lg={4} xl={2}>
                            <RycomInfoPair
                            label="Key"
                            value={nestermapper ? nestermapper.key : ""}
                            ></RycomInfoPair>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} xl={2}>
                            <RycomInfoPair
                            label="mappedJsonKey"
                            value={nestermapper ? nestermapper.mappedJsonKey : ""}
                            ></RycomInfoPair>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} xl={2}>
                            <RycomInfoPair
                            label="TransFormationType"
                            value={nestermapper ? nestermapper.transformationType : ""}
                            ></RycomInfoPair>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} xl={2}>
                            <RycomInfoPair
                            label="Marked As PrimaryKey"
                            value={nestermapper.markedAsPrimaryKey == true ? 'TRUE' : "FALSE"}
                            ></RycomInfoPair>
                        </Grid>
                    </Grid>
                // </span>
            )
        );

        return (
            <>
                <Grid container spacing={12} className={classes.gridBox}>
                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <RycomInfoPair label="Original Key" value={mapper ? mapper.key : ''}></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <RycomInfoPair label="Mapped Key" value={mapper ? mapper.mappedJsonKey : ''}></RycomInfoPair>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <RycomInfoPair
                            label="TransFormationType"
                            value={mapper ? mapper.transformationType : ""}
                        ></RycomInfoPair>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} xl={2}>
                        <RycomInfoPair
                            label="Marked As Primary Key"
                            value={mapper.markedAsPrimaryKey==true?"TRUE":"FALSE"}
                        ></RycomInfoPair>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2} xl={2} style={{marginTop: "30px"}}>
                        <span style={{marginRight: "5px"}}>
                            <Button color="default" variant="contained" size="small" value="edit Mapper" onClick={()=>{handleOpen(mapper)}}>Edit Mapper</Button>
                        </span>
                        <span>
                        <Button color="secondary" variant="contained" size="small" value="delete Mapper"
                            onClick={(e) => {
                                { if (window.confirm('Are you sure you wish to delete this item?')) 
                                {
                                const deleteProfiles = [...mapperList]
                                if (mapperList.length > 1) {
                                    const deleteProfiles1 = deleteProfiles.filter(mapperListValue => {
                                        return mapperListValue.key != mapper.key
                                    });
                                    setMapperList(deleteProfiles1);
                                } else {
                                    setMapperList([])
                                }
                            }}
                            }}>Delete Mapper</Button> 
                        </span>
                        
                    </Grid>
                    
                    {InnerJsonContent}
                </Grid>
               
            </>
        )
    });

        // inner json content start here
        let jsonInnerMapperDisplayList = "";
      jsonInnerMapperDisplayList = mapperList.map(mapper => {
        let content ="";
        const InnerJsonContent = mapper.nestedJsonTypeConfig?.map(nestermapper =>
        <Grid container spacing={12} style={{paddingStyle,marginLeft:60}}>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair label="Original Key" value={nestermapper ? nestermapper.key : ''}></RycomInfoPair>
        </Grid>
        <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair label="Mapped Key" value={nestermapper ? nestermapper.mappedJsonKey : ''}></RycomInfoPair>
        </Grid>
        {fileType === 'CSV'?
            <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair label="TransFormationType" value={nestermapper ? nestermapper.transformationType : ''}></RycomInfoPair>
            </Grid>:""}
      
        {/* <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair label="Attribute Datatype" value={nestermapper ? nestermapper.valuetype : ''}></RycomInfoPair>
        </Grid> */}
            {fileType === 'JSON'?
            <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair label="TransFormationType" value={nestermapper ? nestermapper.transformationType : ''}></RycomInfoPair>
            </Grid>:""}
            {fileType === 'JSON'?
            <Grid item xs={12} sm={6} lg={2} xl={2}>
                <RycomInfoPair label="Marked As PrimaryKey" value={nestermapper.markedAsPrimaryKey==true?"TRUE":"FALSE"}></RycomInfoPair>
            </Grid>:""}
        {fileType === 'JSON-CSV DATA GRID'?
        <Grid item xs={12} sm={6} lg={2} xl={2}>
            <RycomInfoPair label="Transformation Type" value={nestermapper ? nestermapper.transformationType : ''}></RycomInfoPair>
        </Grid>
        :""}
            {fileType === 'JSON-CSV DATA GRID'?
        <Grid item xs={12} sm={6} lg={2} xl={2}>
        <RycomInfoPair label="Marked As PrimaryKey" value={nestermapper.markedAsPrimaryKey==true?"TRUE":"FALSE"}></RycomInfoPair>
    </Grid>
        :""}
        
        </Grid>);
        content = <span>
                {InnerJsonContent}
            </span>
        return content;
        }); // inner json content end here

          // inner csv details diplayed from here
            let innerCsvDetails = "";
            innerCsvDetails = mapperList.map((mapper) => {
                let content = "";
                const InnerCsvContent = mapper.metadata?.map((metadata) => (
                <Grid container spacing={12} style={{ padding: 10, marginLeft: 25 }}>
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair
                        label="metadata Key"
                        value={metadata ? metadata.key : ""}
                    ></RycomInfoPair>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={2}>
                    <RycomInfoPair
                        label="metadata value"
                        value={metadata ? metadata.value : ""}
                    ></RycomInfoPair>
                    </Grid>
                </Grid>
                ));
                content = <div>{InnerCsvContent}</div>;
                return content;
            });
            // inner csv details end here

    useEffect(() => {

       


        if (companyList.length == 0) {
            //OrganisationService.getCompanyByParentId(parentcompanyid)
            OrganisationService.getCompanyParentById(companyid)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setCompanyList(res.data);
                    }
                })
                .catch((error) => {
                    console.error('getConnector =>', error)
                })
        }
        getVertexList(parentcompanyid);

        ConnectorService
            .getAllConnector()
            .then((res) => {
                if (res && res.data && res.data) {
                    let connectorInfo = res.data[0].body;
                    setConnectorList(connectorInfo);
                }
            })
            .catch((error) => {
                alert('Connector Retrieval Error');
                console.error('getConnector =>', error)
            })

    }, []);


    const reviewDatamapperOutput=()=>{
        alert("inside the review output")
        const fileinfoModel={
            filetype:fileType,
            headerpresent:"",
            dataKey:dataKey,
            cells:cellKey
        }
        let payload= {"mapper":mapperList, 
            "fileinfoModel": fileinfoModel,
            "payload":responsePayload}
        console.log('reviewDatamapperOutput', payload);
    
    DataMapperService.reviewDatamapper(payload).then((res) => {
        console.log(res)
        if (res.status === 200)
            alert('DataMapper Review generated successfully');
            console.log(res.datas);
            setReviewOutput(res.data);
        })
        .catch((error) => {
            alert("DataMapper error")
            console.error('DataMapper =>', error)
        })
        return false;
    };

    const getVenueList = (event) => {
        setCompanyId(event.target.value);
        VenueService.getVenueByCompanyId(event.target.value)
            .then((res) => {
                if (res && res.data && res.data) {
                    setVenueList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
        return event.target.value
    };

    const getDeviceList = (event) => {
        const venueId = event.target.value;
        DeviceService.getDeviceByVenueId(venueId)
            .then((res) => {
                if (res && res.data && res.data) {
                    setDeviceList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
        return event.target.value
    };

    let mapperContent = "";
    const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);
    const venueMenuList = venueList.map((venue, index) => <MenuItem key={++index} value={venue.venueid}> {venue.venuename}</MenuItem>);
    const deviceMenuList = deviceList.map((device, index) => <MenuItem key={++index} value={device.deviceid}> {device.devicename}</MenuItem>);

    if (fileType === 'CSV') {
        mapperContent = mapperDisplayList;
    }
    else if (fileType === 'JSON' || fileType === 'JSON-CSV DATA GRID') {
        mapperContent = jsonMapperDisplayList;
    }


    const handleMapperSubmit = data => {

        console.log("data")
        console.log(data)

        const metaDataPayload = metadatapopupproperties.filter((metadatapropertiesValue) => {
            return (metadatapropertiesValue.key !== '' && metadatapropertiesValue.key !== null)
        });

        const mapperPayload = {
            "key": data.key,
            "postion": data.position,
            "mappedJsonKey": data.mappedJsonKey,
            "transformationType": data.transformationType,
            "valuetype": data.valueType,
            "isActive": true,
            "metadata": metaDataPayload,
            "maintainHistoricalRecord": maintainHistoryValue
        };

        if(primaryKey) {
            mapperPayload['markedAsPrimaryKey'] = primaryKey;
        }

        if(data.transformationType && data.transformationType=='DATE'){
            mapperPayload['transformationConfiguration']=transformationConfiguration
        }

        let mapperListValues = [];

        let findExistingMapperWithSamePosition = mapperList.find(mapper => mapper.postion === mapperPayload.postion);
        
        if(Object.keys(selectedMapper).length === 0) { // is datamapper edit or add? Adding here

            if (findExistingMapperWithSamePosition) {
                alert('Position already exist: Please enter different Position or Delete the existing mapper with same position number')
            } else {
                mapperListValues = [...mapperList, mapperPayload]
            }

        } else { // editing

            if(findExistingMapperWithSamePosition && findExistingMapperWithSamePosition.postion !== selectedMapper.postion) {
                alert('Position already exist: Please enter different Position or Delete the existing mapper with same position number')
            } else {
                
                mapperListValues = mapperList.map(data => {
                    let mapperListValue;
                    if (data.postion === selectedMapper.postion) {
                        mapperListValue = { ...mapperPayload }
                    } else {
                        mapperListValue = { ...data }
                    }

                    return mapperListValue;
                })

            }

        }

        if(mapperListValues.length > 0) {
            
            mapperListValues.sort((a, b) => {
                if (parseInt(a.postion) > parseInt(b.postion)) return 1;
                else if (parseInt(a.postion) < parseInt(b.postion)) return -1;
                else return 0;
            });
    
            setMapperList(mapperListValues);
            setMapperError(false);
            setMetadatapopupproperties([]);
            setPrimaryKey(false);
            setTransformationType('');
            setTransformationConfiguration({});
            handleClose();

        }
        
    }

    const handleAutoGenerateConnectorSubmit = data => {
        const fileinfoModel={
            filetype:fileType,
            headerpresent:fileType === "CSV" ? headerPresent : "",
            dataKey:dataKey,
            cells:cellKey
        }
        let payload= {...data, "fileinfoModel": fileinfoModel}
        console.log('handleAutoGenerateConnectorSubmit', payload);
      
        setLoading(true);
        let increamentedValue=progress
        const interval =  setInterval(function(){
          increamentedValue++;
          setProgress(increamentedValue);
        },400)

    DataMapperService.generateDatamapper(payload,
        {
            onDownloadProgress: progressEvent => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (percentage === 100) {
                setProgress(100);
                clearInterval(interval);
                setTimeout(() => {
                  setLoading(false);
                }, 400);
              }
            }
          }).then((res) => {
        console.log(res)
        if (res.status === 200)
            setMapperList(res.data.mapper);
            setResponsePayload(res.data.payload);
            setTimeout(() => {
                setOpenConnector(false);
                alert('Mapper generated successfully');
                setProgress(50)
            }, 400);
        })
        .catch((error) => {
            alert("DataMapper error")
            setLoading(false);
            clearInterval(interval);
            console.error('DataMapper =>', error)
        })
    }

    const handleAutoGenerateSampleSubmit = data => {
        console.log('handleAutoGenerateSampleSubmit', data);
        const fileinfoModel={
            filetype:fileType,
            headerpresent:"",
            dataKey:dataKey,
            cells:cellKey
        }
        let payload= {...data, "fileinfoModel": fileinfoModel, 'samplePayload':JSON.parse(samplePayload)}
        console.log('handleAutoGenerateConnectorSubmit', payload);
        console.log(JSON.stringify(payload, null, 2))
    DataMapperService.generateDatamapperFromSamplePayload(payload).then((res) => {
        console.log(res)
        if (res.status === 200)
            alert('Mapper generated successfully');
            setMapperList(res.data.mapper);
            setResponsePayload(res.data.payload);
           // setOpenConnector(false);
           setOpenSample(false);
        })
        .catch((error) => {
            console.log(error)
            alert("DataMapper error")
            console.error('DataMapper =>', error)
        })
    }

    const onSubmit = data => {
        if (mapperList.length === 0) {
            setMapperError(true);
            return false;
        }

        console.log(data);

        const metadatapropertiesContent = metadataproperties.filter((metadatapropertiesValue) => {
            return (metadatapropertiesValue.key !== '' && metadatapropertiesValue.key !== null)
        });


        let payload = {
            "datamappername": data.datamappername,
            "datamapperdescription": data.datamapperdescription,
            "isActive": true,
            "datacreated": 1582338051,
            //"parentcompanyid": parentcompanyid,
            "parentcompanyid": paramID,
            "companyid": data.companyid,
            "venueid": data.venueid,
            "deviceid": data.deviceid,
            "createdbyuserid": "0",
            "metadata": metadatapropertiesContent,
            "datainfo": { filetype: data.filetype, 
                headerpresent: false, 
                dataKey: '',
                cells:'' },
            "checkDuplicate": primaryKeyEnabled,
            "checkForHistoricalChanges": historicalEnabled,
            "duplicateVertex": duplicateVertex,
            "mapper": mapperList,
            "dataflow":dataFlowType
        }

        if(fileType == 'JSON'){
            payload["vertexName"] = vertexName;
            payload["vertexId"] = vertexId;
            payload.datainfo.dataKey = data.dataKey;
            // payload["dataflow"]=dataFlowType
        } else if(fileType == 'JSON-CSV DATA GRID'){
            payload["vertexName"] = vertexName;
            payload["vertexId"] = vertexId;
            payload.datainfo.dataKey = data.dataKey;
            payload.datainfo.cells = data.cells;
        }
        
        //console.log(data.headerpresent)
        if (data.headerpresent == 'TRUE')
            payload.datainfo.headerpresent = true

        console.log("payload", payload);
        DataMapperService.saveDataMapper(payload).then((res) => {
            console.log(res)
            if (res.status === 200)
                alert('DataMapper inserted successfully');
            history.push("/topics/datamapper")
        })
            .catch((error) => {
                alert("DataMapper error")
                console.error('DataMapper =>', error)
            })
    }

    const getVertexList = (companyId) => {
        console.log("inside the getVertex List")
        let newParentcompanyid=parentcompanyid=='0'?userInfo['companyid']:userInfo['parentcompanyid'];

        DocumentClassService.getAllVertex(newParentcompanyid)
            .then((res) => {
                if (res && res.data && res.data) {
                    setVertexList(res.data);
                }
            })
            .catch((error) => {
                console.error('getVertexList =>', error)
            })
    };

    const [mappedJsonKey, setMappedJsonKey] = React.useState(selectedMapper && selectedMapper.mappedJsonKey ? selectedMapper.mappedJsonKey : '');
    const [transformationType, setTransformationType]=React.useState(selectedMapper && selectedMapper.transformationType ? selectedMapper.transformationType : '');
    const [transformationConfiguration, setTransformationConfiguration]=React.useState(selectedMapper && selectedMapper.transformationConfiguration ? selectedMapper.transformationConfiguration : {});

    const mappedJsonKeyChange = e => {
        const value = e.target.value;

        setMappedJsonKey(value.split(" ").join(""));
    };

    const vertexMenuList = vertexList.map((vertex, index) => <MenuItem key={++index} value={vertex.classId}> {vertex.className}</MenuItem>);
    
    let connectorInboundList;
    
    if(fileType === "CSV") {

        const csvArray = [
            "sftp",
            "sftprecursive",
            "ftp",
            "ftprecrusive",
            "ftps",
            "ftpsrecrusive"
        ];

        connectorInboundList = connectorList.filter(connector => {

            const connectorType = connector.connectionInfo.type;

            return csvArray.includes(connectorType);

            // return connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound';
        });

    } else {
        
        const jsonArray = [
            "API",
            "JDBC",
            "ORIENT"
        ];

        connectorInboundList = connectorList.filter(connector => {
            

            const connectorType = connector.connectionInfo.type.toUpperCase();

            return jsonArray.includes(connectorType);
            // return connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound';
        });

    }

    const connectorListItems = connectorInboundList.map((connector, index) => <MenuItem key={++index} value={connector.id}> {connector.connectorName}</MenuItem>);
    
    const connectorMenuList = connectorListItems.filter(connector=>connector.dataFlowType!='outbound');

    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the DataMapper"
                    links={[{ title: 'Back', url: '/topics/datamapper' }]}></RycomPageHeader>

                <RycomSectionHeader title="DataMapper Form"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="companyid"
                            label="companyid"
                            name="companyid"
                            onChangeHandler={(event) => {
                                return getVenueList(event[0])
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Company is required'
                                }
                            }}
                        >
                            {companyMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="Venue"
                            label="Venue"
                            name="venueid"
                            onChangeHandler={(event) => {
                                return getDeviceList(event[0])
                            }}
                            register={register}
                            control={control}
                            disabled={!companyId}
                        >
                            {venueMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="deviceId"
                            label="Device"
                            name="deviceid"
                            disabled={!companyId}
                            onChangeHandler={(event) => {
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                        >
                            {deviceMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                </RycomGrid>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="datamappername"
                            label="Data Mapper Name"
                            name="datamappername"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Data Mapper Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="datamapperdescription"
                            label="Data Mapper Description"
                            name="datamapperdescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Data Mapper Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                </RycomGrid>
                <RycomSectionHeader title="Data Info"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="File Type"
                            label="File Type"
                            name="filetype"
                            onChangeHandler={(event) => {
                                fileTypeHandler(event[0].target.value);
                                return event[0].target.value
                            }}
                            value={fileType}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'File Type is required'
                                }
                            }}
                        >
                            <MenuItem key="1" value="CSV">CSV</MenuItem>
                            <MenuItem key="2" value="JSON">JSON </MenuItem>
                            <MenuItem key="3" value="JSON-CSV DATA GRID">JSON-CSV DATA GRID </MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>

                    {fileType == 'CSV' ?
                          <RycomGridItem>
                            <RycomSelectField id="headerpresent"
                                label="Header Present"
                                name="headerpresent"
                                onChangeHandler={(event) => {
                                    setHeaderPresent(event[0].target.value);
                                    return event[0].target.value
                                }}
                                register={register}
                                control={control}
                                errors={errors}
                                errorDescription={{
                                    required: {
                                        value: true,
                                        message: 'Header Present is required'
                                    }
                                }}
                            >
                                <MenuItem key="1" value="TRUE"> TRUE</MenuItem>
                                <MenuItem key="2" value="FALSE">FALSE </MenuItem>
                            </RycomSelectField>
                        </RycomGridItem>: <RycomGridItem>
                        <RycomTextField id="dataKey"
                            label="Data Source Attribute"
                            name="dataKey"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                
                            }}
                            onChangeHandler={(event) => {
                                setDataKey(event.target.value)
                                return event.target.value;

                            }}
                        />

                    </RycomGridItem>}
                    <RycomGridItem>
                        <RycomSelectField
                            id="dataflow"
                            label="Connector Flow Types"
                            name="dataflow"
                            onChangeHandler={(event) => {
                                setDataFlowType(event[0].target.value);
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                value: true,
                                message: "Connector Flow Type is required",
                                },
                            }}
                            >
                            <MenuItem value="inbound" key="1">Data Storage</MenuItem>
                            <MenuItem value="outbound" key="2">Data Piping</MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>
                 </RycomGrid>

                    {fileType == 'JSON-CSV DATA GRID' ?
                       <RycomGrid> <RycomTextField id="cells"
                            label="Cell Source Attribute"
                            name="cells"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                
                            }}
                            onChangeHandler={(event) => {
                                setCellKey(event.target.value)
                                return event.target.value;
                            }}
                        /></RycomGrid>:""}
                
                <RycomSectionHeader title="Meta Data"></RycomSectionHeader>
                {metadataList}
                <Grid container spacing={12} className={classes.rycomButtonGroup} >
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} xl={4} >
                        <span>
                            <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newMetadataProfile}>Add Metadata</Button>
                        </span>
                    </Grid>
                </Grid>
                {/* {fileType !== 'CSV' && dataFlowType!='outbound' ?  */}
                <span>
                    <RycomSectionHeader title="DataStore - Constraint Info"></RycomSectionHeader>
                    <RycomGrid>
                        <RycomGridItem>
                            <FormControlLabel
                                style={{ width: 300 }}
                                control={<Checkbox
                                    checked={primaryKeyEnabled} onChange={handlePrimaryKeyEnabledChange} name="flattening" />}
                                label="Check for Duplicate Record"
                            />

                        </RycomGridItem>
                        <RycomGridItem>
                            <FormControlLabel
                                style={{ width: 300 }}
                                control={<Checkbox
                                    checked={historicalEnabled} onChange={handleHistoricalEnabledChange} name="flattening" />}
                                label="Maintain Historical Record - Changes"
                            />
                            {historicalEnabled?<span>
                            <FormControl component="fieldset">
                            <Controller
                    control={control}
                    name="flattening"
                    as={
                      <RadioGroup row>
                        <FormControlLabel
                          value="fieldLevel"
                          control={<Radio checked={checked}/>}
                          label="Field Level"
                          onChange={() => {
                            setChecked2(false);
                            setChecked(true);
                            setcompleteDateSet(false);
                          }}
                        />
                        <FormControlLabel
                          value="completeDateSet"
                          control={<Radio />}
                          label="Complete DataSet"
                          onChange={() => {
                            setChecked(false);
                            setcompleteDateSet(true);
                          }}
                        />
                       
                      </RadioGroup>
                    }
                  />
                  </FormControl></span>:""}
                        </RycomGridItem>
                    </RycomGrid>
                </span> 
                {/* : ''} */}
{responsePayload?<span>
<br></br>
                <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1-header"
        >
          <Typography>Extracted Input Payload</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          <pre>{JSON.stringify(responsePayload, null, 2) }</pre>
                   </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel></span>:""}

    {reviewOutput?<span>
      <br></br>
      <ExpansionPanel>

        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel2-header"
        >
          <Typography>Review: Datamapper</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          <pre>{JSON.stringify(reviewOutput, null, 2) }</pre>
                   </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </span>:""}

                <RycomSectionHeader title={
                    mapperError ? <span>Mapper Configuration<span className={classes.invalidMessage}>
                        Mapper Details is required</span></span> : <span>Mapper Configuration</span>} ></RycomSectionHeader>
                <div>
                    {mapperContent}
                    {/* {jsonInnerMapperDisplayList} */}
                    {/* {innerCsvDetails} */}
                    {fileType?<span>
                    <Grid container spacing={12} className={classes.rycomButtonGroup} >
                        <Grid item xs={12} sm={2} lg={2} xl={2} ></Grid>
                        
                        <Grid item xs={12} sm={3} lg={3} xl={3}>
                                {/* {fileType!=='CSV'? */}
                                <span>
                                    <Button color="primary" variant="contained" size="medium" value="Add Generate from Connector" onClick={handleOpenConnector}>Auto Generate from Connector</Button>
                                </span>
                                {/* :""} */}
                           
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3} xl={3} >
                                {fileType!=='CSV'?
                                <span>
                                    <Button color="primary" variant="contained" size="medium" value="Add Generate from Sample" onClick={handleOpenSample}>Auto Generate from Sample</Button>
                                </span>:""}
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4} xl={4} >
                            <span>
                                <Button color="primary" variant="contained" size="medium" value="Add Mapper" onClick={()=>{handleOpen({})}}>Add Mapper</Button>
                            </span>
                        </Grid>
                    </Grid></span>:""}


                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <div>
                                    <form key={2} className={classes.root}  >
                                        {fileType === 'CSV' ? <span> <RycomSectionHeader title="Header - Excel Column/Json Attribute"></RycomSectionHeader>
                                            <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                                <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                                                    <TextField
                                                        id="outlined-multiline-flexible"
                                                        label="Position"
                                                        name="position"
                                                        type="number"
                                                        variant="outlined"
                                                        style={{ width: 300 }}
                                                        // disabled={Object.keys(selectedMapper).length !== 0}
                                                        inputRef={register2({
                                                            required: {
                                                                value: true,
                                                                message: 'Column Position is required'
                                                            }
                                                        })}
                                                        defaultValue={selectedMapper && selectedMapper.postion ? selectedMapper.postion : ''}
                                                    />
                                                    <span>
                                                        <span className={classes.invalidMessage}>
                                                            {errors2['position'] && <span>{errors2['position'].message}</span>}
                                                        </span>
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                                                    <TextField
                                                        id="outlined-multiline-flexible"
                                                        label="CSV Column - Header"
                                                        name="key"
                                                        variant="outlined"
                                                        style={{ width: 300 }}
                                                        inputRef={register2({
                                                            required: {
                                                                value: true,
                                                                message: 'CSV Column Header is required'
                                                            }
                                                        })}
                                                        defaultValue={selectedMapper && selectedMapper.key ? selectedMapper.key : ''}
                                                        // value={mappedCsvKey}
                                                        onChange={mappedCsvKeyChange}
                                                    />
                                                    <span>
                                                        <span className={classes.invalidMessage}>
                                                            {errors2['key'] && <span>{errors2['key'].message}</span>}
                                                        </span>
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                                                    <TextField
                                                        id="targetColumnName"
                                                        label="Target Column Name"
                                                        name="mappedJsonKey"
                                                        variant="outlined"
                                                        style={{ width: 300 }}
                                                        inputRef={register2({
                                                            // required: {
                                                            //     value: true,
                                                            //     message: 'Column Header is required'
                                                            // }
                                                        })}
                                                        defaultValue={selectedMapper && selectedMapper.mappedJsonKey ? selectedMapper.mappedJsonKey : ''}
                                                        //  value={mappedJsonKey}
                                                        // defaultValue={mappedJsonKey && mappedJsonKey ? mappedJsonKey : ''}

                                                        onChange={mappedJsonKeyChange}
                                                    />
                                                    <span>
                                                        <span className={classes.invalidMessage}>
                                                            {errors2['targetColumnName'] && <span>{errors2['targetColumnName'].message}</span>}
                                                        </span>
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                                                    <span>
                                                        <Controller
                                                            as={
                                                                <TextField
                                                                    id="transformationType"
                                                                    select
                                                                    label="Transformation Type"
                                                                    variant="outlined"
                                                                    name="transformationType"
                                                                >
                                                                    <MenuItem key="0" value="">Select Transformation Type</MenuItem>
                                                                    <MenuItem key="1" value="CURRENCY">CURRENCY</MenuItem>
                                                                    <MenuItem key="2" value="DATE">DATE</MenuItem>
                                                                    <MenuItem key="3" value="BOOPROBABILITY_PERCENTAGELEAN">BOOLPROBABILITY PERCENTAGEEAN</MenuItem>
                                                                </TextField>
                                                            }
                                                            control={control2}
                                                            name="transformationType"
                                                            defaultValue={selectedMapper && selectedMapper.transformationType ? selectedMapper.transformationType : ''}
                                                            style={{ width: 300 }}
                                                            onChange={(event) => {
                                                                console.log(event[0].target.value);
                                                                setTransformationType(event[0].target.value)
                                                                if(event[0].target.value!='DATE'){
                                                                    setTransformationConfiguration({})
                                                                }
                                                                return event[0].target.value
                                                            }}
                                                        />
                                                        <span className={classes.invalidMessage}>
                                                            {errors2["transformationType"] && <span>{errors2["transformationType"].message}</span>}
                                                        </span>
                                                    </span>
                                                </Grid>
                                                { transformationType =='DATE'?<Grid item xs={12} sm={6} lg={3} xl={3} className={classes.rycomGridItem}>
                                                <span>
                                                        <Controller
                                                            as={
                                                                <TextField
                                                                    id="timeZone"
                                                                    select
                                                                    label="Time - Zone*"
                                                                    variant="outlined"
                                                                    name="timeZone"
                                                                >
                                                                <MenuItem value="">Select Time Zone</MenuItem>
                                                        {dataTimeZoneTypes.map((dataTimeZoneTypes1, index) => (
                                                            <MenuItem key={++index} value={dataTimeZoneTypes1}>
                                                                {dataTimeZoneTypes1}
                                                            </MenuItem>   ))}
                                                                
                                                                </TextField>
                                                            }
                                                            control={control2}
                                                            name="timeZone"
                                                            defaultValue={transformationConfiguration && transformationConfiguration.timeZone ? transformationConfiguration.timeZone : ''}
                                                            onChange={(event) => {
                                                                console.log(event[0].target.value);
                                                                setTransformationConfiguration({timeZone:event[0].target.value})
                                                                return event[0].target.value
                                                            }}
                                                            style={{ width: 300 }}
                                                        />
                                                        <span className={classes.invalidMessage}>
                                                            {errors2["timeZone"] && <span>{errors2["timeZone"].message}</span>}
                                                        </span>
                                                    </span>
                                                </Grid>:""}
                                               {primaryKeyEnabled?
                                               <>
                                                    <Grid item xs={12} sm={3} lg={3} xl={3} className={classes.rycomGridItem} style={{width:"300px",padding:"10px"}}>
                                                        <FormControlLabel
                                                            style={{ width: 300 }}
                                                            control={<Checkbox
                                                                checked={primaryKey} onChange={handlePrimaryKeyChange} name="flattening" />}
                                                            label="Primary Key"
                                                        />
                                                    </Grid>
                                                    { primaryKey && 
                                                        <Grid item xs={12} sm={3} lg={3} xl={3} className={classes.rycomGridItem}>
                                                            {/* <span> */}
                                                                <Controller
                                                                    as={
                                                                        <TextField
                                                                            id="valueType"
                                                                            select
                                                                            label="Value Type"
                                                                            variant="outlined"
                                                                            name="valueType"
                                                                            
                                                                        >
                                                                            <MenuItem key="1" value="STRING">STRING</MenuItem>
                                                                            <MenuItem key="2" value="NUMBER">NUMBER</MenuItem>
                                                                            <MenuItem key="3" value="BOOLEAN">BOOLEAN</MenuItem>
                                                                        </TextField>
                                                                    }
                                                                    rules={{
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Value Type is required'
                                                                        }
                                                                    }}
                                                                    control={control2}
                                                                    name="valueType"
                                                                    defaultValue={selectedMapper && selectedMapper.valuetype ? selectedMapper.valuetype : ''}
                                                                    style={{ width: 200 }}
                                                                />
                                                                <span className={classes.invalidMessage}>
                                                                    {errors2["valueType"] && <span>{errors2["valueType"].message}</span>}
                                                                </span>
                                                            {/* </span> */}
                                                        </Grid>
                                                    }
                                                
                                                </>
                                                :""}
                                                {primaryKey || !historicalEnabled ? "" :
                                                <Grid item xs={12} sm={6} lg={3} xl={3} style={{ width: 300, padding: 16 }} className={classes.rycomGridItem} >
                                                    <FormControlLabel
                                                        style={{ width: 300 }}
                                                        control={<Checkbox
                                                            checked={completeDateSet == true ? completeDateSet : maintainHistoryValue} onChange={handleMaintainHistoryValue} name="flattening" />}
                                                        label="Maintain Historical Record"
                                                        disabled={completeDateSet == true ? completeDateSet : false}
                                                    />
                                                </Grid>}
                                            </Grid>
                                            <RycomSectionHeader title="Metadata"></RycomSectionHeader>
                                            {metadataPopupList}
                                            <Grid container spacing={12} className={classes.rycomButtonGroup} >
                                                <Grid item xs={12} sm={4} lg={4} xl={4} >
                                                </Grid>
                                                <Grid item xs={12} sm={4} lg={4} xl={4} >
                                                </Grid>
                                                <Grid item xs={12} sm={4} lg={4} xl={4} >
                                                    <span>
                                                        <Button color="primary" variant="contained" size="medium" value="Add Edge" onClick={newMetadataPopupProfile}>Add Metadata</Button>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                                <Grid item xs={12} sm={6} lg={6} xl={6} className={classes.rycomGridItem}>
                                                    <div>  <TextField
                                                        id="outlined-multiline-flexible"
                                                        label="key"
                                                        name="metadataKey"
                                                        register={register2}
                                                        variant="outlined"
                                                        style={{ width: 300 }}
                                                        inputRef={register2({

                                                        })}
                                                        // defaultValue={selectedMapper && selectedMapper.metadata ? selectedMapper.metadata[0].key : ''}
                                                    />

                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={6} xl={6} className={classes.rycomGridItem}>
                                                    <span>  <TextField
                                                        id="outlined-multiline-flexible"
                                                        label="Value"
                                                        name="metadataValue"
                                                        style={{ width: 300 }}
                                                        variant="outlined"
                                                        inputRef={register2({

                                                        })}
                                                        // defaultValue={selectedMapper && selectedMapper.metadata ? selectedMapper.metadata[0].value : ''}
                                                    />
                                                    </span>
                                                </Grid>
                                            </Grid> */}
                                            <RycomSubmitModel>
                                                <Button type="reset" variant="" size="large"
                                                    onClick={() => {
                                                        reset2();
                                                    }}>Clear</Button>
                                                <Button type color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit2(handleMapperSubmit)}>Submit</Button>
                                            </RycomSubmitModel>
                                        </span> : JsonMapperFormContent}
                                    </form >
                                </div>
                            </div>
                        </Fade>
                    </Modal>



                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openConnector}
                        onClose={handleCloseConnector}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openConnector}>
                            <div className={classes.paper}>
                                <div>
                                    <form key={2} className={classes.root}  >
                                        {loading && <ProgressBar percentage={progress} />}
                                        <span> <RycomSectionHeader title="Connector - Mapper Auto Generate form"></RycomSectionHeader>
                                            <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                                <Grid item xs={12} sm={6} lg={6} xl={6} className={classes.rycomGridItem}>
                                                    <RycomSelectField id="connectorId"
                                                        label="Connector"
                                                        name="connectorId"
                                                        onChangeHandler={(event) => {
                                                            return event[0].target.value;
                                                        }}

                                                        register={register2}
                                                        control={control2}
                                                        errors={errors2}
                                                        errorDescription={{
                                                            required: {
                                                                value: true,
                                                                message: 'Connector is required'
                                                            }
                                                        }}
                                                        >
                                                        {connectorMenuList}
                                                    </RycomSelectField>
                                                </Grid>
                                                {fileType !== 'CSV' ? 
                                                    <Grid item xs={12} sm={6} lg={6} xl={6} className={classes.rycomGridItem}>
                                                        <RycomSelectField id="flatteningLevel"
                                                            label="Json Level"
                                                            name="flatteningLevel"
                                                            onChangeHandler={(event) => {
                                                                return event[0].target.value;

                                                            }}
                                                            register={register2}
                                                            control={control2}
                                                            errors={errors2}
                                                            errorDescription={{
                                                                required: {
                                                                    value: true,
                                                                    message: 'Json Level is required'
                                                                }
                                                            }}
                                                            >
                                                            <MenuItem key="1" value="complete"> No Flattening</MenuItem>
                                                            <MenuItem key="2" value="firstLevel">Flattening-First Level Array or Object </MenuItem>
                                                        </RycomSelectField>
                                                    </Grid>
                                                : "" }
                                            </Grid>
                                                   

                                            <RycomSubmitModel>
                                                <Button type="reset" variant="" size="large"
                                                    onClick={() => {
                                                        reset2();
                                                    }}>Clear</Button>
                                                <Button type color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit2(handleAutoGenerateConnectorSubmit)}>Submit</Button>
                                            </RycomSubmitModel>
                                        </span>
                                    </form >
                                </div>
                            </div>
                        </Fade>
                    </Modal>


                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openSample}
                        onClose={handleCloseSample}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openSample}>
                            <div className={classes.paper}>
                                <div>
                                    <form key={2} className={classes.root}  >
                                        <span> <RycomSectionHeader title="Sample Payload - Mapper Auto Generate form"></RycomSectionHeader>
                                            <Grid container spacing={12} style={{ padding: 4 }} className={classes.rycomGridItem}>
                                                <Grid item xs={12} sm={12} lg={12} xl={12} className={classes.rycomGridItem}>

                                                <TextareaAutosize
                                                aria-label="maximum height"
                                                placeholder="Paste the Sample Payload here"
                                                style={{ width: 450, height:300 }}
                                                minRows={3}
                                                maxRows={40}
                                                value={samplePayload}
                                                onChange={(event)=>{
                                                    setSamplePayload(event.target.value);
                                                }}
                                                />
                           
                                                  
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={6} xl={6} className={classes.rycomGridItem}>
                                                    <RycomSelectField id="flatteningLevel"
                                                        label="Json Level"
                                                        name="flatteningLevel"
                                                        onChangeHandler={(event) => {
                                                            return event[0].target.value;

                                                        }}
                                                        register={register2}
                                                        control={control2}
                                                        errors={errors2}
                                                        errorDescription={{
                                                            required: {
                                                                value: true,
                                                                message: 'Json Level is required'
                                                            }
                                                        }}
                                                        >
                                                          <MenuItem key="1" value="complete"> No Flattening</MenuItem>
                                                        <MenuItem key="2" value="firstLevel">Flattening-First Level Array or Object </MenuItem>
                                              </RycomSelectField>
                                                </Grid>
                                            </Grid>
                                            <RycomSubmitModel>
                                                <Button type="reset" variant="" size="large"
                                                    onClick={() => {
                                                        reset2();
                                                    }}>Clear</Button>

                                                <Button type color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit2(handleAutoGenerateSampleSubmit)}>Submit</Button>
                                            </RycomSubmitModel>
                                        </span>
                                    </form >
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </div>
                <RycomSubmitModel style={{marginLeft: 10}}>

                     <Button style={{marginLeft: 10}} color="primary"  variant="contained" 
                     size="medium" value="Review" onClick={reviewDatamapperOutput}>Review</Button>


                    <Button style={{marginLeft: 10,backgroundColor:'#f50057'}}  type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
