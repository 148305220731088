import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'


import { useParams } from 'react-router-dom'


export default function SchedulerDetail() {



    
    let { id } = useParams();

    const taskValues = 
        { 
            "taskname": "Realestate-Data",
            "taskdescription":"This is realestate sales data",
            "isActive":true,
            "createdate":1582338051,
            "parentcompanyid": "0",
            "companyid": "0",
            "companyName": "Peter 134",
            "venueName":"venue 123",
            "deviceid": "Device Name123",
            "deviceName": "0",
            "schedulerid":"0",
            "schedulerName":"Every Week Scheduler",
            "connectionName":"Peter 134 FTP Connector",
            "connectionid":"0",
            "datamapperid":"5e5aceef01b79e7967dfde50",
            "datamapperName":"",
            "createdbyuserid":"0"
           }

    return (<div>
        <RycomPageHeader title={taskValues ? `Scheduler Details: ${taskValues.taskname}` : 'Task Details'} links={[{ title: 'Back', url: '/topics/scheduler' }]}></RycomPageHeader>
        <RycomSectionHeader title="Task Status: Running"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task" value={taskValues ? taskValues.taskname : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Task Description" value={taskValues ? taskValues.taskdescription : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Parent Organisation" value={taskValues ? taskValues.parentcompanyid : ''}></RycomInfoPair>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label=" Organisation" value={taskValues ? taskValues.companyName : ''}></RycomInfoPair>
            </Grid>
       
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Venue Name" value={taskValues ? taskValues.venueName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Device Name" value={taskValues ? taskValues.deviceName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Connection Name" value={taskValues ? taskValues.connectionName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Data Mapper" value={taskValues ? taskValues.datamapperName : ''}></RycomInfoPair>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <RycomInfoPair label="Scheduler Name" value={taskValues ? taskValues.schedulerName: ''}></RycomInfoPair>
                </Grid>
            </Grid>
            </div>
      );
    }
