import React, { Component } from 'react'
import NavBar from './../components/NavBar'
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom'
import RycomSidebar from './../components/sidebar/RycomSidebar'

import { useEffect, useState } from 'react'

import LoginForm from './../components/login/LoginForm'


import DataAnalystDashboard from './../components/dashboard/DataAnalystDashboard'

import DataMapperList from './../components/dataServices/dataMapper/DataMapperList'
import DataMapperDetail from './../components/dataServices/dataMapper/DataMapperDetail'
import DataMapperForm from './../components/dataServices/dataMapper/DataMapperForm'
import DataMapperEditForm from './../components/dataServices/dataMapper/DataMapperEditForm'

import VertexEditForm from './../components/dataServices/graph/vertex/VertexEditForm'
import VertexList from './../components/dataServices/graph/vertex/VertexList'
import VertexForm from './../components/dataServices/graph/vertex/VertexForm'
import VertexDetail from './../components/dataServices/graph/vertex/VertexDetail'

import DocumentVertexList from './../components/dataServices/documentgraph/vertex/DocumentVertexList'
import DocumentVertexForm from './../components/dataServices/documentgraph/vertex/DocumentVertexForm'
import DocumentVertexDetail from './../components/dataServices/documentgraph/vertex/DocumentVertexDetail'
import DocumentEditVertexForm from './../components/dataServices/documentgraph/vertex/DocumentVertexEditForm'


import DocumentEdgeDetail from './../components/dataServices/documentgraph/edge/DocumentEdgeDetail'
import DocumentEdgeList from './../components/dataServices/documentgraph/edge/DocumentEdgeList'
import DocumentEdgeForm from './../components/dataServices/documentgraph/edge/DocumentEdgeForm'
import DocumentEdgeEditForm from './../components/dataServices/documentgraph/edge/DocumentEdgeEditForm'


import EdgeEditForm from './../components/dataServices/graph/edge/EdgeEditForm'
import EdgeDetail from './../components/dataServices/graph/edge/EdgeDetail'
import EdgeList from './../components/dataServices/graph/edge/EdgeList'
import EdgeForm from './../components/dataServices/graph/edge/EdgeForm'

import RelationDetail from './../components/dataServices/graph/relation/RelationDetail'
import RelationList from './../components/dataServices/graph/relation/RelationList'
import RelationForm from './../components/dataServices/graph/relation/RelationForm'

import DocumentRelationDetail from '../components/dataServices/documentgraph/relation/DocumentRelationDetail'
import DocumentRelationList from './../components/dataServices/documentgraph/relation/DocumentRelationList'
import DocumentRelationForm from './../components/dataServices/documentgraph/relation/DocumentRelationForm'


import { PrivateRoute } from './../auth/PrivateRoute';


import SchedulerList from './../components/scheduler/SchedulerList'
import SchedulerForm from './../components/scheduler/SchedulerForm'
import SchedulerDetail from './../components/scheduler/SchedulerDetail'

import ConnectorList from './../components/connectors/ConnectorList'
import ConnectorForm from './../components/connectors/ConnectorForm'
import ConnectorEditForm from './../components/connectors/ConnectorEditForm'

import ConnectorDetail from './../components/connectors/ConnectorDetail'

import UserList from './../components/org/user/UserList'
import UserForm from './../components/org/user/UserForm'
import UserDetail from './../components/org/user/UserDetail'
import UserEditForm from './../components/org/user/UserEditForm'
import UserToken from './../components/org/user/UserToken'

import UserSelfDetail from './../components/org/user/UserSelfDetail'
import UserSelfEditForm from './../components/org/user/UserSelfEditForm'

import TaskEdit from './../components/task/TaskEdit'
import TaskList from './../components/task/TaskList'
import TaskForm from './../components/task/TaskForm'
import TaskDetail from './../components/task/TaskDetail'
import TaskStatusDetail from './../components/task/TaskStatusDetail'



import TaskEdgeEdit from './../components/task/TaskEdgeEdit'
import TaskEdgeForm from './../components/task/TaskEdgeForm'
import TaskEdgeList from './../components/task/TaskEdgeList'
import TaskEdgeDetail from './../components/task/TaskEdgeDetail'


import OrganisationVenueDetail from './../components/org/venue/OrganisationVenueDetail'
import OrganisationVenueList from './../components/org/venue/OrganisationVenueList'
import OrganisationVenueForm from './../components/org/venue/OrganisationVenueForm'

import OrganisationZoneDetail from './../components/org/zone/OrganisationZoneDetail'
import OrganisationZoneList from './../components/org/zone/OrganisationZoneList'
import OrganisationZoneForm from './../components/org/zone/OrganisationZoneForm'

import DeviceDetail from './../components/org/device/DeviceDetail'
import DeviceList from './../components/org/device/DeviceList'
import DeviceForm from './../components/org/device/DeviceForm'

import OrganisationForm from './../components/org/company/OrganisationForm'
import OrganisationDetail from './../components/org/company/OrganisationDetail'
import OrganisationList from './../components/org/company/OrganisationList'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useKeycloak } from '@react-keycloak/web';
import { withKeycloak } from '@react-keycloak/web';


export default function RycomRouter(props) {
  const themeLight = createMuiTheme({
    palette: {
      background: {
        default: "white"
      }
    }
  });


  return (
    <MuiThemeProvider theme={themeLight}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/">
            <Topics />
          </Route>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}


function Topics(){

  const [menuWidth, setMenuWidth] = useState('70px');
  const onMenuFocus = () => {
    setMenuWidth('240px')
  }

  const onMenuOutOfFocus = () => {
    setMenuWidth('70px')
  }

  const menuStyle = function (options) {
    return {
      marginLeft: menuWidth,
    }
  }

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    }

  }));


  const classes = useStyles();

  const styleValue = menuStyle();
  let { path, url } = useRouteMatch();
 return (
   <div>
    <NavBar />
    <RycomSidebar focus={onMenuFocus} outOfFocus={onMenuOutOfFocus} />
    <div style={styleValue}>
      <Switch >

        <PrivateRoute roles={['view:scheduler']} path={`/topics/scheduler/detail/:id`} component={SchedulerDetail}></PrivateRoute>
        <PrivateRoute roles={['create:scheduler']} path={`/topics/scheduler/form`} component={SchedulerForm}></PrivateRoute>
        <PrivateRoute roles={['view:scheduler']} path={`/topics/scheduler`} component={SchedulerList} exact></PrivateRoute>
       
        <PrivateRoute roles={['update:task']} path={`/topics/task/edit/:id`} component={TaskEdit}></PrivateRoute>
        <PrivateRoute roles={['view:task']} path={`/topics/task/detail/:id`} component={TaskDetail}></PrivateRoute>
        <PrivateRoute roles={['view:task']} path={`/topics/task/status/:id`} component={TaskStatusDetail}></PrivateRoute>

        <PrivateRoute roles={['create:task']} path={`/topics/task/form`} component={TaskForm}></PrivateRoute>
        <PrivateRoute roles={['view:task']} path={`/topics/task`} component={TaskList} exact></PrivateRoute>


        <PrivateRoute roles={['update:task']} path={`/topics/edgetask/edit/:id`} component={TaskEdgeEdit}></PrivateRoute>
        <PrivateRoute roles={['view:task']} path={`/topics/edgetask/detail/:id`} component={TaskEdgeDetail}></PrivateRoute>
        <PrivateRoute roles={['create:task']} path={`/topics/edgetask/form`} component={TaskEdgeForm}></PrivateRoute>
        <PrivateRoute roles={['view:task']} path={`/topics/edgetask`} component={TaskEdgeList} exact></PrivateRoute>

        <PrivateRoute roles={['update:graph']} path={`/topics/edge/edit/:id`} component={EdgeEditForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/edge/detail/:id`} component={EdgeDetail}></PrivateRoute>
        <PrivateRoute roles={['create:graph']} path={`/topics/edge/form`} component={EdgeForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/edge`} component={EdgeList} exact></PrivateRoute>

        <PrivateRoute roles={['update:graph']} path={`/topics/relation/detail/:id`} component={RelationDetail}></PrivateRoute>
        <PrivateRoute roles={['create:graph']} path={`/topics/relation/form`} component={RelationForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/relation`}  component={RelationList} exact></PrivateRoute>

        <PrivateRoute roles={['update:graph']} path={`/topics/vertex/edit/:id`} component={VertexEditForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/vertex/detail/:id`}  component={VertexDetail}></PrivateRoute>
        <PrivateRoute roles={['create:graph']} path={`/topics/vertex/form`} component={VertexForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/vertex`}  component={VertexList} exact></PrivateRoute>

        <PrivateRoute roles={['update:graph']} path={`/topics/documentrelation/detail/:id`} component={DocumentRelationDetail}></PrivateRoute>
        <PrivateRoute roles={['create:graph']} path={`/topics/documentrelation/form`} component={DocumentRelationForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/documentrelation`}  component={DocumentRelationList} exact></PrivateRoute>


        <PrivateRoute roles={['update:graph']} path={`/topics/documentedge/edit/:id`} component={DocumentEdgeEditForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/documentedge/detail/:id`}  component={DocumentEdgeDetail}></PrivateRoute>
        <PrivateRoute roles={['create:graph']} path={`/topics/documentedge/form`} component={DocumentEdgeForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/documentedge`}  component={DocumentEdgeList} exact></PrivateRoute>
     
     
        
        <PrivateRoute roles={['update:graph']} path={`/topics/documentvertex/edit/:id`} component={DocumentEditVertexForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/documentvertex/detail/:id`}  component={DocumentVertexDetail}></PrivateRoute>
        <PrivateRoute roles={['create:graph']} path={`/topics/documentvertex/form`} component={DocumentVertexForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/documentvertex`}  component={DocumentVertexList} exact></PrivateRoute>



        <PrivateRoute roles={['update:graph']} path={`/topics/datamapper/edit/:id`} component={DataMapperEditForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/datamapper/detail/:id`} component={DataMapperDetail}></PrivateRoute>
        <PrivateRoute roles={['create:graph']} path={`/topics/datamapper/form`} component={DataMapperForm}></PrivateRoute>
        <PrivateRoute roles={['view:graph']} path={`/topics/datamapper`} component={DataMapperList} exact></PrivateRoute>

        <PrivateRoute roles={['create:org']} path={`/topics/user/form`} component={UserForm} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/user/detail/:id`} component={UserDetail} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/user`} component={UserList} exact></PrivateRoute>
        <PrivateRoute roles={['update:org']} path={`/topics/user/edit/:id`} component={UserEditForm}></PrivateRoute>
        
        <PrivateRoute roles={['view:org']} path={`/topics/user/token/:id`} component={UserToken} exact></PrivateRoute>

        <PrivateRoute roles={['view:org']} path={`/topics/user/selfdetail/:id`} component={UserSelfDetail} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/user/selfedit/:id`} component={UserSelfEditForm}></PrivateRoute>

        <PrivateRoute roles={['create:org']} path={`/topics/company/form`} component={OrganisationForm} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company/detail/:id`} component={OrganisationDetail} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company`} component={OrganisationList} exact></PrivateRoute>

        <PrivateRoute roles={['create:org']} path={`/topics/company/zone/form`} component={OrganisationZoneForm} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company/zone/detail/:id`}  component={OrganisationZoneDetail} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company/zone`}  component={OrganisationZoneList} exact></PrivateRoute>

        <PrivateRoute roles={['create:org']} path={`/topics/company/venue/form`} component={OrganisationVenueForm} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company/venue/detail/:id`}  component={OrganisationVenueDetail} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company/venue`} component={OrganisationVenueList} exact></PrivateRoute>

        <PrivateRoute roles={['create:org']} path={`/topics/company/device/form`} component={DeviceForm} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company/device/detail/:id`} component={DeviceDetail} exact></PrivateRoute>
        <PrivateRoute roles={['view:org']} path={`/topics/company/device`} component={DeviceList} exact></PrivateRoute>

        <PrivateRoute roles={['update:connector']} path={`/topics/connector/edit/:id`} component={ConnectorEditForm}></PrivateRoute>
        <PrivateRoute roles={['view:connector']} path={`/topics/connector/detail/:id`} component={ConnectorDetail}></PrivateRoute>
        <PrivateRoute roles={['create:connector']} path={`/topics/connector/form`} component={ConnectorForm}></PrivateRoute>
        <PrivateRoute roles={['view:connector']} path={`/topics/connector`} component={ConnectorList} ></PrivateRoute>

        <Route  path={`/`} component={DataAnalystDashboard}></Route>
         </Switch>
      </div>
      </div>);
  
}