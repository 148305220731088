import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const saveDocumentVertex = (vertexPayload) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/documentvertex`,
          vertexPayload, addBearerToken()
    );
}

export const updateDocumentVertex = (vertexPayload) => {
        return axios.patch(`${environment.dataBaseUrl}/api/dms/documentvertex`,
          vertexPayload, addBearerToken()
    );
}

export const deleteDocumentVertex = (id) => {
    return axios.patch(`${environment.dataBaseUrl}/api/dms/documentvertex/delete/${id}`,{}, addBearerToken());
}


export const getAllVertex = (companyId) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/documentvertex/parentcompanyvertex/${companyId}`, addBearerToken());
}

export const getVertexById = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/documentvertex/${id}`, addBearerToken());
}
