import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RycomInfoPair from '../common/RycomInfoPair'
import Grid from '@material-ui/core/Grid';
import * as ConnectorService from '../../services/ConnectorService';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import ConnectorSFTPDetail from './ConnectorSFTPDetail';
import ConnectorFTPDetail from './ConnectorFTPDetail';
import ConnectorApiDetail from './ConnectorApiDetail';
import ConnectorJDBCDetail from './ConnectorJDBCDetail';


import { useParams } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useHistory } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';


const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    details: {
      flexDirection: 'column',
    },
  
    aocard: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'color-white',
      border: '1px solid ui-border-color-base',
      borderRadius: 'border-radius-base',
      marginBottom: 'spacer',
      padding: 0
     },
     
     title: {
        margin: 0,
       fontSize: 'xx-large',
       display: 'inline-block',
       paddingRight: '10px',
      },
  
     header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '5px solid $ui-border-color-base'
      },
  
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end'
      },  
  
      buttonStyle: {
        paddingRight: '5px',
      }
  
  }));
  


export default function ConnectorDetail() {

    let { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [keycloak] = useKeycloak();

    const [connectorInfo, setConnectorInfo] = React.useState(null)

    useEffect(() => {
        console.log(id)
        ConnectorService
            .getConnectorById(id)
            .then((res) => {
                if (res && res.data && res.data) {
                    setConnectorInfo(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    }, [])

    const createDeleteButton = () => {
        const DeleteButton = <Button className={classes.buttonStyle} color="secondary" variant="contained" size="medium" value="Trigger Task"
            onClick={(e) => {
                if (window.confirm('Are you sure you wish to delete this item?')) 
                {
                    ConnectorService.deleteConnector(id).then((res) => {
                        if (res && res.data && res.data) {
                            console.log(res.data);
                            alert(res.data.message)
                            history.push("/topics/connector");
                        }
                    })
                        .catch((error) => {
                            alert('some error in deleting the Edge')
                            console.error('delete vertex =>', error)
                        })
                }
            }}>Delete</Button>
        return DeleteButton;
    }
    const connectorType = connectorInfo ? connectorInfo.connectionInfo.type : undefined;
    let connectorDetail = null;
    if (connectorType !== undefined &&
        (connectorType === 'sftp' || connectorType === 'sftprecursive')) {
        connectorDetail = <ConnectorSFTPDetail connectorInfo={connectorInfo} />
    } else if (connectorType !== undefined &&
        (connectorType === 'ftp' || connectorType === 'ftprecrusive' ||
            connectorType === 'ftps' || connectorType === 'ftpsrecrusive')) {
        connectorDetail = <ConnectorFTPDetail connectorInfo={connectorInfo} />
    } else if (connectorType !== undefined &&
        (connectorType === 'api')) {
        connectorDetail = <ConnectorApiDetail connectorInfo={connectorInfo} />
    }
    else if (connectorType !== undefined &&
        (connectorType === 'JDBC' || connectorType=='ORIENT')) {
        connectorDetail = <ConnectorJDBCDetail connectorInfo={connectorInfo} />
    }

    

    const buttonLinks=<span><Link className={classes.buttonStyle} to="/topics/connector"><Button
    color="primary" 
variant="contained" size="medium">
    Back</Button></Link>
    {keycloak.hasRealmRole("update:connector")?<Link className={classes.buttonStyle} to={'/topics/connector/edit/'+id}><Button
                color="primary" 
            variant="contained" size="medium">
    Edit</Button>
    </Link>:""} 
    {keycloak.hasRealmRole("delete:connector")?<span className={classes.buttonStyle} to="/topics/connector">
        {createDeleteButton()}
    </span>:""} 
     <Link className={classes.buttonStyle} to="/topics">
    <Button
        color="primary" 
        variant="contained" size="medium">
            Home
    </Button></Link></span>;


 


    return (<div>
        
        <Card className={classes.aocard}>
    <div className={classes.header}>
    <span className={classes.title} >
    {connectorInfo ? 
        `Connector Details about ${connectorInfo.connectorName}` : 'Connector Details'} </span>
    <span className={classes.toolbar}>
    {buttonLinks}
    </span>
    </div>
    </Card>
        <RycomSectionHeader title="General Info"></RycomSectionHeader>
        <Grid container spacing={12} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={4} xl={2}>
                <RycomInfoPair label="Connector Type" value={connectorInfo ? connectorInfo.connectionInfo.type : ''}></RycomInfoPair>
            </Grid>
        </Grid>
        {connectorDetail}
    </div>
    );
}
