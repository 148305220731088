import React from 'react';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomTextField from '../common/RycomTextField'
import RycomSelectField from '../common/RycomSelectField'
import MenuItem from '@material-ui/core/MenuItem';
import { RycomGrid, RycomGridItem } from '../common/RycomGrid'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(theme => ({
  invalidMessage: {
      display: 'inline-block',
      fontSize: '12px',
      color: 'red',
      paddingLeft: '12px',
    }
  
}));




export default function ConnectorSFTPConfiguration(props) {

  const classes = useStyles();
  const { connectorType, register, errors, control } = props
  console.log("inside ConnectorSFTPConfiguration")
  console.log(props)
  const suffix = 'recursive'
  const pathLabel = (connectorType !== undefined &&
    connectorType.substr(-suffix.length) === suffix) ? 'Absoloute Directory Path' : 'Absolute File Path';
  return (
    <div>
      <RycomSectionHeader title="Server and Connection Information" />
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id="User Name"
            label="User Name"
            name="userName"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'User Name is required'
              }
            }} />
        </RycomGridItem>
        <RycomGridItem>
        <TextField
                id="outlined-multiline-flexible"
                label="Password*"
                variant="outlined"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'password is required'
                  }})}
                name="password"
                type="password"/>
               <span className={classes.invalidMessage}>
                {errors['password'] && <span>{errors['password'].message}</span>}
            </span>
        </RycomGridItem>
        <RycomGridItem>
          <RycomTextField id="Host"
            label="host"
            name="host"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Host is required'
              }
            }} />
        </RycomGridItem>
      </RycomGrid>
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id="Port"
            label="Port"
            name="port"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Port is required'
              }
            }} />
        </RycomGridItem>
        <RycomGridItem>
        <TextField
                id="outlined-multiline-flexible"
                label="PassPhrase"
                variant="outlined"
                inputRef={register({
                 })}
                name="passPhrase"
                type="password"/>
               <span className={classes.invalidMessage}>
                {errors['passPhrase'] && <span>{errors['passPhrase'].message}</span>}
            </span>
        </RycomGridItem>
        <RycomGridItem>
          <RycomSelectField id="Public Key Authentication"
            label="Public Key Authentication"
            id="publicKeyAuthenticaiton"
            name="publicKeyAuthenticaiton"
             onChangeHandler={(event) => {
              return event[0].target.value
            }}
            register={register}
            control={control}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: 'Public Key Authentication is required'
              }
            }} >
            <MenuItem key="1" value="true">TRUE</MenuItem>
            <MenuItem key="2" value="false">FALSE</MenuItem>
          </RycomSelectField>
        </RycomGridItem>
      </RycomGrid>
      <RycomGrid>
        <RycomGridItem>
          <RycomTextField id={pathLabel}
            label={pathLabel}
            name="filePath"
            register={register}
            errors={errors}
            errorDescription={{
              required: {
                value: true,
                message: pathLabel + ' is required'
              }
            }} />
        </RycomGridItem>
      </RycomGrid>
    </div>
  );
}
