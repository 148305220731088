import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import RycomSectionHeader from '../common/RycomSectionHeader'
import RycomPageHeader from '../common/RycomPageHeader'
import RycomSelectField from '../common/RycomSelectField'
import RycomTextField from '../common/RycomTextField'
import { RycomGridItem, RycomGrid } from '../common/RycomGrid'
import RycomSubmitModel from '../common/RycomSubmitModel'

import * as OrganisationService from '../../services/OrganisationService';
import * as VenueService from '../../services/VenueService';
import * as DeviceService from '../../services/DeviceService';
import * as RelationService from '../../services/RelationService';
import * as DocumentRelationService from '../../services/DocumentRelationService';
import * as DocumentClassService from '../../services/DocumentClassService';


import * as ConnectorService from '../../services/ConnectorService';
import * as DataMapperService from '../../services/DataMapperService';
import * as SchedulerService from '../../services/SchedulerService';
import * as TaskService from '../../services/TaskService';

import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import environment from '../../environment'

const connectorTypes = [
    "sftp",
    "sftprecursive",
    "ftp",
    "ftprecrusive",
    "ftps",
    "ftpsrecrusive",
    "API",
    "JDBC",
    "ORIENT"
];

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '50ch',
        },
    }


}));

export default function TaskForm() {

    const history = useHistory();

    /*
    const defaultValues =  { 
            "baserelationshipid": "string",
            "companyid": "string",
            "connectionid": "string",
            "createdbyuserid": "string",
            "datamapperid": "string",
            "datecreated": 0,
            "deviceid": "string",
            "isActive": true,
            "parentcompanyid": "string",
            "schedulerid": "string",
            "taskdescription": "string",
            "taskid": "string",
            "taskname": "string",
            "venueid": "string"
        };*/

    const defaultValues = {
    };
    const classes = useStyles();
    const [companyId, setCompanyId] = useState(undefined);
    const { register, errors, handleSubmit, reset, watch, control } = useForm({ defaultValues });
    const [companyList, setCompanyList] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [schedulerList, setSchedulerList] = useState([]);
    const [connectorList, setConnectorList] = useState([]);
    const [connectorFilteredInboundList, setConnectorFilteredInboundList] = useState([]);
    const [connectorFilteredOutboundList, setConnectorFilteredOutboundList] = useState([]);
    const [dataMapperList, setDataMapperList] = useState([]);
    const [dataFilteredMapperList, setDataFilteredMapperList] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const userInfoJson = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(userInfoJson);
    const parentcompanyid = userInfo['parentcompanyid'];
    const companyid = userInfo['companyid'];
    const paramID = parentcompanyid > 0 ? parentcompanyid : companyid;
    const [taskType, setTaskType] = useState();
    const [documentRelationList, setDocumentRelationList] = useState([]);
    const [documentClassList, setDocumentClassList] = useState([]);
    const [taskStorageType, setTaskStorageType] = useState();

    

    useEffect(() => {//
        if (companyList.length == 0) {
            //OrganisationService.getCompanyByParentId(parentcompanyid)
            OrganisationService.getCompanyParentById(companyid)
                .then((res) => {
                    if (res && res.data && res.data) {
                        setCompanyList(res.data);
                    }
                })
                .catch((error) => {
                    alert(error)
                    console.error('getConnector =>', error)
                })
        }
        getDatamapperList(parentcompanyid);
        getRelationList(parentcompanyid);
        getConnectorList(parentcompanyid);
        getSchedulerList(parentcompanyid)
        getDocumentRelationList(parentcompanyid);
        getDocumentClassList(parentcompanyid);
    }, []);

    const getVenueList = (event) => {
        setCompanyId(event.target.value);

        // const pID = companyList.filter(e=>e.companyid==event.target.value).map(e=> e.parentcompanyid);
        // if(pID != 0){
        VenueService.getVenueByCompanyId(event.target.value)
            .then((res) => {
                if (res && res.data && res.data) {
                    setVenueList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
        // } else{
        //     VenueService.getVenueByParentCompanyId(paramID)
        //         .then((res) => {
        //             if (res && res.data && res.data) {
        //                 setVenueList(res.data);
        //             }
        //         })
        //         .catch((error) => {
        //             console.error('getConnector =>', error)
        //         })   
        // } 

        return event.target.value
    };

    const getDeviceList = (event) => {
        const venueId = event.target.value;
        DeviceService.getDeviceByVenueId(venueId)
            .then((res) => {
                if (res && res.data && res.data) {
                    setDeviceList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
        return event.target.value
    };



    const getDocumentRelationList = (companyId) => {
        DocumentRelationService.getAllDocumentRelation(companyId)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log("Relation documwnt")
                    console.log(res.data)
                    setDocumentRelationList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };



    const getDocumentClassList = (companyId) => {
        //DocumentClassService.getAllVertex(companyId)
        DocumentClassService.getAllVertex(paramID)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log("Relation documwnt")
                    console.log(res.data)
                    setDocumentClassList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };

    const getRelationList = (companyId) => {
        //RelationService.getAllRelation(parentcompanyid)
        RelationService.getAllRelation(paramID)
            .then((res) => {
                if (res && res.data && res.data) {
                    setRelationList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };

    const getConnectorList = () => {
        ConnectorService.getAllConnector()
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res.data[0]);
                    setConnectorList(res.data[0].body);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };

    const getDatamapperList = () => {
        //DataMapperService..getDataMapperByCompanyIdForTask(parentcompanyid)
        DataMapperService.getDataMapperByCompanyIdForTask(paramID)
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res);
                    setDataMapperList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };

    const getSchedulerList = () => {
        SchedulerService.getAllScheduler()
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res);
                    setSchedulerList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };

    const getList = () => {
        SchedulerService.getAllScheduler()
            .then((res) => {
                if (res && res.data && res.data) {
                    console.log(res);
                    setSchedulerList(res.data);
                }
            })
            .catch((error) => {
                console.error('getConnector =>', error)
            })
    };

    // Filter the datamapper as per the data types
    const filterDataMapperList = (fileType) => {

        let  fileTypeUC = fileType.toUpperCase()

        let data = dataMapperList.filter((item) => item.datainfo.filetype === fileTypeUC);

        setDataFilteredMapperList(data);
        
    }

    // Filter the inbound connectors using connector types
    const filterInboundConnectors = (connectorType) => {

        // Inbound connector list
        const connectorInboundList=connectorList.filter(connector=>{
            console.log("inside the connectorInboundList "+connector.connectionInfo.dataflow+" : "+(connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound'));
            return (connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound') && connector.connectionInfo.type.toLowerCase() == connectorType.toLowerCase();
        });

        setConnectorFilteredInboundList(connectorInboundList);

    }
   
    // Filter the outbout connectors using connector types
    const filterOutboundConnectors = (connectorType) => {

        // outbound connector list
        const connectorOutboundList=connectorList.filter(connector=>{
            console.log("inside the connectorOutboundList "+connector.connectionInfo.dataflow+" : "+(connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound'));
            return (connector.connectionInfo.dataflow!=null && connector.connectionInfo.dataflow=='outbound') && connector.connectionInfo.type.toLowerCase() == connectorType.toLowerCase();
        });

        setConnectorFilteredOutboundList(connectorOutboundList);

    }


    const companyMenuList = companyList.map((company, index) => <MenuItem key={++index} value={company.companyid}> {company.companyname}</MenuItem>);
    const venueMenuList = venueList.map((venue, index) => <MenuItem key={++index} value={venue.venueid}> {venue.venuename}</MenuItem>);
    const deviceMenuList = deviceList.map((device, index) => <MenuItem key={++index} value={device.deviceid}> {device.devicename}</MenuItem>);
    const relationMenuList = relationList.map((relation, index) => <MenuItem key={++index} value={relation.relationshipid}> {relation.rname}</MenuItem>);
    const documentRelationMenuList = documentRelationList.map((relation, index) => <MenuItem key={++index} value={relation.documentRelationId}> {relation.documentRelationName}</MenuItem>);
    const documentClassMenuList = documentClassList.map((relation, index) => <MenuItem key={++index} value={relation.classId}> {relation.className}</MenuItem>);
    const dataMapperMenuList = dataFilteredMapperList.map((dataMapper, index) => <MenuItem key={++index} value={dataMapper.datamapperid}> {dataMapper.datamappername}</MenuItem>);
    console.log("connectorList")
    console.log(connectorList)
    const connectorMenuList = connectorList.map((connector, index) => <MenuItem key={++index} value={connector.id}> {connector.connectorName}</MenuItem>);
    const schedulerMenuList = schedulerList.map((scheduler, index) => <MenuItem key={++index} value={scheduler.id}> {scheduler.schedulerName}</MenuItem>);



    // const connectorInboundList=connectorList.filter(connector=>{
    //     console.log("inside the connectorInboundList "+connector.connectionInfo.dataflow+" : "+(connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound'));
    //     return connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound';
    // });
    // const connectorOutboundList=connectorList.filter(connector=>{
    //     console.log("inside the connectorOutboundList "+connector.connectionInfo.dataflow+" : "+(connector.connectionInfo.dataflow==null || connector.connectionInfo.dataflow=='inbound'));
    //     return connector.connectionInfo.dataflow!=null && connector.connectionInfo.dataflow=='outbound';
    // });

    const connectorInboundMenuList = connectorFilteredInboundList.map((connector, index) => <MenuItem key={++index} value={connector.id}> {connector.connectorName}</MenuItem>);
    const connectorOutboundMenuList = connectorFilteredOutboundList.map((connector, index) => <MenuItem key={++index} value={connector.id}> {connector.connectorName}</MenuItem>);



    const onSubmit = data => {
        console.log("data 1");
        console.log(data);
        //data['parentcompanyid']=parentcompanyid;
        data['parentcompanyid'] = paramID;
        data['vertexTypeId'] = data.documentVertexType;
        //  "taskType":data.taskType,
        //  "documentVertexType":data.documentVertexType

        TaskService.saveVertex(data).then((res) => {
            console.log(res)
            if (res.status === 200)
                alert('Task inserted successfully');
            history.push("/topics/task")
        })
            .catch((error) => {
                alert("Task error")
                console.error('Task =>', error)
            })
    }


    return (
        <div>
            <form className={classes.root}  >
                <RycomPageHeader title="Enter the Task Details"
                    links={[{ title: 'Back', url: '/topics/task' }]}></RycomPageHeader>

                <RycomSectionHeader title="Task Form"></RycomSectionHeader>

                <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="companyid"
                            label="companyid"
                            name="companyid"
                            onChangeHandler={(event) => {
                                return getVenueList(event[0])
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Company is required'
                                }
                            }}
                        >
                            {companyMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="Venue"
                            label="Venue"
                            name="venueid"
                            onChangeHandler={(event) => {
                                return getDeviceList(event[0])
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            disabled={!companyId}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Venue is required'
                                }
                            }}
                        >
                            {venueMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="taskStorageType"
                            label="Task Storage Type"
                            name="taskStorageType"
                            onChangeHandler={(event) => {
                                setTaskStorageType(event[0].target.value);
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'File Type is required'
                                }
                            }}
                        >
                            <MenuItem key="1" value="GraphConnector">Data Storage(Graph)</MenuItem>
                            <MenuItem key="2" value="OutputConnector">Data Piping</MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>
                </RycomGrid>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomTextField id="taskname"
                            label="Task Name"
                            name="taskname"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Task Name is required'
                                }
                            }}
                        />
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomTextField id="taskdescription"
                            label="Task Description"
                            name="taskdescription"
                            register={register}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Task Description is required'
                                }
                            }}
                        />
                    </RycomGridItem>

                    <RycomGridItem>
                        <RycomSelectField id="Task Type"
                            label="Data Type"
                            name="taskType"
                            onChangeHandler={(event) => {
                                filterDataMapperList(event[0].target.value);
                                setTaskType(event[0].target.value);
                                return event[0].target.value
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'File Type is required'
                                }
                            }}
                        >
                            <MenuItem key="1" value="csv">CSV</MenuItem>
                            <MenuItem key="2" value="JSON">JSON </MenuItem>
                        </RycomSelectField>
                    </RycomGridItem>
                </RycomGrid>


                <RycomSectionHeader title="Inbound Connector Configuration"></RycomSectionHeader>
                <RycomGrid>
                    {/* Connector types */}
                    <RycomGridItem>
                        <RycomSelectField
                            id="inboundConnectorType"
                            label="Connector Types"
                            name="inboundConnectorType"
                            list={connectorTypes}
                            onChangeHandler={(event) => {
                             filterInboundConnectors(event[0].target.value);   
                             // setConnectorType(event[0].target.value);
                             return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                            required: {
                                value: true,
                                message: "Connector Type is required",
                            },
                            }}
                        >
                            {connectorTypes.map((connectorType1, index) => (
                            <MenuItem key={++index} value={connectorType1}>
                                {connectorType1}
                            </MenuItem>
                            ))}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="connectionid"
                            label="Source - Input Bound Connector"
                            name="connectionid"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Connector is required'
                                }
                            }}
                        >
                            {connectorInboundMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                </RycomGrid>
                <RycomSectionHeader title="DataMapper - Schema and Scheduler Configuration"></RycomSectionHeader>
                <RycomGrid>
                    <RycomGridItem>
                        <RycomSelectField id="datamapperid"
                            label="DataMapper"
                            name="datamapperid"
                            onChangeHandler={(event) => {
                                return event[0].target.value;
                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'DataMapper is required'
                                }
                            }}
                        >
                            {dataMapperMenuList}
                        </RycomSelectField>
                    </RycomGridItem>
                    <RycomGridItem>
                        <RycomSelectField id="schedulerid"
                            label="Scheduler"
                            name="schedulerid"
                            onChangeHandler={(event) => {
                                return event[0].target.value;

                            }}
                            register={register}
                            control={control}
                            errors={errors}
                            errorDescription={{
                                required: {
                                    value: true,
                                    message: 'Scheduler is required'
                                }
                            }}
                        >
                            {schedulerMenuList}
                        </RycomSelectField>
                    </RycomGridItem>

                </RycomGrid>
                {taskStorageType == 'OutputConnector' &&
                <>
                    <RycomSectionHeader title="Outbound Connector Configuration"></RycomSectionHeader>
                    <RycomGrid>
                        {/* Connector types */}
                        <RycomGridItem>
                            <RycomSelectField
                                id="outboundConnectorType"
                                label="Connector Types"
                                name="outboundConnectorType"
                                list={connectorTypes}
                                onChangeHandler={(event) => {
                                    filterOutboundConnectors(event[0].target.value);   
                                // setConnectorType(event[0].target.value);
                                return event[0].target.value;
                                }}
                                register={register}
                                control={control}
                                errors={errors}
                                errorDescription={{
                                required: {
                                    value: true,
                                    message: "Connector Type is required",
                                },
                                }}
                            >
                                {connectorTypes.map((connectorType1, index) => (
                                <MenuItem key={++index} value={connectorType1}>
                                    {connectorType1}
                                </MenuItem>
                                ))}
                            </RycomSelectField>
                        </RycomGridItem>
                        {/* {taskStorageType == 'OutputConnector' ?  */}
                        <RycomGridItem>
                            <RycomSelectField id="outputConnectionId"
                                label="Target - Output Bound Connector"
                                name="outputConnectionId"
                                onChangeHandler={(event) => {
                                    return event[0].target.value;
                                }}
                                register={register}
                                control={control}
                                errors={errors}
                                errorDescription={{
                                    required: {
                                        value: true,
                                        message: 'Output Connector is required'
                                    }
                                }}>
                                {connectorOutboundMenuList}
                            </RycomSelectField>
                        </RycomGridItem>
                    </RycomGrid>
                </> }
                {taskStorageType == 'GraphConnector' &&
                <>
                    <RycomSectionHeader title="Vertex Storage Configuration"></RycomSectionHeader>
                    <RycomGrid>
                        <RycomGridItem>
                            <RycomSelectField id="pathClassType"
                                label="Document Vertex Type"
                                name="documentVertexType"
                                onChangeHandler={(event) => {
                                    return event[0].target.value
                                }}
                                register={register}
                                control={control}
                                errors={errors}
                                errorDescription={{
                                }} >
                                {documentClassMenuList}
                            </RycomSelectField>
                        </RycomGridItem>
                    </RycomGrid>
                </>}
                <RycomSubmitModel>
                    <Button type="reset" variant="" size="large"
                        onClick={() => {
                            reset();
                        }}>Clear</Button>
                    <Button type="submit" color="primary" variant="contained" size="large" value="submit" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </RycomSubmitModel>
            </form >
        </div>
    );
}
