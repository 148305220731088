import axios from "axios";
import environment from '../environment';
import addBearerToken from '../auth/TokenConfigurator'

export const saveVertex = (task) => {
    return axios.post(`${environment.dataBaseUrl}/api/dms/task`,
            task, addBearerToken()
    );
}

export const updateVertex = (task) => {
    console.log(task);
    return axios.patch(`${environment.dataBaseUrl}/api/dms/task`,
            task, addBearerToken()
    );
}


export const executeTask = (task) => {
    console.log(JSON.stringify(task));
    return axios.post(`${environment.dataBaseUrl}/api/dms/exectask`,
            task, addBearerToken()
    );
}

export const deleteTask = (task) => {
    console.log(JSON.stringify(task));
    return axios.patch(`${environment.dataBaseUrl}/api/dms/task/delete`,
            task, addBearerToken()
    );
}

export const getAllTask = (companyId,config) => {
    const token=localStorage.getItem('react-token')
    config.headers= { Authorization: `Bearer ${token}` }
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/parentcompany/${companyId}`, config, addBearerToken());
}
export const getAllTaskStatus = () => {
  return axios.get(`${environment.dataBaseUrl}/api/dms/task/status`, addBearerToken());
}

export const getAllTaskStatusDetail = (taskStatusId) => {
  return axios.get(`${environment.dataBaseUrl}/api/dms/task/status/${taskStatusId}`, addBearerToken());
}

export const getTaskStatusDetail = (taskStatusId, config) => {
    const token=localStorage.getItem('react-token')
    config.headers= { Authorization: `Bearer ${token}` }
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/status/detail/${taskStatusId}`, config, addBearerToken());
  }

export const getTaskById = (id) => {
    return axios.get(`${environment.dataBaseUrl}/api/dms/task/${id}`, addBearerToken());
}
